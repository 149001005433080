import { capitalize, formatDate, getRiskRatingClassColor } from '../../../../api/helpers';
import TextButton from '../../../common/Buttons/TextButton';
import { useDispatch } from 'react-redux';
import { setCurrentRisk } from '../../../../redux/slices/risks';
import { Box, Td, Tr, Tooltip } from '@chakra-ui/react';
import { getBusinessByName, getDepartmentByName } from '../../../../redux/utils';
import { Link, useNavigate } from 'react-router-dom';
import RiskTableRowActions from './Actions';
import { getOverallRiskRating } from '../../../../redux/slices/riskForm';
import { useMemo } from 'react';
import { RISK_TABLE_FIELDS, DEFAULT_RISK_TABLE_FIELDS } from '../../../../constants';

const RisksTableRow = ({ fields, risk, fieldProps, onClick, selectedRisk }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const handleAddResponseClick = (e) => {
		e.stopPropagation();
		dispatch(setCurrentRisk(risk));
		navigate(`/risk/response?riskId=${risk._id}`);
	};

	const handleClick = () => {
		onClick?.(risk);
	};

	return (
		<Tr
			onClick={handleClick}
			fontSize={14}
			opacity={selectedRisk && selectedRisk._id !== risk._id ? 0.5 : 1}
			fontWeight={selectedRisk?._id === risk._id ? 600 : 400}
			cursor={onClick ? 'pointer' : ''}
			_hover={onClick ? { border: '1px solid gray' } : {}}>
			{(fields || DEFAULT_RISK_TABLE_FIELDS).map((field, index) => {
				const Component = Fields[field];
				const props = fieldProps?.[field] || {};

				if (!Component) {
					throw Error(`InvalidField: ${field}`);
				}

				return (
					<Component
						key={index}
						risk={risk}
						onAddResponseClick={handleAddResponseClick}
						props={props}
					/>
				);
			})}
		</Tr>
	);
};

const Fields = {
	[RISK_TABLE_FIELDS.RiskID]: ({ risk, props }) => (
		<Td {...props} _hover={{ ...(props._hover || {}), textDecoration: 'underline' }}>
			<Link onClick={(e) => e.stopPropagation()} to={`/risk?riskId=${risk?._id}`}>
				{risk.shortId}
			</Link>
		</Td>
	),
	[RISK_TABLE_FIELDS.Title]: ({ risk, props }) => (
		<Td whiteSpace='initial' {...props}>
			<Box width={100} {...props}>
				{risk.title}
			</Box>
		</Td>
	),
	[RISK_TABLE_FIELDS.Description]: ({ risk, props }) => (
		<Td whiteSpace='initial'>
			<Box width={300} {...props}>
				{risk.description}
			</Box>
		</Td>
	),
	[RISK_TABLE_FIELDS.Status]: ({ risk, props }) => <Td {...props}>{capitalize(risk.status)} </Td>,
	[RISK_TABLE_FIELDS.Response]: ({ risk, props, onAddResponseClick }) => (
		<Td {...props}>
			{risk.response?.response ? (
				capitalize(risk.response.response)
			) : (
				<TextButton onClick={onAddResponseClick}>+ Add Response</TextButton>
			)}
		</Td>
	),
	[RISK_TABLE_FIELDS.Business]: ({ risk, props }) => (
		<Td {...props}>{getBusinessByName(risk.business)?.label || ''}</Td>
	),
	[RISK_TABLE_FIELDS.Department]: ({ risk, props }) => (
		<Td {...props} whiteSpace='initial'>
			<Box width='100px'>{getDepartmentByName(risk.department)?.label || ''}</Box>
		</Td>
	),
	[RISK_TABLE_FIELDS.SubDepartment]: ({ risk, props }) => (
		<Td {...props} whiteSpace='initial'>
			<Box width={'80px'}>{typeof risk.subDepartment === 'string' ? risk.subDepartment : ''}</Box>
		</Td>
	),
	[RISK_TABLE_FIELDS.Owner]: ({ risk, props }) => (
		<Td {...props}>
			<Tooltip label={risk.owner ? risk.owner.email : ''}>
				<Box width={150}>
					{risk.owner
						? risk.owner?.name
						: risk.unregisteredOwnerName
						? `${risk.unregisteredOwnerName} (Unregistered)`
						: ''}
				</Box>
			</Tooltip>
		</Td>
	),
	[RISK_TABLE_FIELDS.CreationTime]: ({ risk, props }) => (
		<Td {...props} textAlign='center'>
			{formatDate(risk?.creationTime)}
		</Td>
	),
	[RISK_TABLE_FIELDS.Actions]: ({ risk, props }) => <RiskTableRowActions risk={risk} />,
	[RISK_TABLE_FIELDS.InherentRisk]: ({ risk, props }) => {
		const value = useMemo(() => getOverallRiskRating(risk.inherentRisk), [risk]);

		return (
			<Td textAlign='center' {...props} bgColor={getRiskRatingClassColor(value) + ' !important'}>
				{value}
			</Td>
		);
	},
	[RISK_TABLE_FIELDS.ResidualRisk]: ({ risk, props }) => {
		const value = useMemo(() => getOverallRiskRating(risk.residualRisk), [risk]);

		return (
			<Td textAlign='center' {...props} bgColor={getRiskRatingClassColor(value) + ' !important'}>
				{value}
			</Td>
		);
	},
	[RISK_TABLE_FIELDS.AppetiteRisk]: ({ risk, props }) => {
		const value = useMemo(() => getOverallRiskRating(risk.appetite), [risk]);

		return (
			<Td textAlign='center' {...props} bgColor={getRiskRatingClassColor(value) + ' !important'}>
				{value}
			</Td>
		);
	},
};

export default RisksTableRow;
