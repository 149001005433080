import React, { useState, useEffect, useContext, useMemo } from 'react';
import PageContainer from '../../Components/Layout/PageContainer';
import UserContext from '../../context/user/UserContext';
import { useSelector, useDispatch } from 'react-redux';
import { updateRiskFormState } from '../../redux/slices/riskForm';
import RiskFormHeader from '../../Components/RiskForm/RiskFormHeader';
import SelectUserInput from '../../Components/common/Inputs/SelectUserInput';
import SelectRiskInput from '../../Components/common/Inputs/SelectRiskInput';
import ErrorText from '../../Components/common/ErrorText';
import RiskResponseSummary from '../../Components/common/RiskResponseSummary';
import { isRiskDisabled } from '../../redux/slices/risks';
import {
	getBusinessInputOptions,
	getDepartmentInputOptions,
	getStatusInputOptions,
	getSubDepartmentInputOptions,
	getThemeInputOptions,
} from '../../redux/utils';
import SelectInput from '../../Components/common/Inputs/SelectInput';
import {
	FormControl,
	FormLabel,
	Box,
	Input,
	Textarea,
	Grid,
	GridItem,
	Checkbox,
} from '@chakra-ui/react';
import { userHasGlobalAccess } from '../../context/user/utils';

const Risk = () => {
	const { user } = useContext(UserContext);
	const { settings } = useSelector((state) => state.appSettings);

	const currentRisk = useSelector((state) => state.risks.currentRisk);
	const isDisabled = useMemo(() => isRiskDisabled(currentRisk, user), [currentRisk, user]);

	const { inputValues, errors } = useSelector((state) => state.riskForm);
	const { isGetRiskLoading } = useSelector((state) => state.risks);
	const dispatch = useDispatch();

	const hasGlobalAccess = useMemo(() => userHasGlobalAccess(user), [user]);

	const [inputOptions, setInputOptions] = useState({});

	const handleInputChange = (name, value) => {
		let updatedState = { ...inputValues, [name]: value };

		if (name === 'department') {
			updatedState.subDepartment = undefined;
		} else if (name === 'business') {
			updatedState.department = undefined;
			updatedState.subDepartment = undefined;
		}

		dispatch(updateRiskFormState({ ...updatedState }));
	};

	useEffect(() => {
		if (user) {
			getOptions(inputValues);
		}
	}, [settings, user, inputValues, currentRisk]);

	const getOptions = (inputValues) => {
		const departmentOptions = getDepartmentInputOptions(user, inputValues.business);
		let statusOptions = [];

		if (!currentRisk) {
			statusOptions = [{ label: 'Identified', value: 'identified' }];
		} else {
			statusOptions = getStatusInputOptions();
		}
		setInputOptions({
			business: getBusinessInputOptions(user),
			department: departmentOptions,
			subDepartment: getSubDepartmentInputOptions(
				departmentOptions.find(({ value }) => value === inputValues.department)
			),
			theme: getThemeInputOptions(),
			status: statusOptions,
		});
	};

	return (
		<PageContainer>
			<RiskFormHeader />

			{!isGetRiskLoading && (
				<>
					<Grid templateColumns='repeat(3, 1fr)' columnGap={32} rowGap={8}>
						<GridItem>
							<FormControl>
								<FormLabel>Business Unit</FormLabel>

								<SelectInput
									isDisabled={isDisabled}
									placeholder='Select Business'
									value={inputValues.business}
									options={inputOptions['business']}
									onChange={(value) => handleInputChange('business', value)}
								/>

								{errors.business && <ErrorText>{errors.business}</ErrorText>}
							</FormControl>
						</GridItem>

						<GridItem>
							<FormControl>
								<FormLabel>Department</FormLabel>

								<SelectInput
									isDisabled={isDisabled}
									placeholder='Select Department'
									value={inputValues.department}
									onChange={(value) => handleInputChange('department', value)}
									options={inputOptions['department']}
								/>

								{errors.department && <ErrorText>{errors.department}</ErrorText>}
							</FormControl>
						</GridItem>

						<GridItem>
							<FormControl>
								<FormLabel>Sub-Department</FormLabel>

								<SelectInput
									isDisabled={isDisabled}
									placeholder='Select Sub-Department'
									value={inputValues.subDepartment || ''}
									onChange={(value) => handleInputChange('subDepartment', value)}
									options={inputOptions['subDepartment']}
								/>

								{errors.subDepartment && <ErrorText>{errors.subDepartment}</ErrorText>}
							</FormControl>
						</GridItem>

						<GridItem>
							<FormControl>
								<FormLabel>Title</FormLabel>

								<Input
									variant='full-screen-form'
									isDisabled={isDisabled}
									placeholder='Enter Title'
									maxLength={70}
									value={inputValues.title || ''}
									onChange={(e) => handleInputChange('title', e.target.value)}
								/>

								{errors.title && <ErrorText>{errors.title}</ErrorText>}
							</FormControl>
						</GridItem>

						<GridItem>
							<FormControl>
								<FormLabel>Owner</FormLabel>

								<SelectUserInput
									isDisabled={isDisabled}
									value={
										typeof inputValues.owner === 'object'
											? inputValues.owner?._id
											: inputValues.owner
									}
									onChange={(value) => {
										handleInputChange('owner', value);
									}}
								/>

								{errors.owner && <ErrorText>{errors.owner}</ErrorText>}
							</FormControl>
						</GridItem>

						<GridItem>
							<FormControl>
								<FormLabel>Theme</FormLabel>

								<SelectInput
									isDisabled={isDisabled}
									className='text-[14px] w-[100%]  px-[10px]'
									placeholder='Select Theme'
									value={inputValues.theme}
									onChange={(value) => handleInputChange('theme', value)}
									options={inputOptions['theme']}
								/>

								{errors.theme && <ErrorText>{errors.theme}</ErrorText>}
							</FormControl>
						</GridItem>

						<GridItem>
							<FormControl>
								<FormLabel>Status</FormLabel>

								<SelectInput
									isDisabled={isDisabled}
									className='text-[14px] w-[100%]  px-[10px]'
									value={inputValues.status || 'identified'}
									placeholder='Select Status'
									options={inputOptions['status']}
									onChange={(value) => handleInputChange('status', value)}
								/>

								{errors.status && <ErrorText>{errors.status}</ErrorText>}
							</FormControl>
						</GridItem>

						<GridItem>
							<FormControl>
								<FormLabel>Linked Corporate Risk</FormLabel>

								<SelectRiskInput
									isDisabled={isDisabled}
									corporateRisksOnly={true}
									value={inputValues.linkedCorporateRisk}
									onChange={(value) => handleInputChange('linkedCorporateRisk', value)}
								/>

								{errors.linkedCorporateRisk && <ErrorText>{errors.linkedCorporateRisk}</ErrorText>}
							</FormControl>
						</GridItem>

						<GridItem>
							<Box height='100%' display='flex' alignItems='center' gap={12}>
								<Checkbox
									colorScheme='blue'
									isDisabled={isDisabled || !hasGlobalAccess}
									isChecked={inputValues.corporateRisk}
									onChange={() => handleInputChange('corporateRisk', !inputValues.corporateRisk)}>
									Corporate Risk
								</Checkbox>

								{/* TODO: Needs explanation */}
								{inputValues.corporateRisk && (
									<Checkbox
										colorScheme='blue'
										isDisabled={!hasGlobalAccess}
										isChecked={inputValues.visibleToAll}
										onChange={() => handleInputChange('visibleToAll', !inputValues.visibleToAll)}>
										Visible to All
									</Checkbox>
								)}
							</Box>
						</GridItem>
					</Grid>

					<FormControl mt={8}>
						<FormLabel>Description</FormLabel>

						<Textarea
							disabled={isDisabled}
							value={inputValues.description || ''}
							rows={10}
							style={{ resize: 'none' }}
							onChange={(e) => handleInputChange('description', e.target.value)}
						/>

						{errors.description && <ErrorText>{errors.description}</ErrorText>}
					</FormControl>

					<RiskResponseSummary risk={inputValues} />
				</>
			)}
		</PageContainer>
	);
};
export default Risk;
