import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUsers } from '../../../redux/slices/users';
import { sortOptions } from '../../../redux/utils';
import SelectInput from './SelectInput';
import { Box, Text } from '@chakra-ui/react';

const SelectUserInput = (props) => {
	const { users, fetchUsersLoading } = useSelector((state) => state.users);
	const dispatch = useDispatch();
	const { value, onChange, isDisabled, ...rest } = props;

	const options = useMemo(
		() => [
			// { label: 'None', email: '', value: undefined },
			...sortOptions([
				...users.map((user) => ({
					label: user.name,
					email: user.email,
					value: user._id,
				})),
			]),
		],
		[users]
	);

	// TODO: should we do search on the client instead when the total count of users is only a few hundred?
	// const debouncedSearch = useCallback(
	// 	debounce((value) => {
	// 		dispatch(fetchUsers({ searchValue: value }));
	// 	}, 200),
	// 	[]
	// );

	useEffect(() => {
		if (users.length === 0 && !fetchUsersLoading) {
			dispatch(fetchUsers());
		}
	}, []);

	return (
		<SelectInput
			{...rest}
			isSearchable
			isDisabled={isDisabled}
			value={value || ''}
			placeholder='Select User'
			options={options}
			isLoading={fetchUsersLoading}
			components={{
				Option: (props) => {
					return (
						<Box fontSize={14}>
							<Text style={{ fontWeight: 500 }}>{props.email}</Text>
							<Text>{props.label}</Text>
						</Box>
					);
				},
			}}
			onChange={onChange}
		/>
	);
};

export default SelectUserInput;
