import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingSpinner from '../Components/common/LoadingSpinner';
import PageContainer from '../Components/Layout/PageContainer';
import ActionsList from '../Components/MyActions/ActionsList';
import SelectedActionForm from '../Components/MyActions/SelectedActionForm';
import UserContext from '../context/user/UserContext';
import { fetchActions } from '../redux/slices/actions';
import { fetchUsers } from '../redux/slices/users';
import { Heading } from '@chakra-ui/react';
import { useQuery } from '../App';

const MyActions = () => {
	const dispatch = useDispatch();
	const [selectedAction, setSelectedAction] = useState();
	const [isFetchLoading, setIsFetchLoading] = useState(true);
	const { actions } = useSelector((state) => state.actions);
	const { users } = useSelector((state) => state.users);
	const { user: currentCognitoUser } = useContext(UserContext);
	const query = useQuery();

	const handleActionClick = (action) => {
		setSelectedAction(action);
	};

	useEffect(() => {
		if (!currentCognitoUser) {
			return;
		}

		const currentUser = users.find((user) => user.cognitoId === currentCognitoUser.sub);

		if (!currentUser) {
			return;
		}
		(async () => {
			await dispatch(fetchActions({ owner: currentUser._id }));
			setIsFetchLoading(false);
		})();
	}, [users, currentCognitoUser]);

	useEffect(() => {
		const selectedActionSeqQuery = query.get('actionSeq');

		if (selectedAction) {
			setSelectedAction(actions.find((action) => action._id === selectedAction._id));
		} else if (selectedActionSeqQuery) {
			const action = actions.find((action) => action.actionSeq === Number(selectedActionSeqQuery));

			setSelectedAction(action || actions[0]);
		} else if (actions[0]) {
			setSelectedAction(actions[0]);
		}
	}, [actions]);

	useEffect(() => {
		if (!users.length) {
			dispatch(fetchUsers());
		}
	}, []);

	return (
		<PageContainer>
			<Heading>My Actions</Heading>

			{isFetchLoading ? (
				<div style={{ marginTop: 16 }}>
					<LoadingSpinner />
				</div>
			) : (
				<div style={{ display: 'flex', height: '100%' }}>
					<ActionsList selectedAction={selectedAction} onActionClick={handleActionClick} />
					{selectedAction && <SelectedActionForm selectedAction={selectedAction} />}
				</div>
			)}
		</PageContainer>
	);
};

export default MyActions;
