import { Card, CardBody } from '@chakra-ui/react';
import { ResponsiveBar } from '@nivo/bar';
import { useMemo } from 'react';
import { BarsLayer, HighlightBarsLayer } from './BarsLayer';

const RisksByDepartmentChart = ({ data, highlightedData, onDepartmentClick, selectedFilter }) => {
	const axisLeftTickValues = useMemo(() => [0, ...data.map((entry) => entry.value)], [data]);

	return (
		<Card>
			<CardBody>
				<div style={{ height: '300px' }}>
					<ResponsiveBar
						data={data}
						indexBy='label'
						margin={{ top: 5, right: 5, bottom: 70, left: 60 }}
						padding={0.3}
						valueScale={{ type: 'linear' }}
						indexScale={{ type: 'band', round: true }}
						colors={['#118dff']}
						gridYValues={axisLeftTickValues}
						borderColor={{
							from: 'color',
							modifiers: [['darker', 1.6]],
						}}
						axisTop={null}
						axisRight={null}
						axisBottom={{
							tickSize: 5,
							tickPadding: 5,
							tickRotation: -28,
							legend: 'Departments',
							legendPosition: 'middle',
							legendOffset: 55,
							truncateTickAt: 0,
						}}
						axisLeft={{
							tickSize: 0,
							tickPadding: 5,
							tickRotation: 0,
							legend: 'Number of Risks',
							legendPosition: 'middle',
							legendOffset: -40,
							tickValues: axisLeftTickValues,
						}}
						labelSkipWidth={12}
						labelSkipHeight={12}
						labelTextColor={{
							from: 'color',
							modifiers: [['darker', 1.6]],
						}}
						theme={chartTheme}
						role='application'
						ariaLabel='Risks by department graph'
						layers={[
							'grid',
							'axes',
							'markers',
							'legends',
							'annotations',
							(props) => (
								<BarsLayer
									{...props}
									onDepartmentClick={onDepartmentClick}
									selectedFilter={selectedFilter}
								/>
							),
							(props) => (
								<HighlightBarsLayer
									{...props}
									highlightedData={highlightedData}
									onDepartmentClick={onDepartmentClick}
									selectedFilter={selectedFilter}
								/>
							),
						]}
					/>
				</div>
			</CardBody>
		</Card>
	);
};

const chartTheme = {
	axis: {
		legend: {
			text: {
				fontWeight: 'bold', // You can adjust the font weight here
			},
		},
	},
};

export default RisksByDepartmentChart;
