import { Table, TableContainer, Tbody } from '@chakra-ui/react';
import RisksTableRow from './TableRow/TableRow';
import RisksTableHead from './TableHead';

const RisksTable = ({
	risks,
	size,
	sort,
	setSort,
	fields,
	fieldProps,
	onRiskClick,
	selectedRisk,
	tableProps,
	tableContainerProps,
}) => {
	return (
		<TableContainer bgColor='white' {...tableContainerProps}>
			<Table size={size} colorScheme='gray' variant='striped' {...tableProps}>
				<RisksTableHead fields={fields} fieldProps={fieldProps} sort={sort} setSort={setSort} />
				<Tbody>
					{risks.map((risk, key) => (
						<RisksTableRow
							onClick={onRiskClick}
							selectedRisk={selectedRisk}
							fields={fields}
							fieldProps={fieldProps}
							risk={risk}
							key={key}
						/>
					))}
				</Tbody>
			</Table>
		</TableContainer>
	);
};

export default RisksTable;
