import React, { useContext, useMemo, useState } from 'react';
import RiskFormHeader from '../../Components/RiskForm/RiskFormHeader';
import PageContainer from '../../Components/Layout/PageContainer';
import EntryFormModal from '../../Components/RiskForm/SingleInputTableForm/EntryFormModal';
import TableRow from '../../Components/RiskForm/SingleInputTableForm/TableRow';
import DeleteConfirmPopup from '../../Components/common/Popups/DeleteConfirmPopup';
import { updateRiskFormState } from '../../redux/slices/riskForm';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Icon, Text } from '@chakra-ui/react';
import { BiPlus } from 'react-icons/bi';
import { Table, Thead, Tbody, Tr, Th, TableContainer } from '@chakra-ui/react';
import { isRiskDisabled } from '../../redux/slices/risks';
import UserContext from '../../context/user/UserContext';

const Causes = () => {
	const [selectedCauseIndex, setSelectedCauseIndex] = useState(null);
	const [awaitingDeleteActionIndex, setAwaitingDeleteActionIndex] = useState(null);
	const [isEntryModalOpen, setIsEntryModalOpen] = useState(false);
	const dispatch = useDispatch();
	const riskFormData = useSelector((state) => state.riskForm);
	const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
	const { user } = useContext(UserContext);
	const currentRisk = useSelector((state) => state.risks.currentRisk);
	const isDisabled = useMemo(() => isRiskDisabled(currentRisk, user), [currentRisk, user]);

	const updateCauses = (causes) => {
		dispatch(updateRiskFormState({ causes }));
	};

	const handleEditClick = (index) => {
		setSelectedCauseIndex(index);
		setIsEntryModalOpen(true);
	};

	const handleDeleteClick = (index) => {
		setDeleteConfirmOpen(true);
		setAwaitingDeleteActionIndex(index);
	};

	const handleDeleteConfirm = () => {
		const updatedItems = riskFormData.inputValues.causes.filter(
			(i, index) => index !== awaitingDeleteActionIndex
		);

		updateCauses(updatedItems);
		setAwaitingDeleteActionIndex(null);
		setDeleteConfirmOpen(false);
	};

	return (
		<PageContainer>
			<RiskFormHeader />
			<div>
				<Box display='flex' alignItems='center'>
					<Text fontSize='2xl' fontWeight={500}>
						Causes
					</Text>

					<Box width='fit-content' ml='auto'>
						<Button
							isDisabled={isDisabled}
							onClick={() => {
								setSelectedCauseIndex(undefined);
								setIsEntryModalOpen(true);
							}}
							leftIcon={<Icon as={BiPlus} />}>
							Add New
						</Button>
					</Box>
				</Box>
				<EntryFormModal
					isOpen={isEntryModalOpen}
					labelPropertyName='cause'
					tableItems={riskFormData.inputValues?.causes || []}
					setTableItems={updateCauses}
					selectedTableIndex={selectedCauseIndex}
					onClose={() => setIsEntryModalOpen(false)}
					setSelectedTableIndex={setSelectedCauseIndex}
				/>
				<TableContainer bgColor='white' mt={6}>
					<Table variant='striped'>
						<Thead>
							<Tr>
								<Th width='100px'>#</Th>
								<Th>Cause</Th>
								<Th textAlign='center'>Actions</Th>
							</Tr>
						</Thead>
						<Tbody>
							{riskFormData.inputValues?.causes?.map((item, index) => (
								<TableRow
									number={item.number}
									label={item.cause}
									onEditClick={handleEditClick}
									onDeleteClick={handleDeleteClick}
									index={index}
									key={index}
								/>
							))}
						</Tbody>
					</Table>
				</TableContainer>
			</div>

			<DeleteConfirmPopup
				open={deleteConfirmOpen}
				onClose={() => setDeleteConfirmOpen(false)}
				onDelete={handleDeleteConfirm}
			/>
		</PageContainer>
	);
};

export default Causes;
