import { useSelector } from 'react-redux';
import { capitalize, formatDate } from '../../api/helpers';
import LoadingSpinner from '../common/LoadingSpinner';
import { getBusinessByName, getDepartmentByName } from '../../redux/utils';
import { Box, Text } from '@chakra-ui/react';
import ReviewsPageTabs from './Header';

const ReviewsList = (props) => {
	const { isLoading, onReviewClick, selectedTab, setSelectedTab, selectedReview } = props;
	const { reviews } = useSelector((state) => state.reviews);


	return (
		<Box style={{ width: '40%', height: 'calc(100vh - 250px)', maxWidth: 700, overflowY: 'auto' }}>
			<ReviewsPageTabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
			{Boolean(reviews.length) && (
				<Box display='flex' py={4} px={3} width='100%'>
					<Box width='22%' px={2}>
						<HeaderText>Reviewer</HeaderText>
					</Box>
					{selectedTab !== 'corporate' && (
						<Box width='22%' px={2}>
							<HeaderText>{capitalize(selectedTab)}</HeaderText>
						</Box>
					)}

					<Box width='16%' px={2}>
						<HeaderText>Start</HeaderText>
					</Box>
					<Box width='16%' px={2}>
						<HeaderText>Due</HeaderText>
					</Box>
					<Box width='15%' px={2}>
						<HeaderText>Status</HeaderText>
					</Box>
				</Box>
			)}

			{isLoading && <LoadingSpinner sx={{ marginTop: 4 }} />}

			{!isLoading && reviews.length === 0 && (
				<Box style={{ marginTop: 16, fontSize: 14, textAlign: 'center' }}>No reviews found</Box>
			)}

			{!isLoading &&
				reviews.map((review) => (
					<Box
						display='flex'
						width='100%'
						cursor='pointer'
						borderWidth='1px'
						borderColor='gray.300'
						py={3}
						px={2}
						mb={2}
						borderRadius={24}
						_hover={{
							backgroundColor: 'gray.100',
						}}
						fontWeight={selectedReview?._id === review._id ? 'bold' : ''}
						backgroundColor={selectedReview?._id === review._id ? 'gray.200' : 'white'}
						onClick={() => onReviewClick(review)}
						key={review.reviewSeq}>
						<Box width='22%' px={2}>
							<ItemText>{review.reviewer?.name || 'None'}</ItemText>
						</Box>
						{selectedTab !== 'corporate' && (
							<Box width='22%' px={2}>
								<ItemText>
									{review.type === 'department'
										? getDepartmentByName(review.department)?.label || ''
										: getBusinessByName(review.business)?.label || ''}
								</ItemText>
							</Box>
						)}
						<Box width='16%' px={2}>
							<ItemText>{review.startDate ? formatDate(review.startDate) : ''}</ItemText>
						</Box>
						<Box width='16%' px={2}>
							<ItemText>{review.dueDate ? formatDate(review.dueDate) : ''}</ItemText>
						</Box>
						<Box width='15%' whiteSpace='nowrap' px={2}>
							<ItemText>{review.status === 'completed' ? 'Completed' : 'In Progress'}</ItemText>
						</Box>
						<Box marginLeft={2} px={2}>
							<ItemText textAlign='center'>{review.period}</ItemText>
						</Box>
					</Box>
				))}
		</Box>
	);
};

const HeaderText = ({ children }) => {
	return (
		<Text fontWeight={700} color='primary' fontSize={14}>
			{children}
		</Text>
	);
};

const ItemText = ({ children, ...rest }) => {
	return (
		<Text fontSize={12} {...rest}>
			{children}
		</Text>
	);
};

export default ReviewsList;
