import { useContext, useMemo } from 'react';
import UserContext from '../../../context/user/UserContext';
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
} from '@chakra-ui/react';
import SelectedActionForm from '../../MyActions/SelectedActionForm';
import ActionForm from './ActionInputs';

const ActionModal = (props) => {
	const { isOpen, selectedTableIndex, setSelectedTableIndex, tableItems, setTableItems, onClose } =
		props;
	const { user } = useContext(UserContext);
	const action = useMemo(() => tableItems[selectedTableIndex], [tableItems, selectedTableIndex]);

	const isCurrentUserActionOwner = useMemo(() => {
		if (action && action.owner.cognitoId === user?.sub) {
			return true;
		}

		return false;
	}, [action, user]);

	const handleComplete = (updates) => {
		let updatedItems = [...tableItems];
		updatedItems[selectedTableIndex] = { ...updatedItems[selectedTableIndex], ...updates };

		setTableItems([...updatedItems]);

		setSelectedTableIndex(null);
		onClose();
	};

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent maxW={800}>
				<ModalHeader>{typeof selectedTableIndex === 'number' ? 'Edit' : 'Add'} Action</ModalHeader>
				<ModalCloseButton />

				{/* TODO: Currently clicking on COMPLETE ACTION will save it immediately without 
						waiting for the user to click on the save button on the whole risk form 
					*/}
				{isCurrentUserActionOwner ? (
					<ModalBody pb={6}>
						<SelectedActionForm isInModal onComplete={handleComplete} selectedAction={action} />
					</ModalBody>
				) : (
					<ActionForm {...props} />
				)}
			</ModalContent>
		</Modal>
	);
};

export default ActionModal;
