import { getRiskRatingClass } from '../../api/helpers';
import { CHART_COLORS, RATING_COLORS } from '../../constants';
import { getDepartmentByName } from '../../redux/utils';

export const getChartsDataFromRisks = (risks) => {
	risks = risks || [];
	
	let data = {
		ratings: {
			green: 0,
			amber: 0,
			red: 0,
		},
		risksByDepartment: [],
		risksByTheme: [],
		corporateRisks: [],
		controlsCount: 0,
		controls: [
			{
				id: 'green',
				label: 'Green',
				value: 0,
				color: RATING_COLORS.green,
			},
			{
				id: 'amber',
				label: 'Amber',
				value: 0,
				color: RATING_COLORS.amber,
			},
			{
				id: 'red',
				label: 'Red',
				value: 0,
				color: RATING_COLORS.red,
			},
		],
	};

	risks.forEach((risk) => {
		const rating = getRiskRatingClass(risk.residualRisk);

		data.ratings[rating] = data.ratings[rating] + 1;

		const risksByDepartmentEntry = data.risksByDepartment.find(
			({ department }) => department === risk.department
		);

		if (risksByDepartmentEntry) {
			risksByDepartmentEntry.value += 1;
		} else {
			data.risksByDepartment.push({
				id: risk.department,
				department: risk.department,
				label: getDepartmentByName(risk.department)?.label,
				value: 1,
			});
		}

		const risksByThemeEntry = data.risksByTheme.find(({ id }) => id === risk.theme);

		if (risksByThemeEntry) {
			risksByThemeEntry.value += 1;
		} else {
			data.risksByTheme.push({
				id: risk.theme,
				label: risk.theme,
				value: 1,
				color: CHART_COLORS[data.risksByTheme.length],
			});
		}

		if (risk.corporateRisk) {
			data.corporateRisks.push(risk);
		}

		risk.controls?.forEach((control) => {
			data.controlsCount += 1;
			const ratingEntry = data.controls.find((entry) => entry.id === control.rating);

			if (ratingEntry) {
				ratingEntry.value += 1;
			}
		});
	});

	return data;
};
