import { Box, Card, CardBody, Text } from '@chakra-ui/react';
import { ResponsivePie } from '@nivo/pie';
import CustomLegend from '../CustomLegend';
import { Arcs, HighlightArcs } from './ArcsLayer';

const RisksByThemeChart = ({ data, highlightedData, risksCount, selectedFilter, onThemeClick }) => {
	return (
		<Card>
			<CardBody display='flex'>
				<div style={{ width: `calc(100%)`, height: '300px', position: 'relative' }}>
					<ResponsivePie
						data={data}
						margin={{ top: 0, right: 90, bottom: 0, left: 90 }}
						innerRadius={0.5}
						padAngle={0.7}
						cornerRadius={3}
						activeOuterRadiusOffset={8}
						// arcLabel={null}
						arcLinkLabel={(arc) =>
							arc.data.value +
							` (${Math.round((Number(arc.data.value) / risksCount) * 100 * 10) / 10}%)`
						}
						arcLinkLabelsSkipAngle={10}
						arcLinkLabelsTextColor='#333333'
						arcLinkLabelsThickness={2}
						arcLinkLabelsColor={{ from: 'color' }}
						arcLabelsSkipAngle={10}
						arcLabelsTextColor={{
							from: 'color',
							modifiers: [['darker', 2]],
						}}
						colors={({ data }) => data.color}
						tooltip={(props) => (
							<Box
								fontSize={12}
								display='flex'
								alignItems='center'
								p={3}
								bgColor='white'
								boxShadow='md'
								borderRadius={2}>
								<Text mr={2} fontWeight={600}>
									{props.datum.label}:{' '}
								</Text>{' '}
								<Text>{props.datum.value}</Text>
							</Box>
						)}
						layers={[
							'arcLinkLabels',
							'arcLabels',
							'legends',
							(props) => (
								<Arcs {...props} onThemeClick={onThemeClick} selectedFilter={selectedFilter} />
							),
							(props) => (
								<HighlightArcs
									{...props}
									highlightedData={highlightedData}
									onThemeClick={onThemeClick}
									selectedFilter={selectedFilter}
								/>
							),
						]}
					/>
				</div>

				<CustomLegend
					data={data}
					style={{
						height: 'fit-content',
						marginTop: 'auto',
						marginBottom: 'auto',
						maxHeight: '300px',
						width: '150px',
						display: 'flex',
						flexDirection: 'column',
						top: 8,
					}}
				/>
			</CardBody>
		</Card>
	);
};

export default RisksByThemeChart;
