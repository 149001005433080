import { useContext, useEffect, useState } from 'react';
import UserContext from '../../context/user/UserContext';
import ErrorText from '../common/ErrorText';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { createReview, initiateAllReviews } from '../../redux/slices/reviews';
import { useMemo } from 'react';
import { capitalize } from '../../api/helpers';
import { getBusinessInputOptions, getDepartmentInputOptions } from '../../redux/utils';
import SelectInput from '../common/Inputs/SelectInput';
import {
	Box,
	Button,
	FormControl,
	FormLabel,
	Icon,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Radio,
	RadioGroup,
	Text,
} from '@chakra-ui/react';
import { AiOutlineInfoCircle } from 'react-icons/ai';

const InitiateReviewPopup = (props) => {
	const [inputValues, setInputValues] = useState({});
	const [isSubmitLoading, setIsSubmitLoading] = useState(false);
	const [errors, setErrors] = useState({});
	const [inputOptions, setInputOptions] = useState({});
	const { settings } = useSelector((state) => state.appSettings);
	const dispatch = useDispatch();
	const { open, onClose, onSubmit } = props;
	const { user } = useContext(UserContext);

	const quarter = useMemo(() => moment(inputValues.startDate).quarter(), [inputValues]);

	const handleInputChange = (name, value) => {
		let updatedState = { ...inputValues, [name]: value };

		if (name === 'business') {
			updatedState.department = undefined;
		} else if (name === 'startDate') {
			updatedState.dueDate = moment(value).add(2, 'week').toISOString();
		} else if (name === 'type') {
			delete inputValues.department;
			delete inputValues.business;
		}

		delete errors[name];

		setErrors({ ...errors });
		setInputValues({ ...updatedState });
	};

	const getOptions = (inputValues) => {
		setInputOptions({
			business: getBusinessInputOptions(user),
			department: getDepartmentInputOptions(user, inputValues.business),
		});
	};

	const handleSubmitClick = async () => {
		let errors = {};

		const { type, business, department } = inputValues;

		if ((type === 'business' || type === 'department') && !business) {
			errors.business = 'Required';
		}

		if (type === 'department' && !department) {
			errors.department = 'Required';
		}

		setErrors({ ...errors });

		if (Object.keys(errors).length) {
			return;
		}

		setIsSubmitLoading(true);
		if (type === 'all') {
			const result = await dispatch(initiateAllReviews({ ...inputValues, period: `Q${quarter}` }));
			onSubmit(result.payload);
		} else {
			const result = await dispatch(createReview({ ...inputValues, period: `Q${quarter}` }));
			onSubmit([result.payload]);
		}

		setIsSubmitLoading(false);
		onClose();
	};

	const handleClose = () => {
		if (!isSubmitLoading) {
			onClose();
		}
	};

	useEffect(() => {
		if (user) {
			getOptions(inputValues);
		}
	}, [settings, user, inputValues]);

	useEffect(() => {
		if (open) {
			setInputValues({
				type: 'department',
				startDate: moment().endOf('quarter').subtract(2, 'week').format('YYYY-MM-DD'),
				dueDate: moment().endOf('quarter').subtract(2, 'day').format('YYYY-MM-DD'),
			});
		}
	}, [open]);

	return (
		<>
			<Modal isOpen={open} onClose={handleClose}>
				<ModalOverlay />
				<ModalContent maxW={800}>
					<ModalHeader>Initiate Review</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<FormControl>
							<FormLabel>Review Type</FormLabel>
							<RadioGroup
								colorScheme='blue'
								value={inputValues.type}
								onChange={(type) => handleInputChange('type', type)}
								style={{ display: 'flex', gap: 12 }}>
								{reviewTypes.map((type, index) => (
									<Radio key={index} value={type}>
										{capitalize(type)}
									</Radio>
								))}
							</RadioGroup>
						</FormControl>
						<Box mt={6} display='flex' gap={4}>
							{inputValues.type === 'all' && (
								<Box display='flex'>
									<Icon mt={1} mr={2} as={AiOutlineInfoCircle} />
									<Text fontSize={14}>
										This will result in the initiation of multiple reviews: <br />
										- A review for each business and department <br />- A corporate review
									</Text>
								</Box>
							)}
							{(inputValues.type === 'business' || inputValues.type === 'department') && (
								<FormControl>
									<FormLabel>Business Unit</FormLabel>

									<SelectInput
										placeholder='Select Business'
										value={inputValues.business || ''}
										options={inputOptions['business']}
										defaultChecked={inputValues.business}
										onChange={(value) => handleInputChange('business', value)}
									/>

									{errors.business && <ErrorText>{errors.business}</ErrorText>}
								</FormControl>
							)}

							{inputValues.type === 'department' && (
								<FormControl>
									<FormLabel>Department</FormLabel>

									<SelectInput
										placeholder='Select Department'
										value={inputValues.department || ''}
										options={inputOptions['department']}
										defaultChecked={inputValues.department}
										onChange={(value) => handleInputChange('department', value)}
									/>

									{errors.department && <ErrorText>{errors.department}</ErrorText>}
								</FormControl>
							)}
						</Box>

						<Box mt={6} gap={4} display='flex'>
							<FormControl>
								<FormLabel>Start Date</FormLabel>

								<Input
									variant='full-screen-form'
									type='date'
									min={moment().toDate()}
									value={
										inputValues.startDate
											? moment(new Date(inputValues.startDate)).format('YYYY-MM-DD')
											: ''
									}
									onChange={(e) =>
										handleInputChange(
											'startDate',
											moment(new Date(e.target.value)).format('YYYY-MM-DD')
										)
									}
								/>

								{errors.targetDate && <ErrorText>{errors.startDate}</ErrorText>}
							</FormControl>
							<FormControl>
								<FormLabel>Due Date</FormLabel>

								<Input
									variant='full-screen-form'
									type='date'
									min={moment(inputValues.startDate).toDate()}
									value={
										inputValues.dueDate
											? moment(new Date(inputValues.dueDate)).format('YYYY-MM-DD')
											: ''
									}
									onChange={(e) =>
										handleInputChange(
											'dueDate',
											moment(new Date(e.target.value)).format('YYYY-MM-DD')
										)
									}
								/>

								{errors.dueDate && <ErrorText>{errors.dueDate}</ErrorText>}
							</FormControl>
						</Box>
					</ModalBody>

					<ModalFooter>
						<Button variant='ghost' mr={3} onClick={handleClose}>
							Close
						</Button>
						<Button isLoading={isSubmitLoading} onClick={handleSubmitClick} variant='solid'>
							Initiate
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
};

const reviewTypes = ['all', 'department', 'business', 'corporate'];

export default InitiateReviewPopup;
