import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Button,
} from '@chakra-ui/react';

const UnsavedChangesPopup = ({ open, onDiscard, onClose, onSubmit }) => {
	return (
		<AlertDialog isOpen={open} onClose={onClose}>
			<AlertDialogOverlay>
				<AlertDialogContent>
					<AlertDialogHeader fontSize='lg' fontWeight='bold'>
						Warning
					</AlertDialogHeader>

					<AlertDialogBody>
						You have may unsaved changes. Would you like to submit first before leaving this page?
					</AlertDialogBody>

					<AlertDialogFooter>
						<Button variant='ghost' onClick={onDiscard}>
							Discard Changes
						</Button>
						<Button colorScheme='red' onClick={onSubmit} ml={3}>
							Save & Submit
						</Button>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialogOverlay>
		</AlertDialog>
	);
};

export default UnsavedChangesPopup;
