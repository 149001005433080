import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getQueryParam, updateQueryParam } from '../api/helpers';
import PageContainer from '../Components/Layout/PageContainer';
import ReviewsList from '../Components/Reviews/ReviewsList';
import SelectedReviewForm from '../Components/Reviews/SelectedReviewForm/SelectedReviewForm';
import UserContext from '../context/user/UserContext';
import { addReviewsToList, fetchReviews } from '../redux/slices/reviews';
import { fetchUsers } from '../redux/slices/users';
import { Box, Button, Heading, IconButton } from '@chakra-ui/react';
import InitiateReviewModal from '../Components/Reviews/InitiateReviewModal';
import { AiOutlineReload } from 'react-icons/ai';
import { userHasGlobalAccess } from '../context/user/utils';

const Reviews = () => {
	const userContext = useContext(UserContext);
	const { reviews } = useSelector((state) => state.reviews);
	const { users } = useSelector((state) => state.users);
	const [selectedReview, setSelectedReview] = useState();
	const [isLoading, setIsLoading] = useState(false);
	const [selectedTab, setSelectedTab] = useState('department');
	const [isInitiateReviewModalOpen, setIsInitiateReviewModalOpen] = useState(false);

	const dispatch = useDispatch();

	const isRiskManager = useMemo(
		() => userContext.user && userHasGlobalAccess(userContext.user),
		[userContext.user]
	);

	const loadReviews = async () => {
		setIsLoading(true);
		await dispatch(fetchReviews({ type: selectedTab }));
		setIsLoading(false);
	};

	const handleReviewClick = (review) => {
		updateQueryParam('reviewId', review._id);
		setSelectedReview(review);
	};

	const handleInitiateReviewModalSubmit = (newReviews) => {
		console.log(newReviews)
		if (newReviews.length > 1 || newReviews[0].type === selectedTab) {
			console.log(newReviews.filter((review) => review.type === selectedTab))
			dispatch(addReviewsToList(newReviews.filter((review) => review.type === selectedTab)));
		} else {
			setSelectedTab(newReviews[0].type);
		}

		setSelectedReview(newReviews[0]);
	};

	useEffect(() => {
		if (userContext.user) {
			loadReviews();
		}
	}, [userContext.user, selectedTab]);

	useEffect(() => {
		const reviewIdParam = getQueryParam('reviewId');

		if (selectedReview) {
			const updatedReview = reviews.find((review) => review._id === selectedReview._id);
			setSelectedReview(updatedReview);
		} else if (reviewIdParam) {
			const review = reviews.find((review) => review._id === reviewIdParam);
			if (review) {
				setSelectedReview(review);
			} else {
				// TODO: Do a GET request for just this reviewId
			}
		}
	}, [reviews]);

	useEffect(() => {
		if (!users.length) {
			dispatch(fetchUsers());
		}
	}, []);

	return (
		<PageContainer>
			<InitiateReviewModal
				open={isInitiateReviewModalOpen}
				onSubmit={handleInitiateReviewModalSubmit}
				onClose={() => setIsInitiateReviewModalOpen(false)}
			/>
			<Box display='flex' alignItems='center'>
				<Heading>Reviews</Heading>
				<Box gap={4} marginLeft='auto' width='fit-content' display='flex' alignItems='center'>
					<IconButton
						isLoading={isLoading}
						variant='ghost'
						onClick={() => !isLoading && loadReviews()}>
						<AiOutlineReload fontSize={25} />
					</IconButton>

					{isRiskManager && (
						<Button onClick={() => setIsInitiateReviewModalOpen(true)} style={{ width: 180 }}>
							Initiate Review
						</Button>
					)}
				</Box>
			</Box>

			<div style={{ display: 'flex', height: '100%' }}>
				<ReviewsList
					selectedTab={selectedTab}
					selectedReview={selectedReview}
					onReviewClick={handleReviewClick}
					setSelectedTab={setSelectedTab}
					isLoading={isLoading}
				/>

				{selectedReview && <SelectedReviewForm selectedReview={selectedReview} />}
			</div>
		</PageContainer>
	);
};

export default Reviews;
