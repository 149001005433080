import React, { useContext, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingSpinner from '../../Components/common/LoadingSpinner';
import PageContainer from '../../Components/Layout/PageContainer';
import TeamUserItem from '../../Components/Team/UserItem';
import UserContext from '../../context/user/UserContext';
import { isUserRiskManager } from '../../context/user/utils';
import { fetchUsers } from '../../redux/slices/users';
import AdminPageHeader from '../../Components/Admin/AdminPageHeader';
import { Button, Table, TableContainer, Tbody, Th, Thead, Tr } from '@chakra-ui/react';

const Team = () => {
	const { users, fetchUsersLoading } = useSelector((state) => state.users);
	const dispatch = useDispatch();
	const { user } = useContext(UserContext);

	const isRiskManager = useMemo(() => isUserRiskManager(user), [user]);
	console.log(isRiskManager);
	const sortedUsers = useMemo(
		() => [...users].sort((a, b) => (a.name.toUpperCase() < b.name.toUpperCase() ? -1 : 1)),
		[users]
	);

	const handleManageUsersClick = () => {
		window.open('https://admin.hub4aviation.com', '_blank');
	};

	useEffect(() => {
		if (users.length === 0 && !fetchUsersLoading) {
			dispatch(fetchUsers());
		}
	}, []);

	return (
		<PageContainer>
			<AdminPageHeader selectedTabIndex={1} />
			<div>
				<div style={{ display: 'flex', alignItems: 'center', marginBottom: 16, marginTop: 16 }}>
					{isRiskManager && (
						<div style={{ width: 'fit-content', marginLeft: 'auto' }}>
							<Button onClick={handleManageUsersClick} style={{ width: 200 }}>
								Manage Users
							</Button>
						</div>
					)}
				</div>

				{fetchUsersLoading && (
					<div className='mt-4'>
						<LoadingSpinner />
					</div>
				)}

				{!fetchUsersLoading && (
					<div>
						<TableContainer bgColor='white'>
							<Table colorScheme='gray' variant='striped'>
								<Thead>
									<Tr>
										<Th>Name</Th>
										<Th>Role</Th>
										<Th>Email</Th>
										<Th>Phone</Th>
									</Tr>
								</Thead>
								<Tbody>
									{sortedUsers?.map((user, index) => (
										<TeamUserItem user={user} key={index} />
									))}
								</Tbody>
							</Table>
						</TableContainer>
					</div>
				)}
			</div>
		</PageContainer>
	);
};

export default Team;
