import React, { useContext, useEffect, useState } from 'react';
import PageContainer from '../../Components/Layout/PageContainer';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import UserContext from '../../context/user/UserContext';
import { fetchRisks, updateRisk } from '../../redux/slices/risks';
import LoadingSpinner from '../../Components/common/LoadingSpinner';

const ReviewsRisk = () => {
	const { risks } = useSelector((state) => state.risks);
	const [loading, setLoading] = useState(false);
	const [submitLoading, setSubmitLoading] = useState(false);
	const [currentRisk, setCurrentRisk] = useState(null);
	const userContext = useContext(UserContext);
	let navigate = useNavigate();
	const dispatch = useDispatch();
	const params = useParams();
	console.log(currentRisk);

	const handleDoneClick = async () => {
		setSubmitLoading(true);
		await dispatch(updateRisk({ ...currentRisk, isReviewed: true }));
		setSubmitLoading(false);
		navigate('/reviews');
	};

	// TODO: Update this to fetch only the currently selected risk
	useEffect(() => {
		(async () => {
			if (risks.length > 0) {
				return;
			}

			setLoading(true);
			await dispatch(fetchRisks());
			setLoading(false);
		})();
	}, [userContext]);

	useEffect(() => {
		setCurrentRisk(risks.find((risk) => risk._id === params.riskId));
	}, [risks]);

	if (!currentRisk || loading) {
		return <LoadingSpinner />;
	}

	return (
		<PageContainer>
			<div className='lg:pl-[30px] md:pl-[30px] pl-[0px] bg-[#F4F6FA] h-[100%] lg:mr-[0px] md:mr-[0px] mr-[20px] p-2 '>
				<div className='text-[#3B426F] text-[24px] font-semibold pt-4 p-4'>
					{currentRisk.title}
				</div>

				<div className='mt-[20px] lg:pr-[30px] md:pr-[30px] pr-[0px]'>
					<table className='table-auto pt-[24px] w-[100%]  '>
						<tr className='h-[60px]'>
							<td className='cursor-pointer'>
								<td className='w-[15%]  p-2 text-center whitespace-nowrap'>
									Theme:{' '}
									<span className='font-semibold text-[#686E8E]'>
										{' '}
										{currentRisk.theme || 'None'}
									</span>
								</td>
								<td className='w-[15%] p-2 text-center'>
									IR Value:{' '}
									<span className='font-semibold text-[#686E8E]'>
										{currentRisk.overallInherentRisk}
									</span>
								</td>
								<td className='w-[55%] p-2 text-left'>
									RR Value:{' '}
									<span className='font-semibold text-[#686E8E]'>
										{' '}
										{currentRisk.overallResidualRisk}
									</span>
								</td>
								<tr>
									<td colspan='5' className='px-4'>
										{currentRisk.details || ''}
									</td>
								</tr>
								<td />
							</td>
						</tr>
					</table>

					<div className='pb-60 pt-3'>
						<button
							onClick={() => {
								// TODO: Handle edit
							}}
							className='m-3 p-2 w-[100px] text-[12px] font-semibold  bg-[#6E8AF5] text-[white] rounded-[8px]'>
							Edit
						</button>
						<button
							onClick={handleDoneClick}
							className='text-[12px] p-2 w-[100px] font-semibold bg-[#0DAA5F] text-[white] rounded-[8px]'>
							{submitLoading ? 'Updating...' : 'Done'}
						</button>
					</div>
				</div>
			</div>
		</PageContainer>
	);
};

export default ReviewsRisk;
