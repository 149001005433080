import { Box, Tab, TabList, Tabs } from '@chakra-ui/react';

const tabs = [
	{ label: 'Department', value: 'department' },
	{ label: 'Business', value: 'business' },
	{ label: 'Corporate', value: 'corporate' },
];

const ReviewsPageTabs = (props) => {
	const { selectedTab, setSelectedTab } = props;

	return (
		<Box borderBottomWidth='1px' borderColor='primary' width='100%'>
			<Tabs index={tabs.findIndex((tab) => tab.value === selectedTab)}>
				<TabList>
					{tabs.map((tab) => (
						<Tab
							width='130px'
							fontWeight={tab.value === selectedTab ? 700 : 400}
							color='primary'
							onClick={() => setSelectedTab(tab.value)}>
							{tab.label}
						</Tab>
					))}
				</TabList>
			</Tabs>
		</Box>
	);
};

export default ReviewsPageTabs;
