import { Box, Button, Card, CardBody, Icon, IconButton, Text, useToast } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { GrAdd } from 'react-icons/gr';
import { useState } from 'react';
import BusinessModal from './BusinessModal/BusinessModal';
import ConfirmationPopup from '../../../Components/common/Popups/ConfirmationPopup';
import axios from 'axios';

const BusinessesSettings = () => {
	const { settings } = useSelector((state) => state.appSettings);
	const [isBusinessModalOpen, setIsBusinessModalOpen] = useState(false);
	const [selectedBusiness, setSelectedBusiness] = useState(null);
	const [isResetConfirmOpen, setIsResetConfirmOpen] = useState(false);
	const [isResetLoading, setIsResetLoading] = useState(false);
	const toast = useToast();

	const handlePermissionGroupsReset = async () => {
		try {
			setIsResetLoading(true);
			await axios.post(`${process.env.REACT_APP_BASE_URL}/app-settings/reset-groups`);
			setIsResetLoading(false);

			setIsResetConfirmOpen(false);
			toast({
				status: 'success',
				title: 'Permission groups reset successfully',
			});
		} catch (e) {
			setIsResetLoading(false);
			toast({
				status: 'error',
				title: 'Error resetting groups',
			});
		}
	};

	if (!settings) {
		return null;
	}

	return (
		<Box>
			<BusinessModal
				isOpen={isBusinessModalOpen}
				selectedBusinessName={selectedBusiness?.name}
				onClose={() => setIsBusinessModalOpen(false)}
			/>

			<ConfirmationPopup
				open={isResetConfirmOpen}
				title='Reset Permission Groups'
				message='This action will remove permission groups of deleted businesses and departments. All users except for administrative users will lose access to data linked to these businesses and departments.'
				onClose={() => setIsResetConfirmOpen(false)}
				isLoading={isResetLoading}
				onConfirm={handlePermissionGroupsReset}
			/>

			<Box display='flex' alignItems='center'>
				<Text fontWeight={600} fontSize='2xl'>
					Businesses
				</Text>

				<IconButton
					variant='ghost'
					onClick={() => {
						setSelectedBusiness(null);
						setIsBusinessModalOpen(true);
					}}>
					<Icon as={GrAdd} />
				</IconButton>

				{/* <Box width='fit-content' ml='auto'>
					<Button colorScheme='blue' onClick={() => setIsResetConfirmOpen(true)}>
						Reset Permission Groups
					</Button>
				</Box> */}
			</Box>

			<Box mt={4} display='flex' gap={4} flexWrap='wrap'>
				{settings.businesses.map((business, index) => (
					<Card
						key={index}
						onClick={() => {
							setSelectedBusiness(business);
							setIsBusinessModalOpen(true);
						}}
						cursor='pointer'>
						<CardBody>
							<Text>{business.label}</Text>
						</CardBody>
					</Card>
				))}
			</Box>
		</Box>
	);
};

export default BusinessesSettings;
