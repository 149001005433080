import { useMemo } from 'react';
import { useContext } from 'react';
import UserContext from '../../context/user/UserContext';
import SelectRiskInput from '../common/Inputs/SelectRiskInput';
import {
	getBusinessInputOptions,
	getDepartmentInputOptions,
	getStatusInputOptions,
	getSubDepartmentInputOptions,
	getThemeInputOptions,
} from '../../redux/utils';
import { useSelector } from 'react-redux';
import SelectInput from '../common/Inputs/SelectInput';
import { Box, FormControl, FormLabel, Input, Radio, RadioGroup, Stack } from '@chakra-ui/react';
import { userHasGlobalAccess } from '../../context/user/utils';

const RisksFilterInputs = ({ onInputChange, inputValues, setInputValues, isRisksLoading }) => {
	const { user } = useContext(UserContext);
	const { settings } = useSelector((state) => state.appSettings);

	const inputOptions = useMemo(
		() => ({
			business: getBusinessInputOptions(user),
			department: getDepartmentInputOptions(user),
			subDepartment: getSubDepartmentInputOptions(inputValues.department),
			theme: getThemeInputOptions(),
			status: getStatusInputOptions(),
		}),
		[settings, user]
	);

	const hasGlobalAccess = useMemo(() => userHasGlobalAccess(user), [user]);

	if (inputValues.report === 'SingleRiskReport') {
		return (
			<FormControl mt={6}>
				<FormLabel>Select Risk</FormLabel>
				<SelectRiskInput
					loading={isRisksLoading}
					value={inputValues.riskId}
					onChange={(value) => onInputChange('riskId', value)}
				/>
			</FormControl>
		);
	}

	return (
		<>
			<Box display='flex' mt={6} gap={4}>
				<FormControl>
					<FormLabel>Status</FormLabel>
					<SelectInput
						placeholder='Select Status'
						value={inputValues.status}
						options={inputOptions['status']}
						defaultChecked={inputValues.status}
						onChange={(value) => onInputChange('status', value)}
					/>
				</FormControl>
				<FormControl>
					<FormLabel>Theme</FormLabel>
					<SelectInput
						placeholder='Select Theme'
						value={inputValues.theme}
						options={inputOptions['theme']}
						defaultChecked={inputValues.theme}
						onChange={(value) => onInputChange('theme', value)}
					/>
				</FormControl>
			</Box>

			<Box display='flex' gap={4} mt={6}>
				<FormControl>
					<FormLabel>Department</FormLabel>
					<SelectInput
						placeholder='Select Department'
						value={inputValues.department}
						options={inputOptions['department']}
						defaultChecked={inputValues.department}
						onChange={(value) => onInputChange('department', value)}
					/>
				</FormControl>
				<FormControl>
					<FormLabel>Residual Rating</FormLabel>
					<Input
						variant='full-screen-form'
						type='number'
						placeholder='Enter Rating'
						maxLength={70}
						min={1}
						max={25}
						value={inputValues.overallResidualRisk || ''}
						onChange={(e) => onInputChange('overallResidualRisk', parseInt(e.target.value))}
					/>
				</FormControl>
			</Box>
			{hasGlobalAccess && (
				<>
					<FormLabel mt={6}>Risks Type</FormLabel>
					<RadioGroup
						value={
							inputValues.corporateRisk === true
								? 'corporate'
								: inputValues.corporateRisk === false
								? 'non-corporate'
								: 'all'
						}
						onChange={(value) => {
							if (value === 'all') {
								delete inputValues.corporateRisk;
								setInputValues({ ...inputValues });
							} else {
								setInputValues({
									...inputValues,
									corporateRisk: value === 'corporate' ? true : false,
								});
							}
						}}
						colorScheme='blue'>
						<Stack direction='row'>
							<Radio value='all'>All</Radio>
							<Radio value='corporate'>Corporate</Radio>
							<Radio value='non-corporate'>Non-corporate</Radio>
						</Stack>
					</RadioGroup>
				</>
			)}
		</>
	);
};

export default RisksFilterInputs;
