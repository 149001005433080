import ConfirmationPopup from './ConfirmationPopup';

const DeleteConfirmPopup = ({ open, isLoading, onClose, onDelete }) => {
	return (
		<ConfirmationPopup
			open={open}
			isLoading={isLoading}
			onClose={onClose}
			onConfirm={onDelete}
			actionButtonContent='Delete'
			title='Delete'
		/>
	);
};

export default DeleteConfirmPopup;
