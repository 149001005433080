import { Box, Card, CardBody, Text } from '@chakra-ui/react';
import { ResponsivePie } from '@nivo/pie';

const ControlsChart = ({ data, controlsCount }) => {
	return (
		<Card>
			<CardBody>
				<div style={{ height: '180px' }}>
					<ResponsivePie
						data={data}
						margin={{ top: 32, right: 0, bottom: 32, left: -62 }}
						cornerRadius={3}
						isInteractive={false}
						activeOuterRadiusOffset={8}
						colors={(item) => item.data.color}
						arcLinkLabel={(arc) =>
							arc.data.value +
							` (${Math.round((Number(arc.data.value) / controlsCount) * 100 * 10) / 10}%)`
						}
						arcLinkLabelsSkipAngle={10}
						arcLinkLabelsTextColor='#333333'
						arcLinkLabelsThickness={2}
						arcLinkLabelsColor={{ from: 'color' }}
						arcLabelsSkipAngle={10}
						arcLabelsTextColor={{
							from: 'color',
							modifiers: [['darker', 2]],
						}}
						// colors={colors}
						tooltip={(props) => (
							<Box
								fontSize={12}
								display='flex'
								alignItems='center'
								p={3}
								bgColor='white'
								boxShadow='md'
								borderRadius={2}>
								<Text mr={2} fontWeight={600}>
									{props.datum.label}:{' '}
								</Text>{' '}
								<Text>{props.datum.value}</Text>
							</Box>
						)}
						legends={[
							{
								anchor: 'right',
								direction: 'column',
								justify: false,
								translateX: 0,
								translateY: 0,
								itemsSpacing: 0,
								itemWidth: 100,
								itemHeight: 32,
								itemTextColor: '#999',
								itemDirection: 'left-to-right',
								itemOpacity: 1,
								symbolSize: 18,
								symbolShape: 'circle',
								effects: [
									{
										on: 'hover',
										style: {
											itemTextColor: '#000',
										},
									},
								],
							},
						]}
					/>
				</div>
			</CardBody>
		</Card>
	);
};

export default ControlsChart;
