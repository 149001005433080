import { useContext } from 'react';
import { useMemo } from 'react';
import UserContext from '../../context/user/UserContext';
import { Td, Tr } from '@chakra-ui/react';

const TeamUserItem = (props) => {
	const { user: currentUser } = useContext(UserContext);
	const { user } = props;

	const isCurrentUser = useMemo(() => currentUser?.sub === user.cognitoId, [user, currentUser]);

	const role = useMemo(() => {
		if (user.groups.includes('CorporateLeader')) {
			return 'Corporate';
		}

		if (user.groups.includes('Manager')) {
			return 'Manager';
		}

		return 'None';
	}, [user]);

	return (
		<Tr>
			<Td>
				{user.name} {isCurrentUser ? '(You)' : ''}
			</Td>
			<Td>{role}</Td>
			<Td>{user.email}</Td>
			<Td>{user.mobile}</Td>
		</Tr>
	);
};

export default TeamUserItem;
