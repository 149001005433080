import { Box, Icon, IconButton, Td, Text, Tooltip, Tr } from '@chakra-ui/react';
import { AiOutlineClose } from 'react-icons/ai';
import { BiErrorCircle } from 'react-icons/bi';

const RiskRow = ({ risk, error, onRemoveClick }) => {
	return (
		<Tr>
			<Td color={Boolean(error) ? 'red.500' : ''} width={200} whiteSpace='initial'>
				<Box gap={2} display='flex' alignItems='center'>
					{Boolean(error) && (
						<Tooltip
							maxW={300}
							whiteSpace='pre-line'
							label={error.map((error) => '- ' + error).join('\n')}>
							<Box cursor='pointer'>
								<Icon fontSize={18} as={BiErrorCircle} />
							</Box>
						</Tooltip>
					)}
					{risk.title}
				</Box>
			</Td>
			<Td>{risk.business}</Td>
			<Td width={100} whiteSpace='initial'>
				{risk.department}
			</Td>
			<Td>{risk.subDepartment}</Td>
			<Td>
				<Text width={200} whiteSpace='initial'>
					{risk.description}
				</Text>
			</Td>
			<Td>{risk.owner}</Td>
			<Td>
				<IconButton
					variant='ghost'
					onClick={() => {
						onRemoveClick();
					}}
					backgroundColor='transparent'>
					<Icon as={AiOutlineClose} />
				</IconButton>
			</Td>
		</Tr>
	);
};

export default RiskRow;
