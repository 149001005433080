import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import RiskFormHeader from '../../Components/RiskForm/RiskFormHeader';
import PageContainer from '../../Components/Layout/PageContainer';
import { useDispatch, useSelector } from 'react-redux';
import RiskSliderInputs from '../../Components/RiskForm/RiskSliderInputs';
import { updateRiskFormState } from '../../redux/slices/riskForm';
import { useToast } from '@chakra-ui/react';

import { isRiskDisabled } from '../../redux/slices/risks';
import UserContext from '../../context/user/UserContext';
import { debounce } from 'lodash';

const ResidualRisk = () => {
	const dispatch = useDispatch();
	const toast = useToast();
	const { inputValues } = useSelector((state) => state.riskForm);
	const { user } = useContext(UserContext);
	const currentRisk = useSelector((state) => state.risks.currentRisk);
	const isDisabled = useMemo(() => isRiskDisabled(currentRisk, user), [currentRisk, user]);

	const debouncedToast = useCallback(
		debounce((inherentValue) => {
			toast({
				title: `Value cannot exceed its corresponding inherent value (${inherentValue})`,
				status: 'warning',
			});
		}, 300),
		[]
	);

	const handleInputChange = (name, value) => {
		const inherentValue = inputValues.inherentRisk?.[name] || 0;
		const isValueMoreThanInherent = inherentValue < Number(value);

		if (isValueMoreThanInherent) {
			debouncedToast(inherentValue);
			return;
		}

		dispatch(
			updateRiskFormState({ residualRisk: { ...inputValues.residualRisk, [name]: Number(value) } })
		);
	};

	useEffect(() => {
		let defaultResidualRisk = {
			...(inputValues.residualRisk || {}),
		};

		if (inputValues.inherentRisk) {
			Object.keys(inputValues.inherentRisk).forEach((key) => {
				if (typeof inputValues.residualRisk?.[key] !== 'number') {
					defaultResidualRisk[key] = inputValues.inherentRisk[key];
				}
			});
		}

		dispatch(updateRiskFormState({ residualRisk: defaultResidualRisk }));
	}, []);

	return (
		<PageContainer>
			<RiskFormHeader />

			<RiskSliderInputs
				type='residual'
				isDisabled={isDisabled}
				inputValues={inputValues.residualRisk || {}}
				onInputChange={handleInputChange}
			/>

		</PageContainer>
	);
};

export default ResidualRisk;
