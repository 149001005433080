import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	Button,
	FormControl,
	Input,
	FormLabel,
	FormErrorMessage,
	useToast,
} from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createBusiness, deleteBusiness } from '../../../../redux/slices/app-settings';
import ConfirmationPopup from '../../../../Components/common/Popups/ConfirmationPopup';
import BusinessDepartments from './Departments';

const BusinessModal = ({ isOpen, selectedBusinessName, onClose }) => {
	const { settings } = useSelector((state) => state.appSettings);

	const [businessName, setBusinessName] = useState('');
	const [error, setError] = useState('');
	const [isSaveLoading, setIsSaveLoading] = useState(false);
	const [isDeleteLoading, setIsDeleteLoading] = useState(false);
	const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] = useState(false);
	const dispatch = useDispatch();
	const toast = useToast();

	const selectedBusiness = useMemo(
		() => settings.businesses.find((business) => business.name === selectedBusinessName),
		[selectedBusinessName, settings]
	);

	const handleNameInputChange = (e) => {
		const { value } = e.target;
		setBusinessName(value);

		const lowerCaseValue = value.toLowerCase();

		const existingBusiness = settings.businesses.find(
			(business) =>
				business.name.toLowerCase() === lowerCaseValue ||
				business.label.toLowerCase() === lowerCaseValue
		);
		if (existingBusiness) {
			setError('A business with this name already exists');
		} else {
			setError('');
		}
	};

	const handleDelete = async () => {
		setIsDeleteLoading(true);
		setIsDeleteConfirmModalOpen(false);

		await dispatch(deleteBusiness(selectedBusiness.name));

		setIsDeleteLoading(false);
		onClose();

		toast({
			status: 'success',
			title: 'Business deleted successfully',
		});
	};

	const handleSave = async () => {
		if (error) {
			return;
		}

		if (!businessName) {
			setError('Required');
			return
		}

		setIsSaveLoading(true);

		await dispatch(createBusiness(businessName));

		setIsSaveLoading(false);

		onClose();

		toast({
			status: 'success',
			title: 'Business created successfully',
		});
	};

	useEffect(() => {
		if (isOpen) {
			setBusinessName('');
			setError('');
		}
	}, [isOpen]);

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ConfirmationPopup
				open={isDeleteConfirmModalOpen}
				title='Delete Business'
				onConfirm={handleDelete}
				onClose={() => setIsDeleteConfirmModalOpen(false)}
			/>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>{selectedBusiness ? 'Edit Business' : 'New Business'}</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					{selectedBusiness ? (
						<BusinessDepartments business={selectedBusiness} />
					) : (
						<FormControl isInvalid={Boolean(error)}>
							<FormLabel fontSize={14}>Business Name</FormLabel>
							<Input
								placeholder='Enter name...'
								value={businessName}
								onChange={handleNameInputChange}
							/>
							<FormErrorMessage>{error}</FormErrorMessage>
						</FormControl>
					)}
				</ModalBody>

				<ModalFooter>
					<Button variant='ghost' mr={3} onClick={onClose}>
						Close
					</Button>

					{selectedBusiness ? (
						<Button
							onClick={() => setIsDeleteConfirmModalOpen(true)}
							isLoading={isDeleteLoading}
							colorScheme='red'>
							Delete
						</Button>
					) : (
						<Button
							isLoading={isSaveLoading}
							colorScheme='blue'
							onClick={handleSave}
							isDisabled={Boolean(error)}>
							Save
						</Button>
					)}
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default BusinessModal;
