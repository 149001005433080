import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Button,
} from '@chakra-ui/react';
import { useRef } from 'react';

const ConfirmationPopup = ({
	open,
	onClose,
	title,
	actionButtonContent,
	isLoading,
	onConfirm,
	message,
}) => {
	const cancelRef = useRef();

	return (
		<AlertDialog isOpen={open} leastDestructiveRef={cancelRef} onClose={onClose}>
			<AlertDialogOverlay>
				<AlertDialogContent>
					{title && (
						<AlertDialogHeader fontSize='lg' fontWeight='bold'>
							{title}
						</AlertDialogHeader>
					)}

					<AlertDialogBody>
						{message || "Are you sure? You can't undo this action afterwards."}
					</AlertDialogBody>

					<AlertDialogFooter>
						<Button variant='ghost' ref={cancelRef} onClick={onClose}>
							Cancel
						</Button>
						<Button isLoading={isLoading} colorScheme='red' onClick={onConfirm} ml={3}>
							{actionButtonContent || 'Confirm'}
						</Button>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialogOverlay>
		</AlertDialog>
	);
};

export default ConfirmationPopup;
