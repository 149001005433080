import { useEffect, useMemo } from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { formatDate, getRiskShortId } from '../../api/helpers';
import { useDispatch } from 'react-redux';
import { updateAction } from '../../redux/slices/actions';
import {
	Box,
	Button,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Heading,
	Text,
	Textarea,
	useToast,
} from '@chakra-ui/react';

const SelectedActionForm = (props) => {
	const [isSubmitLoading, setIsSubmitLoading] = useState(false);
	const [commentsInputValue, setCommentsInputValue] = useState('');
	const [error, setError] = useState('');
	const dispatch = useDispatch();
	const { selectedAction, isInModal, onComplete } = props;
	const toast = useToast();
	const isActionCompleted = useMemo(() => selectedAction.status === 'completed', [selectedAction]);

	const handleCompleteClick = async () => {
		if (!commentsInputValue) {
			setError('Required');
			return;
		}
		setIsSubmitLoading(true);
		const updates = { _id: selectedAction._id, comments: commentsInputValue, status: 'completed' };

		await dispatch(updateAction(updates));
		setIsSubmitLoading(false);
		onComplete(updates);

		toast({ status: 'success', title: 'Action completed successfully' });
	};

	useEffect(() => {
		setCommentsInputValue(selectedAction.comments || '');
		setError('');
	}, [selectedAction]);

	return (
		<Box sx={!isInModal ? { width: '60%', pl: 12, pt: 6 } : {}}>
			<Box
				borderWidth={!isInModal ? '1px' : ''}
				borderColor={!isInModal ? 'grey.100' : ''}
				bgColor='white'
				borderRadius={26}
				p={!isInModal ? 6 : 0}>
				{!isInModal && (
					<Heading py={0} pb={6} fontSize='3xl'>
						Selected Action
					</Heading>
				)}

				<Label>Action</Label>
				<Value>{selectedAction.action}</Value>

				<Box mt={4} mb={4} display='flex'>
					<div style={{ width: '25%' }}>
						<Label>ID</Label>
						<Value>ACT{selectedAction.actionSeq}</Value>
					</div>
					<div style={{ width: '25%' }}>
						<Label>Related Risk</Label>
						{/* TODO: generate and get directly from back-end instead */}
						<Link
							to={`/risk?riskId=${selectedAction.risk}`}
							style={{ textDecoration: 'underline' }}
							className='text-sm'>
							#{getRiskShortId(selectedAction.risk)}
						</Link>
					</div>
					<div style={{ width: '25%' }}>
						<Label>Target Date</Label>
						<Value>{formatDate(selectedAction.targetDate)}</Value>
					</div>
					<div style={{ width: '25%' }}>
						<Label>Status</Label>
						<Value>{selectedAction.status === 'completed' ? 'Completed' : 'In Progress'}</Value>
					</div>
				</Box>

				<Label>Description</Label>
				<Value>{selectedAction.description || 'No description added'}</Value>

				<FormControl mt={4} isInvalid={Boolean(error)}>
					<FormLabel>Comments</FormLabel>

					<Textarea
						rows={5}
						disabled={isActionCompleted}
						style={{ opacity: isActionCompleted ? 0.7 : 1 }}
						onChange={(e) => {
							setError('');
							setCommentsInputValue(e.target.value);
						}}
						value={commentsInputValue}
					/>

					<FormErrorMessage>{error}</FormErrorMessage>
				</FormControl>

				<div style={{ width: 'fit-content', marginLeft: 'auto', marginTop: 16 }}>
					<Button
						isLoading={isSubmitLoading}
						isDisabled={isActionCompleted || Boolean(error)}
						onClick={handleCompleteClick}>
						{isActionCompleted ? 'Completed' : 'Complete Action'}
					</Button>
				</div>
			</Box>
		</Box>
	);
};

const Label = ({ children }) => {
	return (
		<Text fontWeight={700} color='primary' fontSize='14px'>
			{children}
		</Text>
	);
};

const Value = ({ children }) => {
	return (
		<Text fontSize={14} color='GrayText' textTransform='capitalize'>
			{children}
		</Text>
	);
};

export default SelectedActionForm;
