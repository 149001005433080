import { useContext, useMemo, useState } from 'react';
import RiskFormHeader from '../../Components/RiskForm/RiskFormHeader';
import PageContainer from '../../Components/Layout/PageContainer';
import ControlModal from '../../Components/RiskForm/Controls/Modal';
import { useDispatch, useSelector } from 'react-redux';
import DeleteConfirmPopup from '../../Components/common/Popups/DeleteConfirmPopup';
import TableRow from '../../Components/RiskForm/Controls/TableRow';
import { updateRiskFormState } from '../../redux/slices/riskForm';
import { Box, Button, Icon, Text } from '@chakra-ui/react';
import { BiPlus } from 'react-icons/bi';
import {
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	TableContainer,
} from '@chakra-ui/react';
import UserContext from '../../context/user/UserContext';
import { isRiskDisabled } from '../../redux/slices/risks';

const Controls = () => {
	const dispatch = useDispatch();
	const [selectedControlIndex, setSelectedControlIndex] = useState(null);
	const [awaitingDeleteActionIndex, setAwaitingDeleteActionIndex] = useState(null);
	const [isControlModalOpen, setIsControlModalOpen] = useState(false);
	const riskFormData = useSelector((state) => state.riskForm);
	const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
	const { user } = useContext(UserContext);
	const currentRisk = useSelector((state) => state.risks.currentRisk);
	const isDisabled = useMemo(() => isRiskDisabled(currentRisk, user), [currentRisk, user]);

	const updateControls = (controls) => {
		dispatch(updateRiskFormState({ controls }));
	};

	const handleEditClick = (index) => {
		setSelectedControlIndex(index);
		setIsControlModalOpen(true);
	};

	const handleDeleteClick = (index) => {
		setDeleteConfirmOpen(true);
		setAwaitingDeleteActionIndex(index);
	};

	const handleDeleteConfirm = () => {
		const updatedItems = riskFormData.inputValues.controls.filter(
			(i, index) => index !== awaitingDeleteActionIndex
		);

		updateControls(updatedItems);
		setAwaitingDeleteActionIndex(null);
		setDeleteConfirmOpen(false);
	};

	return (
		<PageContainer>
			<RiskFormHeader />

			<Box display='flex' alignItems='center'>
				<Text fontSize='2xl' fontWeight={500}>
					Controls
				</Text>

				<Box width='fit-content' ml='auto'>
					<Button
						isDisabled={isDisabled}
						onClick={() => {
							setSelectedControlIndex(undefined);
							setIsControlModalOpen(true);
						}}
						leftIcon={<Icon as={BiPlus} />}>
						Add New
					</Button>
				</Box>
			</Box>

			<ControlModal
				isOpen={isControlModalOpen}
				tableItems={riskFormData.inputValues?.controls || []}
				selectedTableIndex={selectedControlIndex}
				setTableItems={updateControls}
				setSelectedTableIndex={setSelectedControlIndex}
				onClose={() => setIsControlModalOpen(false)}
			/>

			<TableContainer bgColor='white' mt={6}>
				<Table variant='striped'>
					<Thead>
						<Tr>
							<Th>#</Th>
							<Th>Cause</Th>
							<Th>Control&nbsp;Owner</Th>
							<Th>Mitigation</Th>
							<Th>Rating</Th>
							<Th>Control&nbsp;Detail</Th>
							<Th textAlign='center'>Actions</Th>
						</Tr>
					</Thead>
					<Tbody>
						{riskFormData.inputValues.controls?.map((item, i) => (
							<TableRow
								item={item}
								key={i}
								index={i}
								onEditClick={handleEditClick}
								onDeleteClick={handleDeleteClick}
							/>
						))}
					</Tbody>
				</Table>
			</TableContainer>


			<DeleteConfirmPopup
				open={deleteConfirmOpen}
				onClose={() => setDeleteConfirmOpen(false)}
				onDelete={handleDeleteConfirm}
			/>
		</PageContainer>
	);
};

export default Controls;
