import { useContext, useState } from 'react';
import { useEffect } from 'react';
import { v4 } from 'uuid';
import ErrorText from '../../common/ErrorText';
import { useSelector } from 'react-redux';
import { isRiskDisabled } from '../../../redux/slices/risks';
import UserContext from '../../../context/user/UserContext';
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	Button,
	Input,
	Icon,
} from '@chakra-ui/react';
import { capitalize } from 'lodash';
import { AiOutlineSave } from 'react-icons/ai';

/** Used in causes, consequences and comments  */
const EntryFormModal = ({
	isOpen,
	selectedTableIndex,
	setSelectedTableIndex,
	labelPropertyName,
	tableItems,
	setTableItems,
	onClose,
}) => {
	const [inputValue, setInputValue] = useState('');
	const [error, setError] = useState('');

	const currentRisk = useSelector((state) => state.risks.currentRisk);
	const { user } = useContext(UserContext);
	const isDisabled = isRiskDisabled(currentRisk, user);

	const handleSubmit = () => {
		if (!inputValue) {
			setError('Required');
			return;
		} else {
			setError('');
		}

		let data = {
			id: v4(),
			[labelPropertyName]: inputValue,
		};

		let updatedItems = [...tableItems];

		if (typeof selectedTableIndex === 'number') {
			if (updatedItems[selectedTableIndex]?.[labelPropertyName]) {
				updatedItems[selectedTableIndex] = data;
			}
			setTableItems([...updatedItems]);
		} else {
			data.number = tableItems.length + 1;
			updatedItems = [...tableItems, data];
			setTableItems(updatedItems);
		}

		setSelectedTableIndex(null);
		setInputValue('');
		onClose();
	};

	useEffect(() => {
		if (isOpen) {
			setError('');

			if (typeof selectedTableIndex === 'number') {
				setInputValue(tableItems[selectedTableIndex][labelPropertyName]);
			} else {
				setInputValue('');
			}
		}
	}, [selectedTableIndex, isOpen]);

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>
					{typeof selectedTableIndex === 'number' ? 'Edit' : 'Add'} {capitalize(labelPropertyName)}
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<div>
						<Input
							autoFocus
							onKeyDown={(e) => e.key === 'Enter' && handleSubmit()}
							disabled={isDisabled}
							type='text'
							placeholder={`Enter ${labelPropertyName}`}
							onChange={(e) => setInputValue(e.target.value)}
							value={inputValue}
							className='pl-[20px] input_shedow rounded-[8px] text-[#000000] h-[50px] lg:w-[610px] md:w-[579px]  w-[100%] '
						/>

						{error && <ErrorText>{error}</ErrorText>}
					</div>
				</ModalBody>

				<ModalFooter>
					<Button variant='ghost' mr={3} onClick={onClose}>
						Close
					</Button>
					<Button onClick={handleSubmit} leftIcon={<Icon as={AiOutlineSave} />} variant='solid'>
						Save
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default EntryFormModal;
