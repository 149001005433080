import React, { useContext, useMemo, useState } from 'react';
import RiskFormHeader from '../../Components/RiskForm/RiskFormHeader';
import PageContainer from '../../Components/Layout/PageContainer';
import EntryFormModal from '../../Components/RiskForm/SingleInputTableForm/EntryFormModal';
import TableRow from '../../Components/RiskForm/SingleInputTableForm/TableRow';
import DeleteConfirmPopup from '../../Components/common/Popups/DeleteConfirmPopup';
import { updateRiskFormState } from '../../redux/slices/riskForm';
import { useDispatch, useSelector } from 'react-redux';
import {
	Box,
	Button,
	Icon,
	Table,
	TableContainer,
	Tbody,
	Text,
	Th,
	Thead,
	Tr,
} from '@chakra-ui/react';
import { BiPlus } from 'react-icons/bi';
import UserContext from '../../context/user/UserContext';
import { isRiskDisabled } from '../../redux/slices/risks';

const Consequences = () => {
	const [selectedConsequenceIndex, setSelectedConsequenceIndex] = useState(null);
	const dispatch = useDispatch();
	const riskFormData = useSelector((state) => state.riskForm);
	const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
	const [isEntryModalOpen, setIsEntryModalOpen] = useState(false);
	const { user } = useContext(UserContext);
	const currentRisk = useSelector((state) => state.risks.currentRisk);
	const isDisabled = useMemo(() => isRiskDisabled(currentRisk, user), [currentRisk, user]);

	const updateConsequences = (consequences) => {
		dispatch(updateRiskFormState({ consequences }));
	};

	const handleEditClick = (index) => {
		setSelectedConsequenceIndex(index);
		setIsEntryModalOpen(true);
	};

	const handleDeleteClick = (index) => {
		setDeleteConfirmOpen(true);
		setSelectedConsequenceIndex(index);
	};

	const handleDeleteConfirm = () => {
		const updatedConsequences = riskFormData.inputValues.consequences.filter(
			(i, index) => index !== selectedConsequenceIndex
		);

		updateConsequences(updatedConsequences);
		setSelectedConsequenceIndex(null);
		setDeleteConfirmOpen(false);
	};

	return (
		<PageContainer>
			<RiskFormHeader />
			<div>
				<Box display='flex' alignItems='center'>
					<Text fontSize='2xl' fontWeight={500}>
						Consequences
					</Text>

					<Box width='fit-content' ml='auto'>
						<Button
							isDisabled={isDisabled}
							onClick={() => {
								setSelectedConsequenceIndex(undefined);
								setIsEntryModalOpen(true);
							}}
							leftIcon={<Icon as={BiPlus} />}>
							Add New
						</Button>
					</Box>
				</Box>
				<EntryFormModal
					isOpen={isEntryModalOpen}
					labelPropertyName='consequence'
					tableItems={riskFormData.inputValues?.consequences || []}
					setTableItems={updateConsequences}
					selectedTableIndex={selectedConsequenceIndex}
					setSelectedTableIndex={setSelectedConsequenceIndex}
					onClose={() => setIsEntryModalOpen(false)}
				/>
				<TableContainer bgColor='white' mt={6}>
					<Table variant='striped'>
						<Thead>
							<Tr>
								<Th width='100px'>#</Th>
								<Th>Consequence</Th>
								<Th>Actions</Th>
							</Tr>
						</Thead>
						<Tbody>
							{riskFormData.inputValues?.consequences?.map((item, index) => (
								<TableRow
									label={item.consequence}
									onEditClick={handleEditClick}
									onDeleteClick={handleDeleteClick}
									index={index}
									key={index}
								/>
							))}
						</Tbody>
					</Table>
				</TableContainer>
			</div>

			<DeleteConfirmPopup
				open={deleteConfirmOpen}
				onClose={() => setDeleteConfirmOpen(false)}
				onDelete={handleDeleteConfirm}
			/>
		</PageContainer>
	);
};

export default Consequences;
