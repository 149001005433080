import { useEffect } from 'react';
import { useState } from 'react';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCorporateRisks, fetchRisks } from '../../../redux/slices/risks';
import SelectInput from './SelectInput';
import { Text } from '@chakra-ui/react';

const SelectRiskInput = ({ corporateRisksOnly, value, onChange, isDisabled }) => {
	const [isLoading, setIsLoading] = useState(true);
	const { risks, corporateRisks } = useSelector((state) => state.risks);
	const dispatch = useDispatch();

	const options = useMemo(() => {
		let filteredRisks = corporateRisksOnly ? corporateRisks : risks;

		return filteredRisks.map((risk) => ({
			value: risk._id,
			label: risk.title,
			shortId: risk.shortId,
		}));
	}, [risks, corporateRisks]);

	useEffect(() => {
		if (corporateRisksOnly && corporateRisks.length === 0) {
			(async () => {
				await dispatch(fetchCorporateRisks());
				setIsLoading(false);
			})();
		} else if (!corporateRisksOnly && risks.length === 0) {
			(async () => {
				await dispatch(fetchRisks());
				setIsLoading(false);
			})();
		} else {
			setIsLoading(false);
		}
	}, []);

	return (
		<SelectInput
			isSearchable
			components={{
				Option: (props) => {
					return (
						<Text>
							<strong>{props.shortId}</strong> - {props.label}
						</Text>
					);
				},
			}}
			emptyListMessage='No risks found'
			isDisabled={isDisabled}
			placeholder='Select Risk'
			options={options}
			isLoading={isLoading}
			onChange={onChange}
			value={value}
		/>
	);
};

export default SelectRiskInput;
