import { AiOutlineArrowLeft, AiOutlineArrowRight, AiOutlineSave } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import React, { useContext, useMemo } from 'react';
import {
	resetRiskFormState,
	setHasUnsavedChanges,
	submitRisk,
	validateRiskInputs,
} from '../../../redux/slices/riskForm';
import { store } from '../../../redux/store';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useQuery } from '../../../App';
import { isRiskDisabled, setCurrentRisk } from '../../../redux/slices/risks';
import UserContext from '../../../context/user/UserContext';
import { Box, Button, Icon, useToast } from '@chakra-ui/react';
import { riskFormTabs } from '../../RiskForm/RiskFormHeader';
import { getRiskFormPageRoute } from '../../../pages/Risk/utils';

const RiskFormButtons = (props) => {
	const [isLoading, setIsLoading] = useState(false);
	const [lastClickedButton, setLastClickedButton] = useState();
	const currentRisk = useSelector((state) => state.risks.currentRisk);
	const { user } = useContext(UserContext);
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();
	const toast = useToast();
	const query = useQuery();

	const isDisabled = isRiskDisabled(currentRisk, user);

	const { nextRoute, prevRoute } = useMemo(() => {
		const currentRouteIndex = riskFormTabs.findIndex((tab) => tab.path === location.pathname);

		return {
			nextRoute: riskFormTabs[currentRouteIndex + 1]?.path,
			prevRoute: riskFormTabs[currentRouteIndex - 1]?.path,
		};
	}, [location]);

	const handleSubmit = async (exit) => {
		if (isLoading) {
			return;
		}

		dispatch(validateRiskInputs());

		if (Object.keys(store.getState().riskForm.errors).length) {
			toast({
				title: 'Please resolve the errors before submitting',
				status: 'error',
			});
			return;
		}

		setIsLoading(true);
		const result = await dispatch(submitRisk(query.get('riskId')));
		setIsLoading(false);
		dispatch(resetRiskFormState());

		toast({
			title: `Risk ${currentRisk ? 'updated' : 'submitted'} successfully`,
			status: 'success',
		});

		if (result.payload && !currentRisk) {
			dispatch(setHasUnsavedChanges(false));

			if (exit) {
				dispatch(setCurrentRisk(null));
				navigate('/');
			} else {
				dispatch(setCurrentRisk(result.payload));
				navigate(`/risk/causes?riskId=${result.payload._id}`);
			}
		} else {
			dispatch(setCurrentRisk(null));
			navigate('/risks');
		}
	};

	if (isDisabled) {
		return <></>;
	}

	return (
		<Box display='flex' width='fit-content' ml='auto' gap={4}>
			{currentRisk ? (
				<>
					<Button
						variant='ghost'
						leftIcon={<Icon as={AiOutlineArrowLeft} />}
						isDisabled={!prevRoute || isLoading}
						onClick={() => {
							navigate(getRiskFormPageRoute(prevRoute, query), { state: location.state });
						}}>
						Prev
					</Button>

					<Button
						variant='ghost'
						isDisabled={!nextRoute || isLoading}
						rightIcon={<Icon as={AiOutlineArrowRight} />}
						onClick={() => {
							navigate(getRiskFormPageRoute(nextRoute, query), { state: location.state });
						}}>
						Next
					</Button>

					<Button
						isLoading={lastClickedButton === 'Save' && isLoading}
						isDisabled={isLoading}
						leftIcon={<Icon as={AiOutlineSave} />}
						width={150}
						onClick={() => {
							setLastClickedButton('Save');
							handleSubmit();
						}}>
						Save
					</Button>
				</>
			) : (
				<>
					<Button
						isLoading={lastClickedButton === 'Submit and Continue' && isLoading}
						isDisabled={isLoading}
						onClick={() => {
							setLastClickedButton('Submit and Continue');
							handleSubmit(false);
						}}>
						Submit and Continue
					</Button>
					<Button
						isLoading={lastClickedButton === 'Submit and Exit' && isLoading}
						isDisabled={isLoading}
						onClick={() => {
							setLastClickedButton('Submit and Exit');
							handleSubmit(true);
						}}>
						Submit and Exit
					</Button>
				</>
			)}
		</Box>
	);
};

export default RiskFormButtons;
