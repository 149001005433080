import { Button } from '@chakra-ui/react';

const TextButton = (props) => {
	const { style, children, ...rest } = props;

	return (
		<Button {...rest} variant='ghost' size='sm'>
			{children}
		</Button>
	);
};

export default TextButton;
