import { SET_USER_INFO, SET_USER_SETTINGS } from '../types';

const UserReducer = (state, action) => {
	switch (action.type) {
		case SET_USER_INFO: {
			return {
				...state,
				user: action.payload,
			};
		}
		case SET_USER_SETTINGS: {
			return {
				...state,
				settings: action.payload,
			};
		}

		default: {
			state.settings = null;
			return state;
		}
	}
};
export default UserReducer;
