import { createContext } from 'react';
import axios from 'axios';
import { isExpired } from 'react-jwt';
import config from '../../config';

const { cognitoAuthUrl, cognitoClientId, cognitoClientSecret } = config;
const UserContext = createContext();

export const refreshTokens = (redirect) => {
	return new Promise(async (resolve, reject) => {
		let refreshToken = localStorage.getItem('refresh_token');

		let urlParames = new URLSearchParams({
			grant_type: 'refresh_token',
			client_id: cognitoClientId,
			client_secret: cognitoClientSecret,
			refresh_token: refreshToken,
		});

		try {
			const result = await axios.post(
				cognitoAuthUrl + '/oauth2/token',
				urlParames.toString(),

				{
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded',
					},
				}
			);
			const { id_token, access_token, expires_in } = result.data;
			localStorage.setItem('id_token', id_token);
			localStorage.setItem('access_token', access_token);
			localStorage.setItem('expires_in', expires_in);

			resolve(access_token);
		} catch (e) {
			console.log(e.response);
			localStorage.clear();
			window.location.replace('/login');
			reject();
		}
	});
};

axios.interceptors.request.use(async (config) => {
	const accessToken = localStorage.getItem('access_token');
	if (
		(isExpired(accessToken) || !config.headers.Authorization) &&
		!config.url.includes(cognitoAuthUrl)
	) {
		const newAccessToken = await refreshTokens();
		config.headers.Authorization = `Bearer ${newAccessToken}`;
	}

	return config;
});

export const axisoInstance = axios;
export default UserContext;
