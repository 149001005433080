import { Box } from '@chakra-ui/react';
import { Oval } from 'react-loader-spinner';

const LoadingSpinner = (props) => {
	return (
		<Box sx={props.sx} style={{ width: 'fit-content', margin: 'auto', ...props.style }}>
			<Oval
				height={props.height || 50}
				width={props.width || 50}
				strokeWidth={3}
				color='#4f74e8'
				secondaryColor='#4f74e8'
			/>
		</Box>
	);
};

export default LoadingSpinner;
