import { RiDeleteBinLine, RiEdit2Line } from 'react-icons/ri';
import { Box, IconButton, Td, useToast } from '@chakra-ui/react';
import TextButton from '../../../common/Buttons/TextButton';
import { useContext, useMemo, useState } from 'react';
import UserContext from '../../../../context/user/UserContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { isUserRiskManager } from '../../../../context/user/utils';
import { useDispatch } from 'react-redux';
import { deleteRisk, reopenRisk, setCurrentRisk } from '../../../../redux/slices/risks';
import DeleteConfirmPopup from '../../../common/Popups/DeleteConfirmPopup';
import ConfirmationPopup from '../../../common/Popups/ConfirmationPopup';

const RiskTableRowActions = ({ risk }) => {
	const [isDeleteLoading, setIsDeleteLoading] = useState(false);
	const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
	const [isReopenConfirmOpen, setIsReopenConfirmOpen] = useState(false);
	const [isReopenLoading, setIsReopenLoading] = useState(false);
	const { user } = useContext(UserContext);
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const toast = useToast();

	const isCurrentUserRiskManager = useMemo(() => {
		return isUserRiskManager(user);
	}, [user]);

	const isDeleteButtonVisible = useMemo(
		() => user.sub === risk.owner?.cognitoId || isCurrentUserRiskManager,
		[isCurrentUserRiskManager, user]
	);

	const handleEditClick = (e) => {
		e.stopPropagation();
		dispatch(setCurrentRisk(risk));
		console.log(location.pathname);
		navigate(`/risk?riskId=${risk?._id}`, {
			state: { prevPath: location.pathname + location.search },
		});
	};

	const handleDeleteClick = (e) => {
		e.stopPropagation();
		setIsDeleteConfirmOpen(true);
	};

	const handleReopenClick = async (e) => {
		e.stopPropagation();
		if (isReopenConfirmOpen) {
			setIsReopenLoading(true);
			await dispatch(reopenRisk(risk));
			setIsReopenLoading(false);
			setIsReopenConfirmOpen(false);
			toast({ title: 'Risk re-opened successfully', status: 'success' });
		} else {
			setIsReopenConfirmOpen(true);
		}
	};

	const handleDeleteConfirm = async () => {
		setIsDeleteLoading(true);
		await dispatch(deleteRisk(risk._id));
		setIsDeleteConfirmOpen(false);
		setIsDeleteLoading(false);
		toast({ title: 'Risk moved to archive successfully', status: 'success' });
	};

	return (
		<>
			<DeleteConfirmPopup
				open={isDeleteConfirmOpen}
				isLoading={isDeleteLoading}
				onClose={() => setIsDeleteConfirmOpen(false)}
				onDelete={handleDeleteConfirm}
			/>
			<ConfirmationPopup
				isLoading={isReopenLoading}
				open={isReopenConfirmOpen}
				message={`Are you sure you want to re-open this risk? ${
					risk.response ? 'This would remove the current response to this risk.' : ''
				}`}
				onConfirm={handleReopenClick}
				onClose={() => setIsReopenConfirmOpen(false)}
			/>

			<Td>
				<Box display='flex' alignItems='center' ju8stifyContent='center'>
					{risk.status === 'archived' ? (
						<TextButton onClick={handleReopenClick}>Re-open</TextButton>
					) : (
						<>
							<IconButton variant='ghost' onClick={handleEditClick}>
								<RiEdit2Line />
							</IconButton>
							{isDeleteButtonVisible && (
								<IconButton variant='ghost' onClick={handleDeleteClick}>
									<RiDeleteBinLine />
								</IconButton>
							)}
						</>
					)}
				</Box>
			</Td>
		</>
	);
};

export default RiskTableRowActions;
