import {
	Box,
	Button,
	FormControl,
	FormLabel,
	Icon,
	Input,
	ModalBody,
	ModalFooter,
	Textarea,
} from '@chakra-ui/react';
import SelectUserInput from '../../common/Inputs/SelectUserInput';
import ErrorText from '../../common/ErrorText';
import { useContext, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { AiOutlineSave } from 'react-icons/ai';
import { isRiskDisabled } from '../../../redux/slices/risks';
import { useSelector } from 'react-redux';
import UserContext from '../../../context/user/UserContext';

const defaultInputValues = {
	status: 'inProgress',
};

const inputNames = ['action', 'owner', 'targetDate'];

const ActionForm = ({
	isOpen,
	onClose,
	tableItems,
	selectedTableIndex,
	setTableItems,
	setSelectedTableIndex,
}) => {
	const [inputValues, setInputValues] = useState(defaultInputValues);
	const { user } = useContext(UserContext);
	const currentRisk = useSelector((state) => state.risks.currentRisk);

	const [errors, setErrors] = useState({});

	const isDisabled = useMemo(() => isRiskDisabled(currentRisk, user), [currentRisk, user]);

	const handleInputChange = (name, value) => {
		setInputValues({ ...inputValues, [name]: value });
	};

	const handleSubmit = () => {
		let errors = {};

		inputNames.forEach((name) => {
			if (typeof inputValues[name] === 'undefined') {
				errors[name] = 'Required';
			}
		});

		setErrors({ ...errors });

		if (Object.keys(errors).length) {
			return;
		}

		let updatedItems = [...tableItems];

		if (typeof selectedTableIndex === 'number') {
			updatedItems[selectedTableIndex] = inputValues;

			setTableItems([...updatedItems]);
		} else {
			updatedItems = [...tableItems, inputValues];
			setTableItems(updatedItems);
		}

		setSelectedTableIndex(null);
		setInputValues({});
		onClose();
	};

	useEffect(() => {
		if (isOpen) {
			setErrors({});
			if (typeof selectedTableIndex === 'number') {
				setInputValues({
					...defaultInputValues,
					...tableItems[selectedTableIndex],
				});
			} else {
				setInputValues({});
			}
		}
	}, [selectedTableIndex, isOpen]);

	return (
		<>
			<ModalBody>
				<Box mb={6} display='flex' gap={8}>
					<FormControl width='55%'>
						<FormLabel>Action</FormLabel>
						<Input
							variant='full-screen-form'
							disabled={isDisabled}
							placeholder='Action'
							onChange={(e) => handleInputChange('action', e.target.value)}
							value={inputValues.action || ''}
						/>

						{errors.action && <ErrorText>{errors.action}</ErrorText>}
					</FormControl>
					<FormControl width='40%'>
						<FormLabel>Action Owner</FormLabel>
						<SelectUserInput
							isDisabled={isDisabled}
							value={
								typeof inputValues.owner === 'object' ? inputValues.owner?._id : inputValues.owner
							}
							onChange={(value) => handleInputChange('owner', value)}
						/>

						{errors.owner && <ErrorText>{errors.owner}</ErrorText>}
					</FormControl>
				</Box>
				<FormControl mb={6}>
					<FormLabel>Target Date</FormLabel>
					<Input
						type='date'
						min={moment().format('YYYY-MM-DD')}
						value={
							inputValues.targetDate
								? moment(new Date(inputValues.targetDate)).format('YYYY-MM-DD')
								: ''
						}
						onChange={(e) =>
							handleInputChange('targetDate', moment(new Date(e.target.value)).format('YYYY-MM-DD'))
						}
					/>
					{errors.targetDate && <ErrorText>{errors.targetDate}</ErrorText>}
				</FormControl>

				<FormControl>
					<FormLabel>Description</FormLabel>
					<Textarea
						disabled={isDisabled}
						rows={5}
						onChange={(e) => handleInputChange('description', e.target.value)}
						value={inputValues.description || ''}
					/>
					{errors.updates && <ErrorText>{errors.updates}</ErrorText>}
				</FormControl>
			</ModalBody>

			<ModalFooter>
				<Button variant='ghost' mr={3} onClick={onClose}>
					Close
				</Button>
				<Button
					isDisabled={isDisabled}
					onClick={handleSubmit}
					leftIcon={<Icon as={AiOutlineSave} />}
					variant='solid'>
					Save
				</Button>
			</ModalFooter>
		</>
	);
};

export default ActionForm;
