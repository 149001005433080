import { Text } from '@chakra-ui/react';

const ErrorText = (props) => {
	return (
		<Text color='red.500' mt={2} fontSize='sm'>
			{props.children}
		</Text>
	);
};

export default ErrorText;
