import PageContainer from '../Components/Layout/PageContainer';
import { useEffect, useState } from 'react';
import RisksFilterInputs from '../Components/Reports/RisksFilterInputs';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { fetchRisks } from '../redux/slices/risks';
import { Box, Button, FormControl, FormLabel, Heading, Text, useToast } from '@chakra-ui/react';
import SelectInput from '../Components/common/Inputs/SelectInput';

const Reports = () => {
	const [inputValues, setInputValues] = useState({ report: 'DetailedRiskReport' });
	const [isSubmitLoading, setIsSubmitLoading] = useState(false);
	const [isRisksLoading, setIsRisksLoading] = useState(false);
	const [error, setError] = useState('');
	const toast = useToast();
	const dispatch = useDispatch();

	const handleInputChange = (name, value) => {
		if (error === 'No risk selected' && name === 'riskId') {
			setError('');
		}

		setInputValues({ ...inputValues, [name]: value });
	};

	const handleSubmit = async () => {
		if (inputValues.report === 'SingleRiskReport' && !inputValues.riskId) {
			setError('No risk selected');
			return;
		} else {
			setError('');
		}

		setIsSubmitLoading(true);

		try {
			const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/reports`, {
				params: inputValues,
			});

			window.open(response.data.url, '_blank');
			setIsSubmitLoading(false);
		} catch (error) {
			if (error.response.data?.error === 'NoRisksFound') {
				toast({ title: 'No risks were found to be shown on this report', status: 'error' });
			}
			setIsSubmitLoading(false);
			return { error };
		}
	};

	useEffect(() => {
		(async () => {
			setIsRisksLoading(true);
			dispatch(fetchRisks());
			setIsRisksLoading(false);
		})();
	}, []);

	useEffect(() => {
		if (inputValues.report !== 'SingleRiskReport') {
			delete inputValues.riskId;
		}
	}, [inputValues.report]);

	return (
		<PageContainer>
			<Heading>Reporting</Heading>
			<FormControl width='49%'>
				<FormLabel>Report</FormLabel>
				<SelectInput
					placeholder='Select Report'
					options={reports}
					onChange={(value) => handleInputChange('report', value)}
					value={inputValues.report}
				/>
			</FormControl>

			<RisksFilterInputs
				isRisksLoading={isRisksLoading}
				onInputChange={handleInputChange}
				setInputValues={setInputValues}
				inputValues={inputValues}
			/>

			<Box alignItems='center' display='flex'>
				{error && <Text color='red'>{error}</Text>}
				<Box mt={6} w='fit-content' ml='auto'>
					<Button isLoading={isSubmitLoading} onClick={handleSubmit}>
						Generate Report
					</Button>
				</Box>
			</Box>
		</PageContainer>
	);
};

const reports = [
	{ value: 'DetailedRiskReport', label: 'Detailed Risk Report' },
	{ value: 'SingleRiskReport', label: 'Single Risk Report' },
	{ value: 'RiskMatrix', label: 'Risk Matrix' },
	{ value: 'RiskStatistics', label: 'Risk Statistics' },
];

export default Reports;
