let config = {
	cognitoAuthUrl: process.env.REACT_APP_COGNITO_AUTH_URL,
	userPoolId: process.env.REACT_APP_USER_POOL_ID,
	//cognitoClientId: "6rkcrfsme8thmog8l6gflkakl4",
	cognitoClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
	//cognitoClientId: "jgck2jk4fpm4jc78pl8vb9tf",
	redirect_uri: process.env.REACT_APP_REDIRECT_URI,
};

if(process.env.NODE_ENV !== 'development') {
	config.redirect_uri = `https://prod.hub4aviation.com/login`
}

// console.log(config.cognitoClientId);
export default config;
