import { useContext } from 'react';
import { RiEdit2Line, RiDeleteBinLine } from 'react-icons/ri';
import { useSelector } from 'react-redux';
import { formatDate } from '../../../api/helpers';
import UserContext from '../../../context/user/UserContext';
import { isRiskDisabled } from '../../../redux/slices/risks';
import TextButton from '../../common/Buttons/TextButton';
import { Box, IconButton, Td, Tooltip, Tr } from '@chakra-ui/react';

const ActionsTableRow = (props) => {
	const { users } = useSelector((state) => state.users);
	const { item, onEditClick, index, onReopenClick, onDeleteClick } = props;

	const currentRisk = useSelector((state) => state.risks.currentRisk);
	const { user } = useContext(UserContext);
	const isDisabled = isRiskDisabled(currentRisk, user);

	return (
		<Tr fontSize={14}>
			<Td>{item.actionSeq}</Td>
			<Td whiteSpace='initial'>
				<Box maxWidth={300}>{item.action || ''}</Box>
			</Td>
			<Td>
				<Tooltip label={item.owner ? item.owner.email : ''}>
					<Box>
						{item.owner
							? typeof item.owner === 'string'
								? users.find((user) => user._id === item.owner)?.name
								: item.owner?.name || ''
							: item.unregisteredOwnerName
							? `${item.unregisteredOwnerName} (Unregistered)`
							: ''}
					</Box>
				</Tooltip>
			</Td>
			<Td>{item.targetDate ? formatDate(item.targetDate) : ''}</Td>
			<Td whiteSpace='initial'>
				<Box maxWidth={350}>{item.description || ''}</Box>
			</Td>
			<Td>{item.status === 'completed' ? 'Completed' : 'In Progress'}</Td>
			<Td>
				{!isDisabled && (
					<Box display='flex' alignItems='center' justifyContent='center' gap={2}>
						{item.status === 'completed' ? (
							<TextButton onClick={() => onReopenClick(item)}>Re-open</TextButton>
						) : (
							<>
								<IconButton variant='ghost' onClick={() => onEditClick(index)}>
									<RiEdit2Line />
								</IconButton>

								<IconButton variant='ghost' onClick={() => onDeleteClick(index)}>
									<RiDeleteBinLine />
								</IconButton>
							</>
						)}
					</Box>
				)}
			</Td>
		</Tr>
	);
};

export default ActionsTableRow;
