import { Box, Text } from '@chakra-ui/react';
import { capitalize } from 'lodash';
import { useMemo } from 'react';
import { RATING_COLORS } from '../../constants';

const RisksRatingBox = ({ type, value }) => {
	const bgColor = useMemo(() => {
		return RATING_COLORS[type];
	}, []);
	return (
		<Box
			bgColor={bgColor}
			width='33.3%'
			height='150px'
			display='flex'
			alignItems='center'
			justifyContent='center'
			flexDirection='column'>
			<Text fontWeight={600} fontSize='5xl'>
				{value}
			</Text>

			<Text mb={-4}>{capitalize(type)} Risks</Text>
		</Box>
	);
};

export default RisksRatingBox;
