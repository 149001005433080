import React, { useReducer, useMemo } from 'react';
import UserContext, { axisoInstance, refreshTokens } from './UserContext';
import UserReducer from './UserReducer';
import { isExpired, decodeToken } from 'react-jwt';
import { SET_USER_INFO } from '../types';

import config from '../../config';
import { CognitoUserAttribute, CognitoUserPool, CognitoUser } from 'amazon-cognito-identity-js';

const initialState = {
	user: null,
	settings: null,
};

const { cognitoAuthUrl, cognitoClientId, redirect_uri } = config;

const poolData = {
	ClientId: config.cognitoClientId,
	UserPoolId: config.userPoolId,
};

const userPool = new CognitoUserPool(poolData);

const UserState = (props) => {
	const [state, dispatch] = useReducer(UserReducer, initialState);

	const register = (name, email, password) => {
		return new Promise((resolve, reject) => {
			const emailAttribute = new CognitoUserAttribute({
				Name: 'email',
				Value: email.toLowerCase(),
			});

			const nameAttribute = new CognitoUserAttribute({
				Name: 'name',
				Value: name.toLowerCase(),
			});

			userPool.signUp(email, password, [emailAttribute, nameAttribute], [], async (err, result) => {
				if (err) {
					console.log(err);
					reject(err);
				} else if (result) {
					resolve(result);
				}
			});
		});
	};

	const resendConfirmation = (email) => {
		const userData = {
			Username: email,
			Pool: userPool,
		};

		const cognitoUser = new CognitoUser(userData);
		cognitoUser.resendConfirmationCode(function (err, result) {
			if (err) {
				alert(err.message || JSON.stringify(err));
				return;
			}
		});
	};

	const getCredentialsFromCode = async (code) => {
		let urlParames = new URLSearchParams({
			grant_type: 'authorization_code',
			redirect_uri,
			client_id: cognitoClientId,
			scope: 'email openid profile',
			code,
		});
		try {
			const result = await axisoInstance.post(
				cognitoAuthUrl + '/oauth2/token',
				urlParames.toString(),
				{
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded',
					},
				}
			);
			const { id_token, access_token, refresh_token } = result.data;

			localStorage.setItem('id_token', id_token);
			localStorage.setItem('access_token', access_token);
			localStorage.setItem('refresh_token', refresh_token);

			getUserInfo();
			//navigate("/");
		} catch (e) {
			console.log(e.response);
		}

		return true;
	};

	const getUserInfo = async () => {
		const idToken = localStorage.getItem('id_token');
		const accessToken = localStorage.getItem('access_token');

		try {
			if (isExpired(accessToken) || !idToken || !accessToken) {
				await refreshTokens(true);
				getUserInfo();
			} else {
				const decodedToken = decodeToken(idToken);

				dispatch({
					type: SET_USER_INFO,
					payload: decodedToken,
				});
			}
		} catch (e) {
			console.log(e);
		}
	};

	const signOut = () => {
		localStorage.removeItem('id_token');
		localStorage.removeItem('access_token');
		localStorage.removeItem('refresh_token');

		dispatch({
			type: SET_USER_INFO,
			payload: null,
		});

		return true;
	};

	const stateValue = useMemo(() => {
		return {
			...state,
			register,
			getUserInfo,
			refreshTokens,
			resendConfirmation,
			getCredentialsFromCode,
			signOut,
		};
	}, [state]);

	return <UserContext.Provider value={stateValue}>{props.children}</UserContext.Provider>;
};

export default UserState;
