import { useContext, useState } from 'react';
import { useEffect } from 'react';
import SelectUserInput from '../../common/Inputs/SelectUserInput';
import { useSelector } from 'react-redux';
import ErrorText from '../../common/ErrorText';
import { v4 } from 'uuid';
import { isRiskDisabled } from '../../../redux/slices/risks';
import UserContext from '../../../context/user/UserContext';
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	Button,
	Textarea,
	FormControl,
	FormLabel,
	Box,
	Icon,
} from '@chakra-ui/react';
import SelectInput from '../../common/Inputs/SelectInput';
import { AiOutlineSave } from 'react-icons/ai';

const inputNames = ['owner', 'cause', 'mitigation', 'rating', 'controlDetail'];

export const ratingOptions = [
	{ label: 'RED', value: 'red' },
	{ label: 'AMBER', value: 'amber' },
	{ label: 'GREEN', value: 'green' },
];

export const mitigationOptions = [
	{ label: 'Reduce Likelihood', value: 'reduceLikelihood' },
	{ label: 'Reduce Consequence', value: 'reduceConsequence' },
];

const ControlsModal = ({
	selectedTableIndex,
	setSelectedTableIndex,
	tableItems,
	setTableItems,
	isOpen,
	onClose,
}) => {
	const [inputValues, setInputValues] = useState({});
	const [inputOptions, setInputOptions] = useState({});
	const [errors, setErrors] = useState({});
	const riskFormValues = useSelector((state) => state.riskForm.inputValues);

	const currentRisk = useSelector((state) => state.risks.currentRisk);
	const { user } = useContext(UserContext);
	const isDisabled = isRiskDisabled(currentRisk, user);

	const handleInputChange = (name, value) => {
		setInputValues({ ...inputValues, [name]: value });
	};

	const handleSubmit = () => {
		let errors = {};

		inputNames.forEach((name) => {
			if (typeof inputValues[name] === 'undefined') {
				errors[name] = 'Required';
			}
		});

		setErrors({ ...errors });

		if (Object.keys(errors).length) {
			return;
		}

		let updatedItems = [...tableItems];

		if (typeof selectedTableIndex === 'number') {
			updatedItems[selectedTableIndex] = inputValues;

			setTableItems([...updatedItems]);
		} else {
			inputValues.id = v4();
			inputValues.number = tableItems.length + 1;
			updatedItems = [...tableItems, inputValues];
			setTableItems(updatedItems);
		}

		setSelectedTableIndex(null);
		setInputValues({});
		onClose();
	};

	useEffect(() => {
		if (isOpen) {
			setErrors({});

			if (typeof selectedTableIndex === 'number') {
				setInputValues(tableItems[selectedTableIndex]);
			} else {
				setInputValues({});
			}
		}
	}, [selectedTableIndex, isOpen]);

	useEffect(() => {
		setInputOptions({
			...inputOptions,
			rating: ratingOptions,
			mitigation: mitigationOptions,
			cause: [
				...(riskFormValues?.causes?.map((cause) => ({ label: cause.cause, value: cause.id })) ||
					[]),
			],
		});
	}, [riskFormValues]);

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent maxW={800}>
				<ModalHeader>{typeof selectedTableIndex === 'number' ? 'Edit' : 'Add'} Control</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Box display='flex' gap={12}>
						<FormControl width='40%'>
							<FormLabel>Cause</FormLabel>

							<SelectInput
								isDisabled={isDisabled}
								placeholder='Select Cause'
								value={inputValues.cause}
								options={inputOptions['cause']}
								defaultChecked={inputValues.cause}
								onChange={(value) => handleInputChange('cause', value)}
							/>

							{errors.cause && <ErrorText>{errors.cause}</ErrorText>}
						</FormControl>
						<FormControl width='55%'>
							<FormLabel>Control Owner</FormLabel>

							<SelectUserInput
								isDisabled={isDisabled}
								value={inputValues.owner}
								onChange={(value) => handleInputChange('owner', value)}
							/>

							{errors.owner && <ErrorText>{errors.owner}</ErrorText>}
						</FormControl>
					</Box>
					<Box mt={4} display='flex' gap={12}>
						<FormControl width='40%'>
							<FormLabel>Rating</FormLabel>
							<SelectInput
								isDisabled={isDisabled}
								placeholder='Select Rating'
								value={inputValues.rating}
								options={inputOptions['rating']}
								defaultChecked={inputValues.rating}
								onChange={(value) => handleInputChange('rating', value)}
							/>
							{errors.rating && <ErrorText>{errors.rating}</ErrorText>}
						</FormControl>
						<FormControl width='55%'>
							<FormLabel>Mitigation</FormLabel>
							<SelectInput
								isDisabled={isDisabled}
								placeholder='Select Mitigation'
								value={inputValues.mitigation}
								options={inputOptions['mitigation']}
								defaultChecked={inputValues.mitigation}
								onChange={(value) => handleInputChange('mitigation', value)}
							/>
							{errors.mitigation && <ErrorText>{errors.mitigation}</ErrorText>}
						</FormControl>
					</Box>

					<FormControl mt={4}>
						<FormLabel>Control Detail</FormLabel>
						<div className='flex'>
							<Textarea
								style={{ resize: 'none' }}
								isDisabled={isDisabled}
								rows={10}
								onChange={(e) => handleInputChange('controlDetail', e.target.value)}
								value={inputValues.controlDetail || ''}
							/>
						</div>
						{errors.controlDetail && <ErrorText>{errors.controlDetail}</ErrorText>}
					</FormControl>
				</ModalBody>

				<ModalFooter>
					<Button variant='ghost' mr={3} onClick={onClose}>
						Close
					</Button>
					<Button
						isDisabled={isDisabled}
						onClick={handleSubmit}
						leftIcon={<Icon as={AiOutlineSave} />}
						variant='solid'>
						Save
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default ControlsModal;
