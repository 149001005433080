import { configureStore } from '@reduxjs/toolkit';
import { riskFormSlice } from './slices/riskForm';
import { risksSlice } from './slices/risks';
import { usersSlice } from './slices/users';
import { actionsSlice } from './slices/actions';
import { reviewsSlice } from './slices/reviews';
import { appSettingsSlice } from './slices/app-settings';

export const store = configureStore({
	reducer: {
		appSettings: appSettingsSlice.reducer,
		riskForm: riskFormSlice.reducer,
		risks: risksSlice.reducer,
		users: usersSlice.reducer,
		actions: actionsSlice.reducer,
		reviews: reviewsSlice.reducer,
	},
});
