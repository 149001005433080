import { Box, Icon, Text } from '@chakra-ui/react';
import {
	BUSINESS_LEADER_PREFIX,
	DEPARTMENT_LEADER_PREFIX,
	getUserGroups,
	isUserRiskManager,
} from '../../context/user/utils';

import { useContext, useMemo } from 'react';
import UserContext from '../../context/user/UserContext';
import { BsDatabase, BsDatabaseExclamation } from 'react-icons/bs';
import { AiOutlineWarning, AiOutlinePlus, AiOutlineHome } from 'react-icons/ai';
import { BiTachometer, BiCommentDetail, BiPhoneCall } from 'react-icons/bi';
import { HiOutlineDocumentReport } from 'react-icons/hi';
import { NavLink, useLocation } from 'react-router-dom';
import { RiAdminLine } from 'react-icons/ri';

const BottomNavigationBar = () => {
	const { user } = useContext(UserContext);
	const location = useLocation();

	const isRiskManager = useMemo(() => user && isUserRiskManager(user), [user]);

	const { hasAccessToReviews } = useMemo(() => {
		const groups = user ? getUserGroups(user) : [];
		const isRiskManagerOrCorporateLeader =
			groups.includes('RiskManager') || groups.includes('CorporateLeader');

		return {
			// isRiskManagerOrCorporateLeader,
			/** Only users with RisksManager, CorporateLeader or any business/department leader have access to the reviews page */
			hasAccessToReviews:
				isRiskManagerOrCorporateLeader ||
				groups.find(
					(group) =>
						group.startsWith(DEPARTMENT_LEADER_PREFIX) || group.startsWith(BUSINESS_LEADER_PREFIX)
				),
		};
	}, [user]);

	const routes = useMemo(
		() => [
			{
				path: '/',
				name: 'Home',
				Icon: AiOutlineHome,
			},
			{
				path: '/risk',
				name: 'Add Risk',
				disallowHighlightWithSearch: true,
				// disabled: !isRiskManagerOrCorporateLeader,
				Icon: AiOutlinePlus,
			},
			{
				path: '/risks',
				name: 'Edit Risks',
				highlightExemptionPaths: ['?tab=myRisks'],
				Icon: BsDatabase,
			},
			{
				path: '/risks?tab=myRisks',
				name: 'My Risks',
				matchWithParams: true,
				Icon: BsDatabaseExclamation,
			},
			{
				path: '/risk-appetite',
				name: 'Risk Appetite',
				Icon: BiTachometer,
			},
			{
				path: '/reviews',
				name: 'Reviews',
				hidden: !hasAccessToReviews,
				Icon: BiCommentDetail,
			},
			{
				path: '/actions',
				name: 'My Actions',
				Icon: AiOutlineWarning,
			},
			{
				path: '/reporting',
				name: 'Reporting',
				Icon: HiOutlineDocumentReport,
			},
			{
				highlightPath: '/admin',
				path: '/admin/settings',
				name: 'Admin',
				hidden: !isRiskManager,
				Icon: RiAdminLine,
			},
			{
				path: '',
				name: 'Contact Us',
				Icon: BiPhoneCall,
				exact: true,
			},
		],
		[isRiskManager]
	);

	return (
		<Box
			bgColor='primary'
			width='100vw'
			height={BOTTOM_NAVIGATION_BAR_HEIGHT + 'px'}
			position='fixed'
			overflowX='auto'
			bottom={0}>
			<Box
				display='flex'
				alignItems='center'
				justifyContent='center'
				height='100%'
				width='fit-content'
				mx='auto'
				gap={8}>
				{routes.map((route, index) => {
					if (route.hidden) {
						return null;
					}
					let isSelected =
						(location.pathname === route.path ||
							location.pathname.startsWith(route.highlightPath)) &&
						!(route.disallowHighlightWithSearch && location.search) && !route.highlightExemptionPaths?.includes(location.search);

					if (route.matchWithParams) {
						isSelected = location.pathname + location.search === route.path;
					}

					return (
						<NavLink
							to={route.path}
							style={{
								width: 95,
								opacity: route.disabled ? 0.5 : 1,
								pointerEvents: route.disabled ? 'none' : 'all',
								cursor: !route.disabled ? 'pointer' : '',
							}}>
							<Box
								cursor='pointer'
								color='white'
								display='flex'
								flexDirection='column'
								alignItems='center'
								justifyContent='center'
								position='relative'
								gap={1.5}
								key={index}>
								<Icon fontSize={22} as={route.Icon} />
								<Text
									fontWeight={isSelected ? 'bold' : ''}
									// mt={1.5}
									textTransform='uppercase'
									fontSize={12}>
									{route.name}
								</Text>

								{isSelected && (
									<div
										style={{
											width: '100%',
											height: 2,
											backgroundColor: 'white',
											position: 'absolute',
											bottom: -10,
										}}></div>
								)}
							</Box>
						</NavLink>
					);
				})}
			</Box>
		</Box>
	);
};

export const BOTTOM_NAVIGATION_BAR_HEIGHT = 70;

export default BottomNavigationBar;
