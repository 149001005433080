import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getHeaders } from '../../api/helpers';
import axios from 'axios';
import { camelCase, startCase } from 'lodash';

const initialState = {
	settings: null,
	isLoading: false,
};

export const getAppSettings = createAsyncThunk('app-settings/get', async () => {
	try {
		const result = await axios.get(`${process.env.REACT_APP_BASE_URL}/app-settings`, {
			headers: getHeaders(),
		});

		console.log(result.data);
		return result.data;
	} catch (e) {
		throw new Error(JSON.stringify(e.response));
	}
});

export const createBusiness = createAsyncThunk('app-settings/create-business', async (name) => {
	const result = await axios.post(
		`${process.env.REACT_APP_BASE_URL}/app-settings/businesses`,
		{ name: camelCase(name), label: startCase(name) },
		{
			headers: getHeaders(),
		}
	);

	return result.data;
});

export const deleteBusiness = createAsyncThunk('app-settings/delete-business', async (name) => {
	await axios.delete(`${process.env.REACT_APP_BASE_URL}/app-settings/businesses/${name}`, {
		headers: getHeaders(),
	});

	return name;
});

export const createDepartment = createAsyncThunk(
	'apps-settings/create-department',
	async ({ name, businessName }) => {
		const result = await axios.post(
			`${process.env.REACT_APP_BASE_URL}/app-settings/businesses/${businessName}/departments`,
			{ name: camelCase(name), label: name },
			{
				headers: getHeaders(),
			}
		);

		return result.data;
	}
);

export const deleteDepartment = createAsyncThunk(
	'apps-settings/delete-department',
	async ({ businessName, departmentName }) => {
		const result = await axios.delete(
			`${process.env.REACT_APP_BASE_URL}/app-settings/businesses/${businessName}/departments/${departmentName}`,
			{
				headers: getHeaders(),
			}
		);

		return result.data;
	}
);

export const createSubDepartment = createAsyncThunk(
	'apps-settings/create-sub-department',
	async ({ name, businessName, departmentName }) => {
		const result = await axios.post(
			`${process.env.REACT_APP_BASE_URL}/app-settings/businesses/${businessName}/departments/${departmentName}/sub-departments`,
			{ name },
			{
				headers: getHeaders(),
			}
		);

		return result.data;
	}
);

export const deleteSubDepartment = createAsyncThunk(
	'apps-settings/delete-sub-department',
	async ({ subDepartmentName, businessName, departmentName }) => {
		const result = await axios.delete(
			`${process.env.REACT_APP_BASE_URL}/app-settings/businesses/${businessName}/departments/${departmentName}/sub-departments/${subDepartmentName}`,
			{
				headers: getHeaders(),
			}
		);

		return result.data;
	}
);

export const updateRiskAppetite = createAsyncThunk(
	'apps-settings/update-risk-appetite',
	async (inputValues) => {
		const result = await axios.put(
			`${process.env.REACT_APP_BASE_URL}/app-settings/risk-appetite`,
			inputValues,
			{
				headers: getHeaders(),
			}
		);

		return result.data;
	}
);

export const appSettingsSlice = createSlice({
	name: 'app-settings',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getAppSettings.pending, (state) => {
			state.isLoading = true;
		});

		builder.addCase(getAppSettings.fulfilled, (state, action) => {
			state.settings = { ...state.settings, ...action.payload };
			state.isLoading = false;
		});

		builder.addCase(createBusiness.fulfilled, (state, action) => {
			state.settings = { ...state.settings, ...action.payload };
		});

		builder.addCase(createDepartment.fulfilled, (state, action) => {
			state.settings = { ...state.settings, ...action.payload };
		});

		builder.addCase(deleteDepartment.fulfilled, (state, action) => {
			state.settings = { ...state.settings, ...action.payload };
		});

		builder.addCase(createSubDepartment.fulfilled, (state, action) => {
			state.settings = { ...state.settings, ...action.payload };
		});

		builder.addCase(deleteSubDepartment.fulfilled, (state, action) => {
			state.settings = { ...state.settings, ...action.payload };
		});

		builder.addCase(deleteBusiness.fulfilled, (state, action) => {
			state.settings.businesses = state.settings.businesses.filter(
				(business) => business.name !== action.payload
			);
		});
	},
});
