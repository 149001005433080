import PageContainer from '../../Components/Layout/PageContainer';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRisks } from '../../redux/slices/risks';
import LoadingSpinner from '../../Components/common/LoadingSpinner';
import { Box, Button, Heading, Text } from '@chakra-ui/react';
import RisksRatingBox from '../../Components/Dashboard/RisksRatingBox';
import RisksByDepartmentChart from '../../Components/Dashboard/RisksByDepartmentChart/RisksByDepartmentChart';
import RisksByThemeChart from '../../Components/Dashboard/RisksByThemeChart/RisksByThemeChart';
import CorporateRisksTable from '../../Components/Dashboard/CorporateRisksTable';
import RisksTable from '../../Components/Risks/Table/RisksTable';
import { RISK_TABLE_FIELDS } from '../../constants';
import ControlsChart from '../../Components/Dashboard/ControlsChart';
import { getChartsDataFromRisks } from './utils';
import { getDepartmentByName } from '../../redux/utils';
import { MdClose } from 'react-icons/md';

const Dashboard = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [sort, setSort] = useState({ _id: -1 });
	const [filteredRisks, setFilteredRisks] = useState(null);
	const [selectedRisk, setSelectedRisk] = useState(null);
	const [selectedFilter, setSelectedFilter] = useState();
	const dispatch = useDispatch();
	const risks = useSelector((state) => state.risks.risks);

	const data = useMemo(() => getChartsDataFromRisks(risks), [risks]);

	const filteredRisksData = useMemo(
		() => getChartsDataFromRisks(selectedRisk ? [selectedRisk] : filteredRisks),
		[filteredRisks, selectedRisk]
	);

	const risksTableLabel = useMemo(() => {
		if (selectedFilter?.type === 'theme') {
			return `${selectedFilter.value} Theme Risks`;
		} else if (selectedFilter?.type === 'department') {
			return `${getDepartmentByName(selectedFilter.value)?.label} Department Risks`;
		} else {
			return 'All Risks';
		}
	}, [selectedFilter]);

	const loadRisks = async () => {
		setIsLoading(true);
		await dispatch(fetchRisks({ sort }));
		setIsLoading(false);
	};

	const handleDepartmentClick = (department) => {
		setSelectedRisk(null);
		if (department) {
			setFilteredRisks(risks.filter((risk) => risk.department === department));
			setSelectedFilter({
				type: 'department',
				value: department,
			});
		} else {
			setSelectedFilter(null);
			setFilteredRisks(null);
		}
	};

	const handleThemeClick = (theme) => {
		setSelectedRisk(null);

		if (theme) {
			setFilteredRisks(risks.filter((risk) => risk.theme === theme));
			setSelectedFilter({
				type: 'theme',
				value: theme,
			});
		} else {
			setSelectedFilter(null);
			setFilteredRisks(null);
		}
	};

	const handleRiskClick = (risk) => {
		const isRiskSelected = selectedRisk?._id === risk._id;

		if (isRiskSelected) {
			setSelectedRisk(null);
		} else {
			setSelectedRisk(risk);
		}
	};

	const handleClearFilterClick = () => {
		setSelectedFilter(null);
		setFilteredRisks(null);
	};

	useEffect(() => {
		loadRisks();
	}, [sort]);

	// TODO: Show selected filter on top of the table, and add a clear filter button at the top of the page
	return (
		<PageContainer>
			{isLoading ? (
				<LoadingSpinner />
			) : (
				<Box>
					<Heading>Dashboard</Heading>

					<Box display='flex' gap={6}>
						<Box width='65%'>
							<Box width='100%' display='flex' gap={4}>
								<RisksRatingBox type='green' value={data.ratings.green} />
								<RisksRatingBox type='amber' value={data.ratings.amber} />
								<RisksRatingBox type='red' value={data.ratings.red} />
							</Box>

							<Box mt={8} display='flex' gap={4}>
								<Box width='50%'>
									<Text mb={3} fontSize='xl' fontWeight='600'>
										Risks by Department
									</Text>

									<RisksByDepartmentChart
										data={data.risksByDepartment}
										highlightedData={filteredRisksData.risksByDepartment}
										onDepartmentClick={handleDepartmentClick}
										selectedFilter={
											selectedRisk ? { type: 'risk', value: selectedRisk } : selectedFilter
										}
									/>
								</Box>

								<Box width='50%'>
									<Text mb={3} fontSize='xl' fontWeight='600'>
										Risks by Theme
									</Text>

									<RisksByThemeChart
										data={data.risksByTheme}
										risksCount={risks.length}
										selectedFilter={
											selectedRisk ? { type: 'risk', value: selectedRisk } : selectedFilter
										}
										highlightedData={filteredRisksData.risksByTheme}
										onThemeClick={handleThemeClick}
									/>
								</Box>
							</Box>
						</Box>
						<Box width='35%'>
							<Text mb={3} fontSize='xl' fontWeight='600'>
								Corporate Risks
							</Text>
							<CorporateRisksTable
								risks={data.corporateRisks}
								onRiskClick={handleRiskClick}
								selectedRisk={selectedRisk}
							/>

							<Text mt={3} mb={3} fontSize='xl' fontWeight='600'>
								Controls
							</Text>

							<ControlsChart data={data.controls} controlsCount={data.controlsCount} />
						</Box>
					</Box>

					<Box mt={8} mb={3} display='flex' alignItems='center'>
						<Text fontSize='xl' fontWeight='600'>
							{risksTableLabel}
						</Text>

						{selectedFilter && (
							<Box width='fit-content' ml='auto'>
								<Button leftIcon={<MdClose />} size='sm' onClick={handleClearFilterClick}>
									Clear Filter
								</Button>
							</Box>
						)}
					</Box>

					<RisksTable
						onRiskClick={handleRiskClick}
						selectedRisk={selectedRisk}
						size='sm'
						risks={filteredRisks || risks}
						sort={sort}
						setSort={setSort}
						fieldProps={{
							[RISK_TABLE_FIELDS.Title]: {
								width: '300px',
							},
						}}
						fields={[
							RISK_TABLE_FIELDS.RiskID,
							RISK_TABLE_FIELDS.Title,
							RISK_TABLE_FIELDS.Department,
							RISK_TABLE_FIELDS.Owner,
							RISK_TABLE_FIELDS.InherentRisk,
							RISK_TABLE_FIELDS.ResidualRisk,
							RISK_TABLE_FIELDS.AppetiteRisk,
							RISK_TABLE_FIELDS.Description,
							RISK_TABLE_FIELDS.Actions,
						]}
					/>
				</Box>
			)}
		</PageContainer>
	);
};

export default Dashboard;
