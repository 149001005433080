import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { getHeaders } from '../../api/helpers';

const initialState = {
	users: [],
	fetchUsersLoading: false,
};

export const fetchUsers = createAsyncThunk('users/fetchUsers', async (payload) => {
	const { searchValue, groups } = payload || {};
	const result = await axios.get(`${process.env.REACT_APP_BASE_URL}/users`, {
		headers: getHeaders(),
		params: { searchValue, groups },
	});

	return result.data;
});

export const usersSlice = createSlice({
	name: 'users',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchUsers.pending, (state) => {
			state.fetchUsersLoading = true;
		});
		builder.addCase(fetchUsers.fulfilled, (state, action) => {
			state.users = action.payload;
			state.fetchUsersLoading = false;
		});

		builder.addCase(fetchUsers.rejected, (state, action) => {
			console.log('REJECTED', action);
		});
	},
});
