import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { useQuery } from '../../App';
import { getRiskFormPageRoute } from '../../pages/Risk/utils';
import { BiArrowBack, BiErrorCircle } from 'react-icons/bi';
import LoadingSpinner from '../common/LoadingSpinner';
import { getRiskById, isRiskDisabled, setCurrentRisk } from '../../redux/slices/risks';
import {
	resetRiskFormState,
	setRiskFormState,
	submitRisk,
	validateRiskInputs,
} from '../../redux/slices/riskForm';
import { store } from '../../redux/store';
import UnsavedChangesPopup from '../common/Popups/UnsavedChangesPopup';
import { useBeforeunload } from 'react-beforeunload';
import UserContext from '../../context/user/UserContext';
import { Box, Divider, Heading, Icon, IconButton, Text, useToast } from '@chakra-ui/react';
import { unstable_useBlocker } from 'react-router-dom';
import RiskFormButtons from '../common/Buttons/RiskFormButtons';

const RiskFormHeader = () => {
	const [loading, setLoading] = useState(false);
	const { user } = useContext(UserContext);
	let location = useLocation();
	const query = useQuery();

	const dispatch = useDispatch();
	const [unsavedChangesPopup, setUnsavedChangesPopup] = useState({ action: null, open: false });
	const { inputValues, errors, hasUnsavedChanges } = useSelector((state) => state.riskForm);
	const toast = useToast();
	const currentRisk = useSelector((state) => state.risks.currentRisk);
	const isDisabled = isRiskDisabled(currentRisk, user);
	const [prevPath, setPrevPath] = useState();
	const { settings } = useSelector((state) => state.appSettings);

	// const isRiskManagerOrCorporateLeader = useMemo(() => {
	// 	const groups = user ? getUserGroups(user) : [];

	// 	return groups.includes('RiskManager') || groups.includes('CorporateLeader');
	// }, [user]);

	let navigate = useNavigate();

	useBeforeunload((event) => {
		if (hasUnsavedChanges && !isDisabled) {
			event.preventDefault();
		}
	});

	const navigateToRiskRoute = (route, location) => {
		navigate(getRiskFormPageRoute(route, query), { state: location.state });
	};

	const handleSubmit = async () => {
		dispatch(validateRiskInputs());

		if (Object.keys(store.getState().riskForm.errors).length) {
			toast({
				title: 'Please resolve the errors before submitting',
				status: 'error',
			});
			setUnsavedChangesPopup({ open: false, action: null });
			return;
		}

		setLoading(true);
		await dispatch(submitRisk(query.get('riskId')));
		setLoading(false);
		dispatch(resetRiskFormState());
		navigate('/risks');
	};

	unstable_useBlocker((action) => {
		if (
			!hasUnsavedChanges ||
			unsavedChangesPopup.open ||
			((action.nextLocation.pathname === '/risk' ||
				action.nextLocation.pathname.startsWith('/risk/')) &&
				action.nextLocation.search === action.currentLocation.search)
		) {
			return false;
		} else {
			setUnsavedChangesPopup({ action, open: true });
			return true;
		}
	}, hasUnsavedChanges && !unsavedChangesPopup.open && !isDisabled);

	useEffect(() => {
		if (settings) {
			dispatch(
				setRiskFormState({
					...inputValues,
					theme: inputValues.theme || 'General',
					appetite: {
						...(settings.defaultRiskAppetite || {}),
						...(inputValues.appetite || {}),
					},
				})
			);
		}
	}, [settings]);

	useEffect(() => {
		const riskId = query.get('riskId');

		setPrevPath(location.state?.prevPath);

		// if (!riskId && !isRiskManagerOrCorporateLeader) {
		// 	navigate('/');
		// }

		if (!riskId && inputValues._id) {
			setLoading(false);
			dispatch(resetRiskFormState());
			dispatch(setCurrentRisk(null));
			return;
		}

		if (riskId && riskId !== inputValues?._id) {
			(async () => {
				if (riskId) {
					dispatch(resetRiskFormState());
					setLoading(true);
					const result = await dispatch(getRiskById(riskId));
					setLoading(false);

					if (result.payload) {
						dispatch(setRiskFormState(result.payload));
					} else {
						toast({ title: 'Risk not found', status: 'error' });
					}
				} else {
					setLoading(false);
					dispatch(resetRiskFormState());
				}
			})();
		} else {
			setLoading(false);
		}
	}, [location]);

	return (
		<>
			<UnsavedChangesPopup
				open={unsavedChangesPopup.open}
				onClose={() => setUnsavedChangesPopup({ open: false, action: null })}
				onDiscard={() => {
					dispatch(resetRiskFormState());
					navigate(
						unsavedChangesPopup.action.nextLocation.pathname +
							unsavedChangesPopup.action.nextLocation.search
					);
				}}
				onSubmit={handleSubmit}
			/>

			<Box mb={2} gap={2} display='flex' alignItems='center'>
				{currentRisk && (
					<IconButton
						color='primary'
						onClick={() => navigate(prevPath || '/risks')}
						variant='ghost'>
						<Icon as={BiArrowBack} fontSize='2xl' />
					</IconButton>
				)}
				<Heading>{currentRisk ? 'Assess/Edit' : 'Add'} Risk</Heading>

				<RiskFormButtons />
			</Box>

			<Box pt={2} pb={4} display='flex' alignItems='center' gap={12}>
				{riskFormTabs.map((tab, index) => {
					const hasError = tab.properties?.find((property) => errors[property]);
					const isSelected = location.pathname === tab.path;

					return (
						<Box
							textAlign='center'
							onClick={() => navigateToRiskRoute(tab.path, location)}
							cursor='pointer'
							height={25}
							display='flex'
							alignItems='center'
							key={index}
							position='relative'>
							<Text color='primary' fontWeight={700} fontSize={14} textTransform='uppercase'>
								{tab.name}
							</Text>

							{hasError && (
								<Icon
									as={BiErrorCircle}
									sx={{
										color: 'white',
										background: 'red',
										borderRadius: '50%',
										marginLeft: 2,
									}}
								/>
							)}

							{isSelected && (
								<Box
									position='absolute'
									bottom={0}
									bgColor='primary'
									width='100%'
									height='2px'></Box>
							)}
						</Box>
					);
				})}
			</Box>

			<Divider mt={2} mb={6} />
			{loading && (
				<div style={{ marginTop: 16 }}>
					<LoadingSpinner />
				</div>
			)}
		</>
	);
};

export const riskFormTabs = [
	{
		path: '/risk',
		name: 'Risk',
		properties: [
			'business',
			'department',
			'title',
			'subDepartment',
			'owner',
			'theme',
			'status',
			'description',
			'linkedCorporateRisk',
			'details',
		],
	},
	{ path: '/risk/causes', name: 'Causes' },
	{ path: '/risk/consequences', name: 'Consequences' },
	{ path: '/risk/inherent-risk', name: 'Inherent Risk' },
	{ path: '/risk/controls', name: 'Controls' },
	{ path: '/risk/residual-risk', name: 'Residual Risk' },
	{ path: '/risk/appetite', name: 'Appetite' },
	{ path: '/risk/response', name: 'Response', properties: ['response'] },
	{ path: '/risk/actions', name: 'Actions' },
	{ path: '/risk/comments', name: 'Comments' },
];

export default RiskFormHeader;
