import { Box, Text } from '@chakra-ui/react';

const CustomLegend = ({ data, style }) => {
	return (
		<Box overflowY='auto' style={style}>
			{data.map((entry, index) => (
				<Box mb={2} gap={2} display='flex' alignItems='center' key={index}>
					<Box borderRadius='50%' width='15px' height='15px' bgColor={entry.color}></Box>
					<Text width='80px' fontSize={12}>
						{entry.label}
					</Text>
				</Box>
			))}
			

		</Box>
	);
};

export default CustomLegend;
