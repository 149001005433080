import { set, uniqueId } from "lodash";

export const readFile = (file) => {
    return new Promise((resolve) => {
        const rawFile = new XMLHttpRequest();
        rawFile.open('GET', file, false);
        rawFile.onreadystatechange = function () {
            if (rawFile.readyState === 4) {
                if (rawFile.status === 200 || rawFile.status === 0) {
                    const allText = rawFile.responseText;
                    resolve(allText);
                }
            }
        };
        rawFile.send(null);
    });
};

/**

 * TODO: Discuss mapping of impact (consequence) value
 * TODO: For now store them all as "Finance" (first field in the slides page)
 */
export const parseJsonToRisks = (json) => {
    let risks = [];
    console.log(json);

    json.forEach((row) => {
        let risk = {};

        Object.keys(row).forEach((key) => {
            let value = row[key];
            const mapping = fieldMappings[key];

            if (!mapping || !value) {
                return;
            }

            if (mapping.type === 'number') {
                value = Number(value);
            }

            set(risk, mapping.path, value);
        });

        if (Object.keys(risk).length) {
            /** Used to temporary identify the imported risk until it's validated and saved to the database */
            risk.temporaryId = uniqueId();
            risks.push(risk);
        }
    });

    return risks;
};

export const fieldMappings = {
    'Business*': {
        path: 'business',
        type: 'string',
    },
    'Department*': {
        path: 'department',
        type: 'string',
    },
    'Sub-Department': {
        path: 'subDepartment',
        type: 'string',
    },
    'Risk Title': {
        path: 'title',
        type: 'string',
    },
    'Risk Description': {
        path: 'description',
        type: 'string',
    },
    'Action Owner': {
        path: 'actions[0].owner',
        type: 'string',
    },
    Actions: {
        path: 'actions[0].action',
        type: 'string',
    },

    'Residual Likelihood': {
        path: 'residualRisk.likelihood',
        type: 'number',
    },
    'Residual Impact': {
        path: 'residualRisk.finance',
        type: 'number',
    },
    'Inherent Likelihood': {
        path: 'inherentRisk.likelihood',
        type: 'number',
    },
    'Inherent Impact': {
        path: 'inherentRisk.finance',
        type: 'number',
    },
    Causes: {
        path: 'causes[0].cause',
        type: 'string',
    },
    'Risk Owner': {
        path: 'owner',
        type: 'string',
    },
    'Comments': {
        path: 'comments[0].comment',
        type: 'string'
    }
};
