import { useContext, useEffect, useState } from 'react';

import UserContext from '../../context/user/UserContext';
import SelectUserInput from '../common/Inputs/SelectUserInput';
import { useSelector } from 'react-redux';
import {
	getBusinessInputOptions,
	getDepartmentInputOptions,
	getStatusInputOptions,
	getThemeInputOptions,
} from '../../redux/utils';
import SelectInput from '../common/Inputs/SelectInput';
import { Input, Button, Box } from '@chakra-ui/react';

const MyRisksSearchInputs = ({
	inputValues,
	selectedTab,
	onInputChange,
	onSearchClick,
	onClearClick,
}) => {
	const { settings } = useSelector((state) => state.appSettings);

	const { user } = useContext(UserContext);

	const [inputOptions, setInputOptions] = useState({});

	useEffect(() => {
		if (!user) {
			return;
		}
		setInputOptions({
			business: getBusinessInputOptions(user),
			department: getDepartmentInputOptions(user),
			theme: getThemeInputOptions(),
			status: getStatusInputOptions(),
		});
	}, [settings, user, inputValues]);

	return (
		<Box width='70%'>
			<Box gap={6} display='flex' alignItems='center'>
				<Box width='50%'>
					<SelectInput
						value={inputValues.status || ''}
						placeholder='Select Status'
						options={inputOptions['status']}
						onChange={(value) => onInputChange('status', value)}
					/>
				</Box>
				<Box width='50%'>
					<SelectInput
						value={inputValues.department || ''}
						placeholder='Select Department'
						options={inputOptions['department']}
						onChange={(value) => onInputChange('department', value)}
					/>
				</Box>
			</Box>
			<Box mt={2} display='flex' alignItems='center' gap={6}>
				<Box width='50%'>
					<Input
						variant='full-screen-form'
						type='text'
						placeholder='Enter Title'
						maxLength={70}
						onKeyDown={(e) => e.key === 'Enter' && onSearchClick()}
						value={inputValues.title || ''}
						onChange={(e) => onInputChange('title', e.target.value)}
					/>
				</Box>
				<Box width='50%'>
					<SelectUserInput
						visibility={selectedTab === 'allRisks' ? 'visible' : 'hidden'}
						value={inputValues.owner}
						onChange={(value) => {
							onInputChange('owner', value._id);
						}}
					/>
				</Box>
			</Box>

			<Box mt={6} display='flex' gap={4}>
				<Button onClick={onSearchClick}>Search</Button>
				<Button variant='ghost' onClick={onClearClick}>
					Clear
				</Button>
			</Box>
		</Box>
	);
};

export default MyRisksSearchInputs;
