import React, { useContext, useMemo, useState } from 'react';
import RiskFormHeader from '../../Components/RiskForm/RiskFormHeader';
import PageContainer from '../../Components/Layout/PageContainer';
import EntryFormModal from '../../Components/RiskForm/SingleInputTableForm/EntryFormModal';
import TableRow from '../../Components/RiskForm/SingleInputTableForm/TableRow';
import DeleteConfirmPopup from '../../Components/common/Popups/DeleteConfirmPopup';
import { updateRiskFormState } from '../../redux/slices/riskForm';
import { useDispatch, useSelector } from 'react-redux';
import {
	Box,
	Button,
	Icon,
	Table,
	TableContainer,
	Tbody,
	Text,
	Th,
	Thead,
	Tr,
} from '@chakra-ui/react';
import { BiPlus } from 'react-icons/bi';
import UserContext from '../../context/user/UserContext';
import { isRiskDisabled } from '../../redux/slices/risks';

const Comments = () => {
	const [selectedCommentIndex, setSelectedCommentIndex] = useState(null);
	const [isEntryModalOpen, setIsEntryModalOpen] = useState(false);
	const dispatch = useDispatch();
	const riskFormData = useSelector((state) => state.riskForm);
	const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
	const { user } = useContext(UserContext);
	const currentRisk = useSelector((state) => state.risks.currentRisk);
	const isDisabled = useMemo(() => isRiskDisabled(currentRisk, user), [currentRisk, user]);

	const updateComments = (comments) => {
		dispatch(updateRiskFormState({ comments }));
	};

	const handleEditClick = (index) => {
		setSelectedCommentIndex(index);
		setIsEntryModalOpen(true);
	};

	const handleDeleteClick = (index) => {
		setDeleteConfirmOpen(true);
		setSelectedCommentIndex(index);
	};

	const handleDeleteConfirm = () => {
		const updatedItems = riskFormData.inputValues.comments.filter(
			(i, index) => index !== selectedCommentIndex
		);

		updateComments(updatedItems);
		setSelectedCommentIndex(null);
		setDeleteConfirmOpen(false);
	};

	return (
		<PageContainer>
			<RiskFormHeader />
			<Box display='flex' alignItems='center'>
				<Text fontSize='2xl' fontWeight={500}>
					Comments
				</Text>

				<Box width='fit-content' ml='auto'>
					<Button
						isDisabled={isDisabled}
						onClick={() => {
							setSelectedCommentIndex(undefined);
							setIsEntryModalOpen(true);
						}}
						leftIcon={<Icon as={BiPlus} />}>
						Add New
					</Button>
				</Box>
			</Box>

			<EntryFormModal
				isOpen={isEntryModalOpen}
				labelPropertyName='comment'
				tableItems={riskFormData.inputValues?.comments || []}
				setTableItems={updateComments}
				selectedTableIndex={selectedCommentIndex}
				setSelectedTableIndex={setSelectedCommentIndex}
				onClose={() => setIsEntryModalOpen(false)}
			/>

			<TableContainer bgColor='white' mt={6}>
				<Table>
					<Thead>
						<Tr>
							<Th width='100px'>#</Th>
							<Th>Comment</Th>
							<Th>Actions</Th>
						</Tr>
					</Thead>
					<Tbody>
						{riskFormData.inputValues?.comments?.map((item, index) => (
							<TableRow
								label={item.comment}
								onEditClick={handleEditClick}
								onDeleteClick={handleDeleteClick}
								index={index}
								key={index}
							/>
						))}
					</Tbody>
				</Table>
			</TableContainer>

			<DeleteConfirmPopup
				open={deleteConfirmOpen}
				onClose={() => setDeleteConfirmOpen(false)}
				onDelete={handleDeleteConfirm}
			/>
		</PageContainer>
	);
};

export default Comments;
