import { useDispatch, useSelector } from 'react-redux';
import PageContainer from '../../Components/Layout/PageContainer';
import ErrorText from '../../Components/common/ErrorText';
import { updateRiskFormState } from '../../redux/slices/riskForm';
import RiskFormHeader from '../../Components/RiskForm/RiskFormHeader';
import { isRiskDisabled } from '../../redux/slices/risks';
import UserContext from '../../context/user/UserContext';
import { useContext } from 'react';
import SelectInput from '../../Components/common/Inputs/SelectInput';
import { FormControl, FormLabel, Text, Textarea } from '@chakra-ui/react';

const RiskResponse = () => {
	const dispatch = useDispatch();
	const { inputValues, errors } = useSelector((state) => state.riskForm);

	const currentRisk = useSelector((state) => state.risks.currentRisk);
	const { user } = useContext(UserContext);
	const isDisabled = isRiskDisabled(currentRisk, user);

	const handleInputChange = (name, value) => {
		let updatedState = {
			...inputValues,
			response: {
				...(inputValues.response || {}),
				[name]: value,
			},
		};

		if (name === 'response') {
			if (value) {
				updatedState.status = 'assessed';
			} else {
				updatedState.status = 'identified';
			}
		}

		dispatch(updateRiskFormState(updatedState));
	};

	const responseOptions = [
		{ value: '', label: 'None' },
		{ value: 'mitigate', label: 'Mitigate' },
		{ value: 'avoid', label: 'Avoid' },
		{ value: 'transfer', label: 'Transfer' },
		{ value: 'accept', label: 'Accept' },
	];

	return (
		<PageContainer>
			<RiskFormHeader />

			<Text fontSize='2xl' fontWeight={500}>
				Risk Response
			</Text>

			<div style={{ width: '100%', maxWidth: 800, marginTop: 16 }}>
				<FormControl>
					<FormLabel>Response</FormLabel>

					<SelectInput
						width='100%'
						isDisabled={isDisabled}
						placeholder='Select Response'
						value={inputValues.response?.response}
						options={responseOptions}
						defaultChecked={inputValues.response?.response || ''}
						onChange={(value) => handleInputChange('response', value)}
					/>

					{errors.response?.response && <ErrorText>{errors.response?.response}</ErrorText>}
				</FormControl>

				<FormControl mt={6}>
					<FormLabel>Comments</FormLabel>
					<Textarea
						width='100%'
						placeholder='Enter comments...'
						disabled={isDisabled}
						value={inputValues.response?.comments || ''}
						rows={10}
						onChange={(e) => handleInputChange('comments', e.target.value)}
					/>

					{errors.response?.comments && <ErrorText>{errors.response?.comments}</ErrorText>}
				</FormControl>
			</div>
		</PageContainer>
	);
};

export default RiskResponse;
