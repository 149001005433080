import { useDispatch, useSelector } from 'react-redux';
import NavigationHeader, { HEADER_HEIGHT } from './Header';
import { useContext, useEffect } from 'react';
import { getAppSettings } from '../../redux/slices/app-settings';
import { Box, CircularProgress } from '@chakra-ui/react';
import BottomNavigationBar from './BottomNavigationBar';
import { useLocation, useNavigate } from 'react-router-dom';
import UserContext from '../../context/user/UserContext';

const PageContainer = ({ children, hideLogo }) => {
	const { getUserInfo } = useContext(UserContext);
	const { settings, isLoading } = useSelector((state) => state.appSettings);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		(async () => {
			if (localStorage.getItem('refresh_token')) {
				getUserInfo();
			} else if (location.pathname !== '/login') {
				navigate('/login');
			}

			if (!settings && !isLoading) {
				await dispatch(getAppSettings());
			}
		})();
	}, []);

	return (
		<Box bgColor='#F4F6Fa' height='100vh' mt>
			<NavigationHeader hideLogo={hideLogo} />

			{isLoading ? (
				<Box width='fit-content' pt={HEADER_HEIGHT} mx='auto'>
					<CircularProgress isIndeterminate />
				</Box>
			) : (
				<Box height='100vh'>
					<Box
						px={6}
						overflowY='auto'
						paddingTop={HEADER_HEIGHT}
						boxSizing='border-box'
						height={`calc(100vh - ${HEADER_HEIGHT}px)`}
						paddingBottom={'32px'}>
						<Box mx='auto' maxWidth={1500}>
							{children}
						</Box>
					</Box>
					<BottomNavigationBar />
				</Box>
			)}
		</Box>
	);
};

export default PageContainer;
