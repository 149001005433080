import {
	Button,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	Table,
	TableContainer,
	Tbody,
	Th,
	Thead,
	Tr,
} from '@chakra-ui/react';
import RiskRow from './RiskRow';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bulkImportRisks } from '../../../../redux/slices/risks';
import { useMemo } from 'react';
import { cloneDeep } from 'lodash';
import { getBusinessByName } from '../../../../redux/utils';

const RisksPreviewModalContent = ({ risks, setRisks, onImport, onClose }) => {
	const { settings } = useSelector((state) => state.appSettings);
	const [errors, setErrors] = useState({});
	const dispatch = useDispatch();
	const [isSaveLoading, setIsSaveLoading] = useState(false);

	const validatedRisks = useMemo(() => {
		let errors = {};
		let validatedRisks = cloneDeep(risks);

		// TODO: Validate theme
		validatedRisks.forEach((risk) => {
			risk.theme = risk.theme || 'General';

			risk.appetite = {
				...settings.defaultRiskAppetite,
				...risk.appetite,
			};

			const addError = (error) => {
				errors[risk.temporaryId] = [...(errors[risk.temporaryId] || []), error];
			};

			if (!risk.title) {
				addError('Title is required');
			}

			if (!risk.owner) {
				addError('Risk owner is required');
			}

			if (!risk.business) {
				addError('Business is required');
			} else {
				const business = getBusinessByName(risk.business);

				if (business) {
					risk.business = business.name;
					if (risk.department) {
						const department = business.departments.find(
							(department) => department.label === risk.department
						);

						console.log(department);

						if (department) {
							risk.department = department.name;
							if (risk.subDepartment) {
								const subDepartment = department.subDepartments.find(
									(subDepartment) => subDepartment === risk.subDepartment
								);
								if (!subDepartment) {
									addError('Sub department does not exist in this department');
								}
							}
						} else {
							addError('Department does not exist in this business');
						}
					}
				} else {
					addError('Business does not exist');
				}
			}

			if (!risk.department) {
				addError('Department is required');
			}
		});

		setErrors(errors);

		return validatedRisks;
	}, [risks, settings]);

	console.log(validatedRisks);

	const handleSave = async () => {
		if (Object.keys(errors).length) {
			return;
		}

		setIsSaveLoading(true);

		await dispatch(bulkImportRisks(validatedRisks));
		setIsSaveLoading(false);

		onImport();
		onClose();
	};

	return (
		<ModalContent maxW={1200}>
			<ModalHeader>Parsed Risks ({validatedRisks.length})</ModalHeader>
			<ModalCloseButton />
			<ModalBody>
				<TableContainer>
					<Table size='sm' variant='striped'>
						<Thead>
							<Tr>
								<Th>Title</Th>
								<Th>Business</Th>
								<Th>Department</Th>
								<Th>Sub Department</Th>
								<Th>Description</Th>
								<Th>Risk Owner</Th>
								<Th>Actions</Th>
							</Tr>
						</Thead>
						<Tbody>
							{risks.map((risk, index) => (
								<RiskRow
									key={index}
									risk={risk}
									error={errors[risk.temporaryId]}
									onRemoveClick={() => {
										risks.splice(index, 1);
										setRisks([...risks]);
									}}
								/>
							))}
						</Tbody>
					</Table>
				</TableContainer>
			</ModalBody>
			<ModalFooter>
				<Button mr={3} onClick={onClose}>
					Close
				</Button>
				<Button
					isDisabled={Boolean(Object.keys(errors).length)}
					isLoading={isSaveLoading}
					colorScheme='blue'
					mr={3}
					onClick={handleSave}>
					Save
				</Button>
			</ModalFooter>
		</ModalContent>
	);
};

export default RisksPreviewModalContent;
