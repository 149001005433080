import { Box, List, ListItem, Select, Text, useDisclosure } from '@chakra-ui/react';

import { Popover, PopoverContent, PopoverBody, PopoverAnchor } from '@chakra-ui/react';
import { useMemo, useState } from 'react';
import SearchInput from './SearchInput';

const SelectInput = ({
	options,
	components,
	isSearchable,
	onChange,
	emptyListMessage,
	...inputProps
}) => {
	const [searchValue, setSearchValue] = useState('');
	const { isOpen, onOpen, onClose } = useDisclosure();

	const filteredOptions = useMemo(() => {
		if (searchValue) {
			const lowerCaseSearchValue = searchValue.toLowerCase();

			return options?.filter((option) => {
				const values = Object.values(option);

				for (const value of values) {
					if (typeof value === 'string' && value.toLowerCase().includes(lowerCaseSearchValue)) {
						return true;
					}
				}

				return false;
			});
		} else {
			return options;
		}
	}, [searchValue, options]);

	const { Option } = components || {};

	return (
		<>
			<Popover
				isOpen={isOpen && (components?.Option || isSearchable)}
				onOpen={onOpen}
				onClose={onClose}>
				<PopoverAnchor>
					<Select
						size='md'
						height='60px'
						bgColor='white'
						onMouseDown={(e) => {
							if (isSearchable || components?.Option) {
								e.preventDefault();
								onOpen();
							}
						}}
						onChange={(e) => onChange(e.target.value)}
						{...(!inputProps.value && { color: 'gray' })}
						borderWidth={1}
						borderRadius={20}
						{...inputProps}
						value={inputProps.value || ''}>
						{options?.map((option, index) => {
							return (
								<option key={index} value={option.value}>
									{option.label}
								</option>
							);
						})}
					</Select>
				</PopoverAnchor>

				<PopoverContent>
					<PopoverBody>
						<Box sx={{ p: 2, mb: 1, mt: 1 }}>
							<SearchInput
								value={searchValue}
								onChange={(e) => setSearchValue(e.target.value)}
								onClearClick={() => setSearchValue('')}
							/>
						</Box>
						<List maxHeight={280} overflowY='auto'>
							{filteredOptions?.map((option, index) => {
								const isSelected = inputProps.value === option.value;
								return (
									<ListItem
										onClick={() => {
											onChange?.(option.value);
											onClose();
										}}
										padding={2}
										cursor='pointer'
										borderRadius={8}
										color={isSelected ? 'whiteAlpha.900' : ''}
										bgColor={isSelected ? 'gray.700' : ''}
										_hover={{
											background: 'gray.100',
											color: 'black',
										}}>
										{Option ? <Option {...option} key={index} /> : option.label}
									</ListItem>
								);
							})}

							{!filteredOptions?.length && (
								<Text textAlign='center' fontSize={14}>
									{emptyListMessage || 'No options found'}
								</Text>
							)}
						</List>
					</PopoverBody>
				</PopoverContent>
			</Popover>
		</>
	);
};

export default SelectInput;
