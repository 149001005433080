export const RATING_COLORS = {
	green: '#23e46d',
	amber: '#f5c825',
	red: '#ee4c41',
};

export const RISK_TABLE_FIELDS = {
	RiskID: 'RiskID',
	Title: 'Title',
	Status: 'Status',
	Response: 'Response',
	Business: 'Business',
	Department: 'Department',
	SubDepartment: 'SubDepartment',
	Owner: 'Owner',
	CreationTime: 'CreationTime',
	Actions: 'Actions',
	InherentRisk: 'InherentRisk',
	ResidualRisk: 'ResidualRisk',
	AppetiteRisk: 'AppetiteRisk',
};

export const DEFAULT_RISK_TABLE_FIELDS = [
	RISK_TABLE_FIELDS.RiskID,
	RISK_TABLE_FIELDS.Title,
	RISK_TABLE_FIELDS.Status,
	RISK_TABLE_FIELDS.Response,
	RISK_TABLE_FIELDS.Business,
	RISK_TABLE_FIELDS.Department,
	RISK_TABLE_FIELDS.SubDepartment,
	RISK_TABLE_FIELDS.Owner,
	RISK_TABLE_FIELDS.CreationTime,
	RISK_TABLE_FIELDS.Actions,
];

export const CHART_COLORS = [
	'#118dff',
	'#12239e',
	'#6b007b',
	'#e66c37',
	'#117865',
	'#f3a70f',
	'#6e5791',
	'rgb(29, 111, 168)',
	'#eb8404',
	'#199384',
	'#e25d47',
	'rgb(155, 192, 211)'
];
