import React, { useContext, useMemo } from 'react';
import RiskFormHeader from '../../Components/RiskForm/RiskFormHeader';
import PageContainer from '../../Components/Layout/PageContainer';
import { useDispatch, useSelector } from 'react-redux';
import RiskSliderInputs from '../../Components/RiskForm/RiskSliderInputs';
import { updateRiskFormState } from '../../redux/slices/riskForm';
import { isRiskDisabled } from '../../redux/slices/risks';
import UserContext from '../../context/user/UserContext';
import { isUserRiskManager } from '../../context/user/utils';

const AppetiteRisk = () => {
	const dispatch = useDispatch();
	const { inputValues } = useSelector((state) => state.riskForm);
	const { user } = useContext(UserContext);
	const currentRisk = useSelector((state) => state.risks.currentRisk);
	const isDisabled = useMemo(
		() => isRiskDisabled(currentRisk, user) || !isUserRiskManager(user),
		[currentRisk, user]
	);

	const handleInputChange = (name, value) => {
		dispatch(updateRiskFormState({ appetite: { ...inputValues.appetite, [name]: Number(value) } }));
	};

	return (
		<PageContainer>
			<RiskFormHeader />

			<RiskSliderInputs
				type='appetite'
				isDisabled={isDisabled}
				inputValues={inputValues.appetite || {}}
				onInputChange={handleInputChange}
			/>
		</PageContainer>
	);
};

export default AppetiteRisk;
