import { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UserContext from '../../../context/user/UserContext';
import { updateReview } from '../../../redux/slices/reviews';
import ReviewInfo from './ReviewInfo';
import ReviewRisksList from './ReviewRisksList';
import { Box, Button, FormControl, FormLabel, Heading, Textarea, useToast } from '@chakra-ui/react';
import ErrorText from '../../common/ErrorText';

const SelectedReviewForm = (props) => {
	const [commentsInputValue, setCommentsInputValue] = useState('');
	const [isSubmitLoading, setIsSubmitLoading] = useState(false);
	const [error, setError] = useState('');
	const { users } = useSelector((state) => state.users);
	const { user: currentCognitoUser } = useContext(UserContext);
	const toast = useToast();

	const dispatch = useDispatch();
	const { selectedReview } = props;

	const currentUser = useMemo(
		() => users.find((user) => user.cognitoId === currentCognitoUser.sub),
		[currentCognitoUser, users]
	);

	const isReviewCompleted = useMemo(() => selectedReview.status === 'completed', [selectedReview]);

	const handleCompleteClick = async () => {
		if (!currentUser) {
			throw Error('CurrentUserNotFound');
		}

		if (!commentsInputValue) {
			setError('Comments required');
		} else {
			setIsSubmitLoading(true);
			const result = await dispatch(
				updateReview({
					...selectedReview,
					comments: commentsInputValue,
					status: 'completed',
					reviewer: currentUser._id,
				})
			);

			console.log(result);

			if (result.error) {
				if (result.error.message === 'CannotCompleteDueToPendingReviews') {
					setError(
						`Cannot complete review. ${
							selectedReview.type === 'corporate' ? 'Business and' : ''
						} Department reviews need to be completed first`
					);
				} else {
					setError('Error completing review...');
				}
			} else {
				toast({
					title: 'Review completed successfully',
					status: 'success',
				});
				setError('');
			}

			setIsSubmitLoading(false);
		}
	};

	useEffect(() => {
		setError('');
		setCommentsInputValue(selectedReview.comments || '');
	}, [selectedReview]);

	return (
		<Box width='60%' pl={12} pt={6}>
			<Box borderWidth='1px' borderColor='grey.100' bgColor='white' borderRadius={26} p={6}>
				<Heading py={0} fontSize='3xl'>
					Selected Review
				</Heading>
				<div style={{ width: '100%', maxWidth: 1100 }}>
					<ReviewInfo selectedReview={selectedReview} isReviewCompleted={isReviewCompleted} />

					<FormControl mt={4}>
						<FormLabel>Comments</FormLabel>

						<Textarea
							rows={5}
							placeholder='Enter comments...'
							isDisabled={isReviewCompleted}
							style={{ opacity: isReviewCompleted ? 0.7 : 1 }}
							onChange={(e) => setCommentsInputValue(e.target.value)}
							value={commentsInputValue}
						/>

						{error && <ErrorText>{error}</ErrorText>}
					</FormControl>

					<div style={{ width: 'fit-content', marginLeft: 'auto', marginTop: 16 }}>
						<Button
							isLoading={isSubmitLoading}
							isDisabled={isReviewCompleted}
							style={{ width: 200 }}
							primary
							onClick={handleCompleteClick}>
							{isSubmitLoading
								? 'Completing...'
								: isReviewCompleted
								? 'Completed'
								: 'Complete Review'}
						</Button>
					</div>
				</div>

				<ReviewRisksList selectedReview={selectedReview} />
			</Box>
		</Box>
	);
};

export default SelectedReviewForm;
