import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { getHeaders } from '../../api/helpers';
import {
	getUserGroups,
	DEPARTMENT_LEADER_PREFIX,
	DEPARTMENT_USER_PREFIX,
	BUSINESS_LEADER_PREFIX,
	BUSINESS_PREFIX,
	BUSINESS_USER_PREFIX,
} from '../../context/user/utils';

const initialState = {
	risks: [],
	corporateRisks: [],
	currentRisk: null,
	isGetRiskLoading: false,
};

export const getRiskById = createAsyncThunk('risks/getRiskById', async (id) => {
	const result = await axios.get(`${process.env.REACT_APP_BASE_URL}/risks`, {
		headers: getHeaders(),
		params: { ids: id },
	});

	return result.data?.[0] || null;
});

export const fetchCorporateRisks = createAsyncThunk('risks/fecthCorporateRisks', async () => {
	const result = await axios.get(`${process.env.REACT_APP_BASE_URL}/risks`, {
		headers: getHeaders(),
		params: { corporateRisk: true },
	});

	return result.data || [];
});

export const fetchRisks = createAsyncThunk('risks/fetchRisks', async (params) => {
	const result = await axios.get(`${process.env.REACT_APP_BASE_URL}/risks`, {
		headers: getHeaders(),
		params,
	});

	return result.data || [];
});

export const deleteRisk = createAsyncThunk('risks/deleteRisk', async (_id) => {
	await axios.delete(`${process.env.REACT_APP_BASE_URL}/risks`, {
		params: { riskId: _id },
	});

	return _id;
});

export const updateRisk = createAsyncThunk('risks/updateRisk', async (payload) => {
	await axios.put(`${process.env.REACT_APP_BASE_URL}/risks`, payload, {
		params: { riskId: payload._id },
	});

	return payload;
});

export const reopenRisk = createAsyncThunk('risks/reopenRisk', async (payload) => {
	await axios.put(`${process.env.REACT_APP_BASE_URL}/risks`, payload, {
		params: { riskId: payload._id, reopen: true },
	});

	return payload;
});

export const bulkImportRisks = createAsyncThunk('risks/bulkImport', async (payload) => {
	const result = await axios.post(
		`${process.env.REACT_APP_BASE_URL}/risks`,
		{ risks: payload },
		{ params: { bulkImport: true } }
	);

	return result.data;
});

export const risksSlice = createSlice({
	name: 'risks',
	initialState,
	reducers: {
		setCurrentRisk: (state, action) => {
			state.currentRisk = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchRisks.fulfilled, (state, action) => {
			state.risks = action.payload;
		});

		builder.addCase(fetchCorporateRisks.fulfilled, (state, action) => {
			state.corporateRisks = action.payload;
		});

		builder.addCase(getRiskById.pending, (state) => {
			state.isGetRiskLoading = true;
		});
		builder.addCase(getRiskById.fulfilled, (state, action) => {
			state.currentRisk = action.payload;
			state.isGetRiskLoading = false;
		});

		builder.addCase(deleteRisk.fulfilled, (state, action) => {
			state.risks = state.risks.filter((risk) => risk._id !== action.payload);
		});

		builder.addCase(updateRisk.fulfilled, (state, action) => {
			state.risks = state.risks.map((risk) => {
				if (risk._id === action.payload._id) {
					return { ...risk, ...action.payload };
				} else {
					return risk;
				}
			});
		});
		builder.addCase(reopenRisk.fulfilled, (state, action) => {
			state.risks = state.risks.filter((risk) => risk._id !== action.payload._id);
		});
	},
});

export const { setCurrentRisk } = risksSlice.actions;

export const isRiskDisabled = (risk, user) => {
	if (!risk || !user) {
		return false;
	}

	const userGroups = getUserGroups(user);

	let hasBusinessAccess = false;
	let hasDepartmentAccess = false;

	// Disable risk inputs if the user does not have access to the selected risk
	userGroups.forEach((group) => {
		if (group === `${BUSINESS_PREFIX}${risk.business}`) {
			hasBusinessAccess = true;
		} else if (
			group === `${BUSINESS_LEADER_PREFIX}${risk.business}` ||
			group === `${BUSINESS_USER_PREFIX}${risk.business}` ||
			group === 'RiskManager' ||
			group === 'CorporateUser' ||
			group === 'CorporateLeader'
		) {
			hasBusinessAccess = true;
			hasDepartmentAccess = true;
		} else if (
			group === `${DEPARTMENT_LEADER_PREFIX}${risk.department}` ||
			group === `${DEPARTMENT_USER_PREFIX}${risk.department}`
		) {
			hasDepartmentAccess = true;
		}
	});

	const isRiskOwner = risk.owner.cognitoId !== user.sub;

	return (
		((!hasBusinessAccess || !hasDepartmentAccess) && isRiskOwner) || risk?.status === 'archived'
	);
};
