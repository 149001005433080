import './App.css';
import {
	Route,
	createRoutesFromElements,
	createBrowserRouter,
	RouterProvider,
	useLocation,
	BrowserRouter,
} from 'react-router-dom';
import UserState from './context/user/UserState';
import Dashboard from './pages/Dashboard/Dashboard';
import Login from './pages/Login';
import Risk from './pages/Risk/Risk';
import Causes from './pages/Risk/Causes';
import Consequences from './pages/Risk/Consequences';
import InherentRisk from './pages/Risk/InherentRisk';
import Controls from './pages/Risk/Controls';
import ResidualRisk from './pages/Risk/ResidualRisk';
import Appetite from './pages/Risk/Appetite';
import Actions from './pages/Risk/Actions';
import Comments from './pages/Risk/Comments';
import Team from './pages/Admin/Team';
import RiskAppetite from './pages/RiskAppetite';
import Reviews from './pages/Reviews';
import ReviewsRisk from './pages/Reviews/ReviewsRisk';
import Risks from './pages/Risks';
import MyActionsPage from './pages/MyActions';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import Reports from './pages/Reports';
import 'react-datepicker/dist/react-datepicker.css';
import RiskResponsePage from './pages/Risk/Response';
import { ChakraProvider } from '@chakra-ui/react';
import AppSettings from './pages/Admin/Settings';
import { theme } from './theme';

export function useQuery() {
	return new URLSearchParams(useLocation().search);
}

const router = createBrowserRouter(
	createRoutesFromElements(
		<>
			<Route path='/' element={<Dashboard />} />
			<Route path='/login' element={<Login />} />
			<Route path='/risk' element={<Risk />} />
			<Route path='/risk/causes' element={<Causes />} />
			<Route path='/risk/consequences' element={<Consequences />} />
			<Route path='/risk/inherent-risk' element={<InherentRisk />} />
			<Route path='/risk/controls' element={<Controls />} />
			<Route path='/risk/residual-risk' element={<ResidualRisk />} />
			<Route path='/risk/appetite' element={<Appetite />} />
			<Route path='/risk/actions' element={<Actions />} />
			<Route path='/risk/comments' element={<Comments />} />
			<Route path='/risk/response' element={<RiskResponsePage />} />
			<Route path='/actions' element={<MyActionsPage />} />
			<Route path='/reporting' element={<Reports />} />
			<Route path='/risks' element={<Risks />} />
			<Route path='/risk-appetite' element={<RiskAppetite />} />
			<Route path='/reviews' element={<Reviews />} />
			<Route path='/reviews/:riskId' element={<ReviewsRisk />} />
			<Route path='/admin/team' element={<Team />} />
			<Route path='/admin/settings' element={<AppSettings />} />
		</>
	)
);

function App() {
	return (
		<ChakraProvider theme={theme}>
			<Provider store={store}>
				<UserState>
					<RouterProvider router={router} />
				</UserState>
			</Provider>
		</ChakraProvider>
	);
}

export default App;
