import React, { useContext, useMemo } from 'react';
import RiskFormHeader from '../../Components/RiskForm/RiskFormHeader';
import PageContainer from '../../Components/Layout/PageContainer';
import { useDispatch, useSelector } from 'react-redux';
import RiskSliderInputs from '../../Components/RiskForm/RiskSliderInputs';
import { updateRiskFormState } from '../../redux/slices/riskForm';
import { isRiskDisabled } from '../../redux/slices/risks';
import UserContext from '../../context/user/UserContext';

const InherentRisk = () => {
	const dispatch = useDispatch();
	const { inputValues } = useSelector((state) => state.riskForm);

	const { user } = useContext(UserContext);
	const currentRisk = useSelector((state) => state.risks.currentRisk);
	const isDisabled = useMemo(() => isRiskDisabled(currentRisk, user), [currentRisk, user]);

	const handleInputChange = (name, value) => {
		let updates = {
			inherentRisk: {
				...(inputValues.inherentRisk || {}),
				[name]: Number(value),
			},
		};

		dispatch(updateRiskFormState(updates));
	};

	return (
		<PageContainer>
			<RiskFormHeader />

			<RiskSliderInputs
				type='inherent'
				isDisabled={isDisabled}
				inputValues={inputValues.inherentRisk || {}}
				onInputChange={handleInputChange}
			/>
		</PageContainer>
	);
};

export default InherentRisk;
