import {
	Button,
	Divider,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	Text,
} from '@chakra-ui/react';
import { useRef } from 'react';
import { parseJsonToRisks, readFile } from './utils';
import csv from 'csvtojson';

const FileUploadModalContent = ({ setSelectedFile, setParsedRisks }) => {
	const fileInputRef = useRef();

	const handleFileChange = async (e) => {
		const file = e.target.files[0];

		if (file) {
			const filePath = URL.createObjectURL(file);
			const { type } = file;

			const fileValue = await readFile(filePath);

			if (type.includes('text/')) {
				const json = await csv({ output: 'json' }).fromString(fileValue);

				const risks = parseJsonToRisks(json);

				setParsedRisks(risks);
			}

			setSelectedFile(file);
		}
	};

	return (
		<ModalContent>
			<ModalHeader>Import Risks</ModalHeader>
			<ModalCloseButton />

			<ModalBody>
				<input
					ref={fileInputRef}
					onChange={handleFileChange}
					type='file'
					accept='.csv'
					style={{ display: 'none' }}
				/>

				<Button
					size='sm'
					colorScheme='blue'
					onClick={() => {
						fileInputRef.current.value = null;
						fileInputRef.current.click();
					}}
					primary>
					Select File
				</Button>

				<Text fontSize={12} marginTop={2}>
					Only files with the .csv format are accepted
				</Text>

				<Divider mb={4} mt={4} />

				<Button
					size='sm'
					colorScheme='blue'
					onClick={() => {
						window.open(window.location.origin + '/import-template.xlsx');
					}}
					primary>
					Download Template
				</Button>
				<Text fontSize={12} marginTop={2}>
					Add your risks data in the format used in this Excel template and save it as a CSV file to
					import the data to the application
				</Text>
			</ModalBody>
		</ModalContent>
	);
};

export default FileUploadModalContent;
