import React, { useState, useEffect, useContext, useMemo } from 'react';
import PageContainer from '../Components/Layout/PageContainer';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRisks } from '../redux/slices/risks';
import MyRisksSearchInputs from '../Components/Risks/SearchInputs';
import LoadingSpinner from '../Components/common/LoadingSpinner';
import UserContext from '../context/user/UserContext';
import { useQuery } from '../App';
import ImportRisksModal from '../Components/Risks/ImportRisks/ImportRisksModal/ImportRisksModal';
import { userCanAddRisk } from '../context/user/utils';
import { Box, Button, Heading, Tab, TabList, Tabs } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import { updateQueryParam } from '../api/helpers';
import RisksTable from '../Components/Risks/Table/RisksTable';

const tabs = [
	{ label: 'All Risks', value: 'allRisks' },
	{ label: 'My Risks', value: 'myRisks' },
	{ label: 'Archived', value: 'archived' },
];

const Risks = () => {
	const { user: currentUser } = useContext(UserContext);
	const users = useSelector((state) => state.users.users);
	const [isLoading, setIsLoading] = useState(false);
	const [searchInputValues, setSearchInputValues] = useState(false);
	const [sort, setSort] = useState({ _id: -1 });
	const risks = useSelector((state) => state.risks.risks);
	const [isImportRisksModalOpen, setIsImportRisksModalOpen] = useState(false);
	const dispatch = useDispatch();
	const query = useQuery();
	const [selectedTab, setSelectedTab] = useState(query.get('tab') || 'allRisks');
	const location = useLocation();
	const handleSearchInputChange = (name, value) => {
		setSearchInputValues({ ...searchInputValues, [name]: value });
	};

	const isImportButtonVisible = useMemo(() => userCanAddRisk(currentUser), [currentUser]);

	const listRisks = async () => {
		if (isLoading) {
			return;
		}
		setSearchInputValues({});
		setIsLoading(true);
		await dispatch(fetchRisks({ sort }));
		setIsLoading(false);
	};

	const baseFilters = useMemo(() => {
		if (selectedTab === 'myRisks' && currentUser && users.length) {
			return { owner: users.find((user) => user.cognitoId === currentUser.sub)?._id };
		} else if (selectedTab === 'archived') {
			return { status: 'archived' };
		} else {
			return null;
		}
	}, [selectedTab, currentUser, users]);

	const handleSearch = async () => {
		setIsLoading(true);
		await dispatch(fetchRisks({ ...searchInputValues, ...baseFilters, sort }));
		setIsLoading(false);
	};

	useEffect(() => {
		if (baseFilters) {
			handleSearch(baseFilters);
		} else {
			listRisks();
		}
	}, [baseFilters, sort]);

	useEffect(() => {
		const tab = query.get('tab');

		setSelectedTab(tab || 'allRisks');
	}, [location]);

	return (
		<PageContainer>
			<ImportRisksModal
				isOpen={isImportRisksModalOpen}
				onClose={() => setIsImportRisksModalOpen(false)}
				onImport={listRisks}
			/>

			<Box overflowY='auto'>
				<Heading>Assess/Edit Risks</Heading>
				<MyRisksSearchInputs
					inputValues={searchInputValues}
					selectedTab={selectedTab}
					onSearchClick={() => handleSearch()}
					onInputChange={handleSearchInputChange}
					onClearClick={listRisks}
				/>

				<Box mt={8}>
					<Box display='flex' alignItems='center'>
						<Tabs index={tabs.findIndex((tab) => tab.value === selectedTab)}>
							<TabList>
								{tabs.map((tab) => (
									<Tab
										width='130px'
										fontWeight={tab.value === selectedTab ? 700 : 400}
										color='primary'
										onClick={() => {
											setSelectedTab(tab.value);
											updateQueryParam('tab', tab.value);
										}}>
										{tab.label}
									</Tab>
								))}
							</TabList>
						</Tabs>

						{isImportButtonVisible && (
							<Box width='fit-content' marginLeft='auto'>
								<Button
									size='sm'
									onClick={() => {
										setIsImportRisksModalOpen(true);
									}}
									style={{ width: 180 }}>
									Import From CSV
								</Button>
							</Box>
						)}
					</Box>
					{isLoading && <LoadingSpinner style={{ marginTop: 16 }} />}
					{Boolean(risks.length) && !isLoading && (
						<RisksTable risks={risks} sort={sort} setSort={setSort} />
					)}

					{!risks.length && !isLoading && <div style={{ marginTop: 16 }}>No risks found</div>}
				</Box>
			</Box>
		</PageContainer>
	);
};

export default Risks;
