import { Icon, Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import { AiOutlineClose, AiOutlineSearch } from 'react-icons/ai';

const SearchInput = ({ value, placeholder, sx, inputSx, onClearClick, onChange }) => {
	return (
		<InputGroup sx={sx}>
			<Input
				sx={inputSx}
				size='sm'
				onChange={onChange}
				value={value}
				borderRadius={6}
				backgroundColor='white'
				color='black'
				placeholder={placeholder || 'Search...'}
			/>

			<InputRightElement
				mt={-1}
				onClick={value ? () => onClearClick?.() : undefined}
				cursor={value ? 'pointer' : 'default'}
				children={<Icon as={value ? AiOutlineClose : AiOutlineSearch} />}
			/>
		</InputGroup>
	);
};

export default SearchInput;
