import { store } from './store';
import { getAndParseUserGroups, userHasGlobalAccess } from '../context/user/utils';
import { capitalize } from 'lodash';

export const getBusinessByName = (name) => {
	const { settings } = store.getState().appSettings;
	const businesses = settings.businesses || [];

	return businesses.find((business) => business.name === name || business.label === name);
};

export const getDepartmentByName = (name, businessName) => {
	const { settings } = store.getState().appSettings;
	const businesses = settings?.businesses || [];

	for (const business of businesses) {
		if (businessName && business.name !== businessName) {
			continue;
		}

		const department = business.departments.find((department) => department.name === name);
		if (department) {
			return department;
		}
	}
};

export const getBusinessInputOptions = (user) => {
	const { settings } = store.getState().appSettings;

	let options = [];

	if (!settings) {
		return [];
	}

	if (userHasGlobalAccess(user)) {
		options = settings.businesses.map((business) => ({
			value: business.name,
			label: business.label,
		}));
	} else {
		const { businesses } = getAndParseUserGroups(user);

		businesses?.forEach((element) => {
			options.push({
				value: element.name,
				label: element.label,
			});
		});
	}

	options = sortOptions(options);

	return options;
};

export const getDepartmentInputOptions = (user, businessName) => {
	const { settings } = store.getState().appSettings;

	if (!settings) {
	}

	let options = [];
	const { departments, businesses } = getAndParseUserGroups(user);
	const business = businesses.find((business) => business.name === businessName);
	const isManager = userHasGlobalAccess(user);

	const getBusinessDepartments = (business) => {
		business.departments?.forEach(({ name, label, subDepartments }) => {
			const userHasAccessToDepartment =
				isManager || departments.find((department) => department.name === name);
			if (!userHasAccessToDepartment) {
				return;
			}

			if (!options.find((option) => option.value === name)) {
				options.push({
					value: name,
					label,
					subDepartments,
				});
			}
		});
	};

	if (business) {
		getBusinessDepartments(business);
	} else {
		businesses.forEach((business) => {
			getBusinessDepartments(business);
		});
	}

	options = sortOptions(options);

	return options;
};

export const getSubDepartmentInputOptions = (selectedDepartment) => {
	let options =
		selectedDepartment?.subDepartments?.map((subDepartment) => ({
			value: subDepartment,
			label: subDepartment,
		})) || [];

	return sortOptions(options);
};

export const getThemeInputOptions = () => {
	const { settings } = store.getState().appSettings;

	let options =
		settings?.riskThemes?.map((element) => {
			return { value: element, label: element };
		}) || [];

	return sortOptions(options);
};

export const getStatusInputOptions = () => {
	const { settings } = store.getState().appSettings;

	let options = [];
	settings?.allowedRiskStatus?.forEach((element) => {
		options.push({ value: element, label: capitalize(element) });
	});
	return options;
};

export const sortOptions = (options) => {
	return options.sort((a, b) => {
		var textA = a.label.toUpperCase();
		var textB = b.label.toUpperCase();

		return textA < textB ? -1 : textA > textB ? 1 : 0;
	});
};
