import { Tabs, TabList, Tab, Heading, Box, Icon } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { FiSettings } from 'react-icons/fi';
import { AiOutlineTeam } from 'react-icons/ai';
import { RiAdminLine } from 'react-icons/ri';
import { useContext, useEffect, useMemo } from 'react';
import UserContext from '../../context/user/UserContext';
import { isUserRiskManager } from '../../context/user/utils';

const AdminPageHeader = ({ selectedTabIndex }) => {
	const { user } = useContext(UserContext);

	const navigate = useNavigate();
	const isRiskManager = useMemo(() => user && isUserRiskManager(user), [user]);

	useEffect(() => {
		if(user && !isRiskManager) {
			navigate('/')
		}
	}, [isRiskManager, user])

	return (
		<Box>
			<Heading p={4} pl={8} pt={6} fontWeight={600}>
				<Icon mb={-0.5} fontSize={28} as={RiAdminLine} mr={4} />
				Admin Panel
			</Heading>

			<Tabs defaultIndex={selectedTabIndex}>
				<TabList pl={4}>
					<Tab color='primary' onClick={() => navigate('/admin/settings')}>
						<Icon as={FiSettings} mr={2} /> Application Settings
					</Tab>
					<Tab color='primary' onClick={() => navigate('/admin/team')}>
						<Icon as={AiOutlineTeam} mr={2} />
						Team
					</Tab>
				</TabList>
			</Tabs>
		</Box>
	);
};

export default AdminPageHeader;
