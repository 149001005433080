import { store } from '../../redux/store';

export const DEPARTMENT_LEADER_PREFIX = 'DL_';
export const BUSINESS_LEADER_PREFIX = 'BL_';
export const DEPARTMENT_USER_PREFIX = 'DU_';
export const BUSINESS_USER_PREFIX = 'BU_';
export const BUSINESS_PREFIX = 'B_';

/** Retrieves business and departments that a user has access to, and returns its data from globalSettings */
export const getAndParseUserGroups = (user) => {
	const { settings } = store.getState().appSettings;
	let result = { departments: [], businesses: [] };

	if (!user || !settings) {
		return result;
	}
	const groups = user['cognito:groups'];

	if (userHasGlobalAccess(user)) {
		result.businesses = settings.businesses;
	} else {
		groups?.forEach((group) => {
			let groupDepartmentName = '';
			let groupBusinessName = '';
			let isBusinessLeader = false;
			let isBusinessUser = false;

			if (group.startsWith(DEPARTMENT_LEADER_PREFIX)) {
				groupDepartmentName = group.split(DEPARTMENT_LEADER_PREFIX)[1];
			} else if (group.startsWith(DEPARTMENT_USER_PREFIX)) {
				groupDepartmentName = group.split(DEPARTMENT_USER_PREFIX)[1];
			} else if (group.startsWith(BUSINESS_LEADER_PREFIX)) {
				groupBusinessName = group.split(BUSINESS_LEADER_PREFIX)[1];
				isBusinessLeader = true;
			} else if (group.startsWith(BUSINESS_USER_PREFIX)) {
				groupBusinessName = group.split(BUSINESS_USER_PREFIX)[1];
				isBusinessUser = true;
			} else if (group.startsWith(BUSINESS_PREFIX)) {
				groupBusinessName = group.split(BUSINESS_PREFIX)[1];
			}

			if (groupDepartmentName) {
				if (!result.departments.find((department) => department.name === groupDepartmentName)) {
					const department = settings.businesses
						.find((business) =>
							business.departments.find((department) => department.name === groupDepartmentName)
						)
						?.departments?.find((department) => department.name === groupDepartmentName);

					if (department && !result.departments.find(({ name }) => department.name === name)) {
						result.departments.push(department);
					}
				}
			} else if (groupBusinessName) {
				const business = settings.businesses.find(
					(business) => business.name === groupBusinessName
				);

				if (business) {
					if (!result.businesses.find((business) => business.name === groupBusinessName)) {
						result.businesses.push(business);
					}

					if (isBusinessLeader || isBusinessUser) {
						business.departments.forEach((department) => {
							if (!result.departments.find(({ name }) => department.name === name)) {
								result.departments.push(department);
							}
						});
					}
				}
			}
		});
	}

	return result;
};

export const getUserGroups = (user) => {
	return user?.['cognito:groups'] || [];
};

export const isUserCorporateLeader = (user) => {
	const groups = getUserGroups(user);
	return groups.includes('CorporateLeader');
};

export const isUserCorporateUser = (user) => {
	const groups = getUserGroups(user);

	return groups.includes('CorporateUser');
};

export const userHasGlobalAccess = (user) => {
	const groups = getUserGroups(user);

	return (
		groups.includes('RiskManager') ||
		groups.includes('CorporateLeader') ||
		groups.includes('CorporateUser')
	);
};

export const userCanAddRisk = (user) => {
	const groups = getUserGroups(user);

	return groups.includes('RiskManager') || groups.includes('CorporateLeader');
};

export const isUserRiskManager = (user) => {
	const groups = user?.['cognito:groups'] || [];
	return groups.includes('RiskManager');
};
