import { useContext, useMemo } from 'react';
import { RiDeleteBinLine, RiEdit2Line } from 'react-icons/ri';
import { useSelector } from 'react-redux';
import UserContext from '../../../context/user/UserContext';
import { isRiskDisabled } from '../../../redux/slices/risks';
import { mitigationOptions } from './Modal';
import { Box, IconButton, Td, Tr } from '@chakra-ui/react';

const TableRow = (props) => {
	const riskFormData = useSelector((state) => state.riskForm);
	const { item, onEditClick, onDeleteClick, index } = props;
	const { users } = useSelector((state) => state.users);
	const currentRisk = useSelector((state) => state.risks.currentRisk);
	const { user } = useContext(UserContext);
	const isDisabled = isRiskDisabled(currentRisk, user);

	const cause = useMemo(
		() => riskFormData.inputValues.causes?.find((cause) => cause.id === item.cause),
		[item, riskFormData]
	);

	return (
		<Tr fontSize={14}>
			<Td>{item.number || index + 1}</Td>
			<Td whiteSpace='initial'>
				<Box maxWidth={300}>{cause?.cause || ''}</Box>
			</Td>
			<Td>
				{typeof item.owner === 'string'
					? users.find((user) => user._id === item.owner)?.name
					: item.owner?.name || ''}
			</Td>
			<Td>{mitigationOptions.find((option) => option.value === item.mitigation)?.label || ''}</Td>
			<Td style={{ textTransform: 'uppercase' }}>{item.rating || ''}</Td>
			<Td>{item.controlDetail || ''}</Td>
			<Td>
				{!isDisabled && (
					<Box display='flex' alignItems='center' justifyContent='center' gap={2}>
						<IconButton variant='ghost' onClick={() => onEditClick(index)}>
							<RiEdit2Line />
						</IconButton>
						<IconButton variant='ghost' onClick={() => onDeleteClick(index)}>
							<RiDeleteBinLine />
						</IconButton>
					</Box>
				)}
			</Td>
		</Tr>
	);
};

export default TableRow;
