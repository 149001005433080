import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { getHeaders } from '../../api/helpers';

const initialState = {
	reviews: [],
	currentRisk: null,
};

export const fetchReviews = createAsyncThunk('reviews/fetchReviews', async (params) => {
	const result = await axios.get(`${process.env.REACT_APP_BASE_URL}/reviews`, {
		headers: getHeaders(),
		params,
	});

	return result.data || [];
});

export const createReview = createAsyncThunk('reviews/createReview', async (payload) => {
	const result = await axios.post(`${process.env.REACT_APP_BASE_URL}/reviews`, payload);

	return result.data;
});

export const initiateAllReviews = createAsyncThunk(
	'reviews/initiateAllReviews',
	async (payload) => {
		const result = await axios.post(
			`${process.env.REACT_APP_BASE_URL}/reviews?initiateAll=true`,
			payload
		);

		return result.data;
	}
);

export const updateReview = createAsyncThunk('reviews/updateReview', async (payload, thunk) => {
	const users = thunk.getState().users.users;
	try {
		const result = await axios.put(`${process.env.REACT_APP_BASE_URL}/reviews`, payload, {
			params: { reviewId: payload._id },
		});

		return { updatedRisk: result.data, users };
	} catch (e) {
		if (e.response.data.error) {
			throw e.response.data.error;
		} else {
			throw e;
		}
	}
});

export const reviewsSlice = createSlice({
	name: 'reviews',
	initialState,
	reducers: {
		addReviewsToList: (state, action) => {
			state.reviews = [...state.reviews, ...action.payload];
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchReviews.fulfilled, (state, action) => {
			state.reviews = action.payload;
		});

		builder.addCase(updateReview.fulfilled, (state, action) => {
			const { updatedRisk } = action.payload;
			const oldReviewIndex = state.reviews.findIndex((review) => review._id === updatedRisk._id);
			console.log(
				updatedRisk.initiator
					? action.payload.users.find((user) => user._id === action.payload.initiator)
					: undefined
			);
			if (typeof oldReviewIndex !== 'undefined') {
				state.reviews[oldReviewIndex] = {
					...updatedRisk,
					initiator: updatedRisk.initiator
						? action.payload.users.find((user) => user._id === updatedRisk.initiator)
						: undefined,
					reviewer: updatedRisk.reviewer
						? action.payload.users.find((user) => user._id === updatedRisk.reviewer)
						: undefined,
				};
			}
		});
	},
});

export const { addReviewsToList } = reviewsSlice.actions;
