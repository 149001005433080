import UserInfo from './UserInfo';
import logo from '../../Images/logo.png';
import { Box } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

export const HEADER_HEIGHT = 65;

const NavigationHeader = ({ hideLogo }) => {
	const navigate = useNavigate();

	return (
		<Box
			px={6}
			bgColor='#F4F6Fa'
			style={{
				height: HEADER_HEIGHT,
				zIndex: 999,
				width: '100%',
				position: 'fixed',
			}}>
			<Box
				width='100%'
				maxWidth={1500}
				mx='auto'
				height={HEADER_HEIGHT}
				display='flex'
				alignItems='center'>
				{!hideLogo && (
					<Box onClick={() => navigate('/')} as='img' src={logo} height='80%' cursor='pointer' />
				)}

				<Box width='fit-content' ml='auto'>
					<UserInfo />
				</Box>
			</Box>
		</Box>
	);
};

export default NavigationHeader;
