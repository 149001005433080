import React, { useContext, useEffect, useState } from 'react';
import logo from '../Images/logo.png';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import UserContext from '../context/user/UserContext';
import config from '../config';
import { Button } from '@chakra-ui/react';

const { cognitoAuthUrl, cognitoClientId, redirect_uri } = config;

const Login = () => {
	const [isLoading, setIsLoading] = useState(false);
	const userContext = useContext(UserContext);
	const navigate = useNavigate();
	const location = useLocation();
	const [searchParams] = useSearchParams();

	const handleLoginClick = () => {
		window.location.replace(
			`${cognitoAuthUrl}/login?client_id=${cognitoClientId}&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=${redirect_uri}`
		);
	};

	useEffect(() => {
		(async () => {
			if (searchParams.get('code')) {
				setIsLoading(true);
				await userContext.getCredentialsFromCode(searchParams.get('code'));
				setIsLoading(false);
			}
		})();
	}, [location]);

	useEffect(() => {
		console.log(userContext?.user);
		if (userContext?.user) {
			navigate('/');
		}
	}, [userContext?.user]);

	return (
		<>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column',
					backgroundSize: 'cover',
				}}
				className='bg_photo'>
				<div>
					<img src={logo} alt='no_img' style={{ maxWidth: 300, width: '100%' }} />
				</div>

				<Button width={200} isLoading={isLoading} mt={12} onClick={handleLoginClick}>
					Login
				</Button>
			</div>
		</>
	);
};

export default Login;
