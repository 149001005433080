import { Modal, ModalOverlay } from '@chakra-ui/react';
import RisksPreviewModalContent from './RisksPreviewModalContent';
import { useEffect, useState } from 'react';
import FileUploadModalContent from './FileUploadModalContent';

const ImportRisksModal = ({ isOpen, onClose, onImport }) => {
	const [selectedFile, setSelectedFile] = useState(null);
	const [parsedRisks, setParsedRisks] = useState([]);

	useEffect(() => {
		if (isOpen) {
			setSelectedFile(null);
			setParsedRisks([]);
		}
	}, [isOpen]);

	return (
		<>
			<Modal isCentered onClose={onClose} isOpen={isOpen} motionPreset='slideInBottom'>
				<ModalOverlay />
				{selectedFile ? (
					<RisksPreviewModalContent
						risks={parsedRisks}
						setRisks={setParsedRisks}
						onImport={onImport}
						onClose={onClose}
					/>
				) : (
					<FileUploadModalContent
						setSelectedFile={setSelectedFile}
						setParsedRisks={setParsedRisks}
					/>
				)}
			</Modal>
		</>
	);
};

export default ImportRisksModal;
