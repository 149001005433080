import { useContext, useMemo, useState } from 'react';
import RiskFormHeader from '../../Components/RiskForm/RiskFormHeader';
import PageContainer from '../../Components/Layout/PageContainer';
import ActionModal from '../../Components/RiskForm/Actions/Modal';
import { useDispatch, useSelector } from 'react-redux';
import DeleteConfirmPopup from '../../Components/common/Popups/DeleteConfirmPopup';
import ActionsTableRow from '../../Components/RiskForm/Actions/TableRow';
import { updateRiskFormState } from '../../redux/slices/riskForm';
import { cloneDeep } from 'lodash';
import { Box, Button, Icon, Text } from '@chakra-ui/react';
import { BiPlus } from 'react-icons/bi';
import { Table, Thead, Tbody, Tr, Th, TableContainer } from '@chakra-ui/react';
import UserContext from '../../context/user/UserContext';
import { isRiskDisabled } from '../../redux/slices/risks';

const Actions = () => {
	const dispatch = useDispatch();
	const [selectedActionIndex, setSelectedActionIndex] = useState(null);
	const [awaitingDeleteActionIndex, setAwaitingDeleteActionIndex] = useState(null);
	const [isActionModalOpen, setIsActionModalOpen] = useState(false);
	const riskFormData = useSelector((state) => state.riskForm);
	const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
	const { user } = useContext(UserContext);
	const currentRisk = useSelector((state) => state.risks.currentRisk);
	const isDisabled = useMemo(() => isRiskDisabled(currentRisk, user), [currentRisk, user]);

	const updateActions = (actions) => {
		dispatch(updateRiskFormState({ actions }));
	};

	const handleEditClick = (index) => {
		setSelectedActionIndex(index);
		setIsActionModalOpen(true);
	};

	const handleDeleteClick = (index) => {
		let updatedActions = cloneDeep(riskFormData.inputValues.actions);
		updatedActions.splice(index, 1);
		updateActions(updatedActions);
	};

	const handleDeleteConfirm = () => {
		const updatedItems = riskFormData.inputValues.actions.filter(
			(i, index) => index !== awaitingDeleteActionIndex
		);

		updateActions(updatedItems);
		setAwaitingDeleteActionIndex(null);
		setDeleteConfirmOpen(false);
	};

	const handleReopen = ({ _id }) => {
		let updatedActions = cloneDeep(riskFormData.inputValues?.actions);

		for (let action of updatedActions) {
			console.log(action);
			if (action._id === _id) {
				action.status = 'inProgress';
				break;
			}
		}

		updateActions([...updatedActions]);
	};

	return (
		<PageContainer>
			<RiskFormHeader />

			<div>
				<Box display='flex' alignItems='center'>
					<Text fontSize='2xl' fontWeight={500}>
						Actions
					</Text>

					<Box width='fit-content' ml='auto'>
						<Button
							isDisabled={isDisabled}
							onClick={() => {
								setSelectedActionIndex(undefined);
								setIsActionModalOpen(true);
							}}
							leftIcon={<Icon as={BiPlus} />}>
							Add New
						</Button>
					</Box>
				</Box>
				<ActionModal
					isOpen={isActionModalOpen}
					tableItems={riskFormData.inputValues?.actions || []}
					setTableItems={updateActions}
					selectedTableIndex={selectedActionIndex}
					setSelectedTableIndex={setSelectedActionIndex}
					onClose={() => setIsActionModalOpen(false)}
				/>

				<TableContainer bgColor='white' mt={6}>
					<Table>
						<Thead>
							<Tr>
								<Th>#</Th>
								<Th>Action</Th>
								<Th>Owner</Th>
								<Th>Target Date</Th>
								<Th>Description</Th>
								<Th>Status</Th>
								<Th textAlign='center'>Actions</Th>
							</Tr>
						</Thead>
						<Tbody>
							{riskFormData.inputValues.actions?.map((item, i) => (
								<ActionsTableRow
									item={item}
									key={i}
									index={i}
									onEditClick={handleEditClick}
									onDeleteClick={handleDeleteClick}
									onReopenClick={handleReopen}
								/>
							))}
						</Tbody>
					</Table>
				</TableContainer>
			</div>

			<DeleteConfirmPopup
				open={deleteConfirmOpen}
				onClose={() => {
					setDeleteConfirmOpen(false);
				}}
				onDelete={handleDeleteConfirm}
			/>
		</PageContainer>
	);
};

export default Actions;
