import { Box, Text, Tooltip } from '@chakra-ui/react';
import { formatDate } from '../../../api/helpers';
import { getBusinessByName, getDepartmentByName } from '../../../redux/utils';

const ReviewInfo = (props) => {
	const { selectedReview: review, isReviewCompleted } = props;

	console.log(review);

	return (
		<>
			<Box mt={6} display='flex'>
				<div style={{ width: '25%' }}>
					<Label>Reviewer</Label>
					<Value>{review.reviewer?.name || 'None'}</Value>
				</div>
				<div style={{ width: '25%' }}>
					<Label>Type</Label>
					<Value>{review.type}</Value>
				</div>
				<div style={{ width: '25%' }}>
					<Label>Start Date</Label>
					<Value>{formatDate(review.startDate)}</Value>
				</div>
				<div style={{ width: '25%' }}>
					<Label>Due Date</Label>
					<Value>{formatDate(review.dueDate)}</Value>
				</div>
			</Box>
			<Box mt={4} display='flex'>
				<div style={{ width: '25%' }}>
					<Label>Business</Label>
					<Value>
						{review.business ? getBusinessByName(review.business)?.label || '' : 'None'}
					</Value>
				</div>
				<div style={{ width: '25%' }}>
					<Label>Department</Label>
					<Value>
						{review.department ? getDepartmentByName(review.department)?.label || '' : 'None'}
					</Value>
				</div>
				<div style={{ width: '25%' }}>
					<Label>Period</Label>
					<Value>{review.period}</Value>
				</div>
				<div style={{ width: '25%' }}>
					<Label>Status</Label>
					<Value>{isReviewCompleted ? 'Completed' : 'In Progress'}</Value>
				</div>
			</Box>
			<Box mt={4} display='flex'>
				<div style={{ width: '25%' }}>
					<Label>Created At</Label>
					<Value>{formatDate(review.createdAt)}</Value>
				</div>
				{review.status === 'completed' && (
					<>
						<div style={{ width: '25%' }}>
							<Label>Reviewer</Label>
							<Tooltip label={review.reviewer.email}>
								<Value>{review.reviewer.name}</Value>
							</Tooltip>
						</div>
						{review.reviewedAt && (
							<div style={{ width: '25%' }}>
								<Label>Reviewed At</Label>
								<Value>{formatDate(review.reviewedAt)}</Value>
							</div>
						)}
					</>
				)}
			</Box>
		</>
	);
};

const Label = ({ children }) => {
	return (
		<Text fontWeight={700} color='primary' fontSize='14px'>
			{children}
		</Text>
	);
};

const Value = ({ children }) => {
	return (
		<Text fontSize={14} color='GrayText' textTransform='capitalize'>
			{children}
		</Text>
	);
};

export default ReviewInfo;
