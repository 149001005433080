import {
	Box,
	Card,
	CardBody,
	Icon,
	IconButton,
	Tag,
	TagLabel,
	Text,
	Tooltip,
} from '@chakra-ui/react';
import AddDepartmentPopover from './AddDepartmentPopover';
import { AiOutlineDelete } from 'react-icons/ai';
import { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import ConfirmationPopup from '../../../../Components/common/Popups/ConfirmationPopup';
import { deleteDepartment, deleteSubDepartment } from '../../../../redux/slices/app-settings';

const BusinessDepartments = ({ business }) => {
	const popoverContainerRef = useRef();
	return (
		<Box ref={popoverContainerRef}>
			<Box display='flex' alignItems='center'>
				<Text fontSize='md' fontWeight={500} mr={2}>
					Departments
				</Text>

				<AddDepartmentPopover business={business} containerRef={popoverContainerRef} />
			</Box>

			{!business.departments.length && (
				<Text fontSize={12} fontWeight={400}>
					No departments added
				</Text>
			)}

			<Box mt={2} maxHeight={500} overflowY='auto' display='flex' flexWrap='wrap' gap={4}>
				{business.departments.map((department, index) => (
					<DepartmentListItem
						business={business}
						department={department}
						popoverContainerRef={popoverContainerRef}
						key={index}
					/>
				))}
			</Box>
		</Box>
	);
};

const DepartmentListItem = ({ department, business, popoverContainerRef }) => {
	const [isHovered, setIsHovered] = useState(false);
	const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] = useState(false);
	const [isDeleteLoading, setIsDeleteLoading] = useState(false);
	const dispatch = useDispatch();

	const handleDelete = async () => {
		setIsDeleteLoading(true);

		await dispatch(
			deleteDepartment({ businessName: business.name, departmentName: department.name })
		);

		setIsDeleteLoading(false);
	};

	return (
		<Card
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
			backgroundColor='gray.100'
			width='100%'
			size='sm'>
			<ConfirmationPopup
				open={isDeleteConfirmModalOpen}
				title='Delete Department'
				onConfirm={handleDelete}
				isLoading={isDeleteLoading}
				onClose={() => setIsDeleteConfirmModalOpen(false)}
			/>
			<CardBody>
				<Box display='flex' alignItems='center'>
					<Text fontWeight={500} fontSize={14}>
						{department.label}
					</Text>
					<Box width='fit-content' ml='auto'>
						<Tooltip label='Delete Department'>
							<IconButton
								variant='ghost'
								onClick={() => setIsDeleteConfirmModalOpen(true)}
								visibility={isHovered ? 'visible' : 'hidden'}
								color='black'
								size='sm'>
								<Icon as={AiOutlineDelete} />
							</IconButton>
						</Tooltip>
					</Box>
				</Box>
				<Box display='flex' flexDirection='column' alignItems='flex-start'>
					<Box mb={2} display='flex' flexDirection='row' gap={2} flexWrap='wrap'>
						{department.subDepartments.map((subDep, index) => (
							<SubDepartmentListItem
								key={index}
								business={business}
								department={department}
								subDepartment={subDep}
							/>
						))}
					</Box>
					<AddDepartmentPopover
						business={business}
						parentDepartment={department}
						containerRef={popoverContainerRef}
					/>
				</Box>
			</CardBody>
		</Card>
	);
};

const SubDepartmentListItem = ({ business, department, subDepartment }) => {
	// const [isHovered, setIsHovered] = useState(false);
	const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] = useState(false);
	const [isDeleteLoading, setIsDeleteLoading] = useState(false);
	const dispatch = useDispatch();

	const handleDelete = async () => {
		setIsDeleteLoading(true);

		const result = await dispatch(
			deleteSubDepartment({
				businessName: business.name,
				departmentName: department.name,
				subDepartmentName: subDepartment,
			})
		);

		console.log(result);

		setIsDeleteLoading(false);
	};

	return (
		<>
			<ConfirmationPopup
				open={isDeleteConfirmModalOpen}
				title='Delete Sub-Department'
				onConfirm={handleDelete}
				isLoading={isDeleteLoading}
				onClose={() => setIsDeleteConfirmModalOpen(false)}
			/>
			<Tooltip fontSize={12} label='Delete Sub-Department'>
				<Tag
					cursor='pointer'
					onClick={() => {}}
					// onMouseEnter={() => setIsHovered(true)}
					// onMouseLeave={() => setIsHovered(false)}
					backgroundColor='white'
					size='sm'>
					<TagLabel>
						{subDepartment}

						{/* {isHovered && ( */}
						<IconButton
							variant='ghost'
							onClick={() => setIsDeleteConfirmModalOpen(true)}
							color='black'
							size='xs'>
							<Icon as={AiOutlineDelete} />
						</IconButton>
						{/* )} */}
					</TagLabel>
				</Tag>
			</Tooltip>
		</>
	);
};

export default BusinessDepartments;
