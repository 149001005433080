import { useContext } from 'react';
import { RiDeleteBinLine, RiEdit2Line } from 'react-icons/ri';
import { useSelector } from 'react-redux';
import UserContext from '../../../context/user/UserContext';
import { isRiskDisabled } from '../../../redux/slices/risks';
import { Box, IconButton, Td, Tr } from '@chakra-ui/react';

const TableRow = (props) => {
	const { label, number, onEditClick, onDeleteClick, index } = props;

	const currentRisk = useSelector((state) => state.risks.currentRisk);
	const { user } = useContext(UserContext);
	const isDisabled = isRiskDisabled(currentRisk, user);

	return (
		<Tr fontSize={14}>
			<Td whiteSpace='initial'>
				<Box width='100px'>{number || index + 1}</Box>
			</Td>
			<Td whiteSpace='initial'>
				<Box maxWidth={600}>{label}</Box>
			</Td>
			<Td width='20%'>
				{!isDisabled && (
					<Box display='flex' alignItems='center' justifyContent='center' gap={2}>
						<IconButton variant='ghost' onClick={() => onEditClick(index)}>
							<RiEdit2Line />
						</IconButton>
						<IconButton variant='ghost' onClick={() => onDeleteClick(index)}>
							<RiDeleteBinLine />
						</IconButton>
					</Box>
				)}
			</Td>
		</Tr>
	);
};

export default TableRow;
