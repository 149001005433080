import { useContext, useMemo } from 'react';
import UserContext from '../../context/user/UserContext';
import { getAndParseUserGroups, userHasGlobalAccess } from '../../context/user/utils';
import { startCase } from 'lodash';
import { CgProfile } from 'react-icons/cg';
import { Box, Button, Icon, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import {
	Popover,
	PopoverTrigger,
	PopoverContent,
	PopoverHeader,
	PopoverBody,
	PopoverArrow,
	PopoverCloseButton,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';

const UserInfo = () => {
	const userContext = useContext(UserContext);
	const { settings } = useSelector((state) => state.appSettings);
	const navigate = useNavigate();

	const { businesses, departments } = useMemo(
		() => getAndParseUserGroups(userContext.user),
		[userContext, settings]
	);

	const handleSignOutClick = () => {
		userContext.signOut();
		navigate('/login');
	};

	return (
		<>
			<Popover>
				<PopoverTrigger>
					<Box
						cursor='pointer'
						style={{ textAlign: 'left', display: 'flex', alignItems: 'center' }}>
						<Box cursor='pointer' pr={4}>
							<Icon fontSize={24} color='primary' as={CgProfile} />
						</Box>

						<Box mr={4} width='1px' height='30px' bgColor='primary'></Box>
						<Box>
							<Text mb={-1} fontWeight={700} fontSize={14} color='primary'>
								{userContext.user?.given_name} {userContext.user?.family_name}
							</Text>
							<Button
								size='xs'
								fontSize={14}
								p={0}
								fontWeight={300}
								variant='ghost'
								color='primary'
								onClick={(e) => {
									e.stopPropagation();
									handleSignOutClick();
								}}>
								Sign Out
							</Button>
						</Box>
					</Box>
				</PopoverTrigger>
				<PopoverContent>
					<PopoverArrow />
					<PopoverCloseButton />
					<PopoverHeader>
						{userContext.user?.given_name} {userContext.user?.family_name}
					</PopoverHeader>
					<PopoverBody>
						<p style={{ fontSize: 12, fontWeight: 600 }}>
							{userContext.user && userHasGlobalAccess(userContext.user) && 'Manager'}
						</p>
						<span style={{ fontSize: 12 }}>{userContext.user?.email}</span>
						<div style={{ fontSize: 12, marginTop: 16 }}>
							{Boolean(businesses.length) && (
								<>
									<p style={{ fontWeight: 600 }}>Businesses</p>
									<p>{businesses.map((business) => startCase(business.name)).join(', ')}</p>
								</>
							)}
							{Boolean(departments.length) && (
								<>
									{' '}
									<p style={{ fontWeight: 600, marginTop: 12 }}>Departments</p>
									<p>
										{departments
											.map((department) =>
												department.name.length > 3
													? startCase(department.name)
													: department.name.toUpperCase()
											)
											.join(', ')}
									</p>
								</>
							)}
						</div>
					</PopoverBody>
				</PopoverContent>
			</Popover>
		</>
	);
};

export default UserInfo;
