import RisksTable from '../Risks/Table/RisksTable';
import { RISK_TABLE_FIELDS } from '../../constants';

const CorporateRisksTable = ({ risks, selectedRisk, onRiskClick }) => {
	return (
		<RisksTable
			risks={risks}
			selectedRisk={selectedRisk}
			onRiskClick={onRiskClick}
			size='sm'
			tableProps={{
				size: 'sm',
				__css: { 'table-layout': 'fixed', width: 'full' },
			}}
			tableContainerProps={{
				height: '245px',
				overflowY: 'auto',
			}}
			fieldProps={{
				[RISK_TABLE_FIELDS.Title]: {
					width: '200px',
					overflowX: 'auto',
					whiteSpace: null,
				},
			}}
			fields={[
				RISK_TABLE_FIELDS.RiskID,
				RISK_TABLE_FIELDS.Title,
				RISK_TABLE_FIELDS.InherentRisk,
				RISK_TABLE_FIELDS.ResidualRisk,
				RISK_TABLE_FIELDS.AppetiteRisk,
			]}
		/>
	);
};

export default CorporateRisksTable;
