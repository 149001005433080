import { capitalize } from '../../api/helpers';
import {
	Card,
	CardHeader,
	CardBody,
	Heading,
	StackDivider,
	Stack,
	Box,
	Text,
} from '@chakra-ui/react';
const RiskResponseSummary = (props) => {
	const { risk } = props;

	if (!risk.response) {
		return null;
	}

	return (
		<Card mt={8}>
			<CardHeader pb={0} pt={0}>
				<Heading pb={2} size='md'>
					Risk Response
				</Heading>
			</CardHeader>

			<CardBody pt={0}>
				<Stack divider={<StackDivider />} spacing='4'>
					<Box>
						<Heading size='xs' textTransform='uppercase'>
							Response
						</Heading>
						<Text fontSize='sm'>{capitalize(risk.response.response)}</Text>
					</Box>
					<Box>
						<Heading size='xs' textTransform='uppercase'>
							Comments
						</Heading>
						<Text fontSize='sm'>{risk.response.comments}</Text>
					</Box>
				</Stack>
			</CardBody>
		</Card>
	);
};

export default RiskResponseSummary;
