import { Box, Text } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

const ActionsList = (props) => {
	const { selectedAction, onActionClick } = props;
	const { actions } = useSelector((state) => state.actions);

	return (
		<Box style={{ width: '40%', height: 'calc(100vh - 250px)', maxWidth: 700, overflowY: 'auto' }}>
			{!actions.length && <div style={{ marginTop: 8, fontSize: 14 }}>No actions found</div>}

			{Boolean(actions.length) && (
				<Box display='flex' py={4} px={3} width='100%'>
					<Box width='25%' px={2}>
						<HeaderText>ID</HeaderText>
					</Box>

					<Box width='50%' px={2}>
						<HeaderText>Action</HeaderText>
					</Box>

					<Box width='33.3%' px={2}>
						<HeaderText>Status</HeaderText>
					</Box>
				</Box>
			)}

			{actions.map((action) => (
				<Box
					display='flex'
					width='100%'
					cursor='pointer'
					borderWidth='1px'
					borderColor='gray.300'
					py={3}
					px={2}
					mb={2}
					borderRadius={24}
					_hover={{
						backgroundColor: 'gray.100',
					}}
					fontWeight={selectedAction?._id === action._id ? 'bold' : ''}
					backgroundColor={selectedAction?._id === action._id ? 'gray.200' : 'white'}
					onClick={() => onActionClick(action)}
					key={action.actionSeq}>
					<Box width='25%' px={2}>
						<ItemText>ACT{action.actionSeq}</ItemText>
					</Box>

					<Box width='50%' px={2}>
						<ItemText>{action.action}</ItemText>
					</Box>
					<Box width='33.3%' px={2}>
						<ItemText>{action.status === 'completed' ? 'Completed' : 'In Progress'}</ItemText>
					</Box>
				</Box>
			))}
		</Box>
	);
};

const HeaderText = ({ children }) => {
	return (
		<Text fontWeight={700} color='primary' fontSize={14}>
			{children}
		</Text>
	);
};

const ItemText = ({ children, ...rest }) => {
	return (
		<Text fontSize={12} {...rest}>
			{children}
		</Text>
	);
};

export default ActionsList;
