import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getHeaders } from '../../api/helpers';

const initialState = {
    actions: [],
};


// eslint-disable-next-line no-undef
export const fetchActions = createAsyncThunk('actions/fetchActions', async (params) => {
    const result = await axios.get(`${process.env.REACT_APP_BASE_URL}/actions`, {
        headers: getHeaders(),
        params
    });


    return result.data || [];
});

export const updateAction = createAsyncThunk('actions/updateAction', async (updates) => {
    await axios.put(`${process.env.REACT_APP_BASE_URL}/actions?actionId=${updates._id}`, updates)

    return updates;

})

export const actionsSlice = createSlice({
    name: 'actions',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchActions.fulfilled, (state, action) => {
            state.actions = action.payload;
        });

        builder.addCase(updateAction.fulfilled, (state, { payload }) => {
            state.actions = state.actions.map(action => {
                if (action._id === payload._id) {
                    return { ...action, ...payload }
                } else {
                    return action
                }
            });
        })
    },
});