import PageContainer from '../../Components/Layout/PageContainer';
import AdminPageHeader from '../../Components/Admin/AdminPageHeader';
import BusinessesSettings from './Businesses/Businesses';
import { Box } from '@chakra-ui/react';

const AppSettings = () => {
	return (
		<PageContainer>
			<AdminPageHeader selectedTabIndex={0} />
			<Box py={6} px={8}>
				<BusinessesSettings />
			</Box>
		</PageContainer>
	);
};

export default AppSettings;
