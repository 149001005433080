import React, { useContext, useEffect, useMemo, useState } from 'react';
import PageContainer from '../Components/Layout/PageContainer';
import RiskSliderInputs from '../Components/RiskForm/RiskSliderInputs';
import UserContext from '../context/user/UserContext';
import { resetRiskFormState } from '../redux/slices/riskForm';
import { useDispatch, useSelector } from 'react-redux';
import { isUserRiskManager } from '../context/user/utils';
import { updateRiskAppetite } from '../redux/slices/app-settings';
import { Box, Button, Heading } from '@chakra-ui/react';

const RiskAppetite = () => {
	const [isSaveLoading, setIsSaveLoading] = useState(false);
	const [inputValues, setInputValues] = useState({});
	const { settings } = useSelector((state) => state.appSettings);
	const { user } = useContext(UserContext);
	const dispatch = useDispatch();

	const isCurrentUserRiskManager = useMemo(() => user && isUserRiskManager(user), [user]);

	const handleRevert = () => {
		setInputValues(settings.defaultRiskAppetite || {});
	};

	const handleSave = async () => {
		setIsSaveLoading(true);

		await dispatch(updateRiskAppetite(inputValues));

		setIsSaveLoading(false);
	};

	useEffect(() => {
		if (settings) {
			setInputValues(settings.defaultRiskAppetite || {});
			dispatch(resetRiskFormState());	
		}
	}, [settings]);

	return (
		<PageContainer>
			<Heading>Default Risk Appetite</Heading>

			<RiskSliderInputs
				isDisabled={!isCurrentUserRiskManager}
				type='appetite'
				inputValues={inputValues}
				onInputChange={(name, value) => setInputValues({ ...inputValues, [name]: value })}
			/>

			{isCurrentUserRiskManager && (
				<Box display='flex' gap={4} mt={8} width='fit-content' ml='auto'>
					<Button variant='ghost' onClick={handleRevert}>
						Revert
					</Button>
					<Button isLoading={isSaveLoading} onClick={handleSave}>
						Save
					</Button>
				</Box>
			)}
		</PageContainer>
	);
};

export default RiskAppetite;
