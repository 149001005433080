import { Th, Thead, Tr } from '@chakra-ui/react';
import { RISK_TABLE_FIELDS, DEFAULT_RISK_TABLE_FIELDS } from '../../../constants';

const RisksTableHead = ({ fields, sort, setSort, fieldProps }) => {
	return (
		<Thead>
			<Tr>
				{(fields || DEFAULT_RISK_TABLE_FIELDS).map((field, index) => {
					const Component = Fields[field];
					const props = fieldProps?.[field] || {};

					if (!Component) {
						throw Error(`InvalidField: ${field}`);
					}

					return <Component key={index} sort={sort} setSort={setSort} props={props} />;
				})}
			</Tr>
		</Thead>
	);
};

const Fields = {
	[RISK_TABLE_FIELDS.RiskID]: ({ props }) => <Th {...props}>#</Th>,
	[RISK_TABLE_FIELDS.Title]: ({ props }) => (
		<Th width={100} {...props}>
			Title
		</Th>
	),
	[RISK_TABLE_FIELDS.Description]: ({ props }) => (
		<Th width={500} {...props}>
			Description
		</Th>
	),
	[RISK_TABLE_FIELDS.Status]: ({ props }) => <Th {...props}>Status</Th>,
	[RISK_TABLE_FIELDS.Response]: ({ props }) => <Th {...props}>Response</Th>,
	[RISK_TABLE_FIELDS.Business]: ({ props }) => <Th {...props}>Business</Th>,
	[RISK_TABLE_FIELDS.Department]: ({ props }) => (
		<Th width='80px' {...props}>
			Department
		</Th>
	),
	[RISK_TABLE_FIELDS.SubDepartment]: ({ props }) => (
		<Th width={'80px'} {...props}>
			Sub-Department
		</Th>
	),
	[RISK_TABLE_FIELDS.Owner]: ({ props }) => <Th {...props}>Owner</Th>,
	CreationTime: ({ sort, setSort, props }) => (
		<Th {...props} cursor='pointer' onClick={() => setSort?.({ _id: sort._id === -1 ? 1 : -1 })}>
			Creation Time {sort ? (sort._id === 1 ? '⇧' : '⇩') : ''}
		</Th>
	),
	[RISK_TABLE_FIELDS.Actions]: ({ props }) => (
		<Th textAlign='center' {...props}>
			Actions
		</Th>
	),
	[RISK_TABLE_FIELDS.InherentRisk]: ({ props }) => (
		<Th textAlign='center' {...props}>
			Inherent
		</Th>
	),
	[RISK_TABLE_FIELDS.ResidualRisk]: ({ props }) => (
		<Th textAlign='center' {...props}>
			Residual
		</Th>
	),
	[RISK_TABLE_FIELDS.AppetiteRisk]: ({ props }) => (
		<Th textAlign='center' {...props}>
			Appetite
		</Th>
	),
};

export default RisksTableHead;
