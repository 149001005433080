import {
	Popover,
	PopoverTrigger,
	PopoverContent,
	PopoverHeader,
	PopoverBody,
	PopoverFooter,
	PopoverArrow,
	PopoverCloseButton,
	IconButton,
	Icon,
	useDisclosure,
	Button,
	Input,
	FormControl,
	FormLabel,
	Box,
	Portal,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { GrAdd } from 'react-icons/gr';
import { useDispatch } from 'react-redux';
import { createDepartment, createSubDepartment } from '../../../../redux/slices/app-settings';

const AddDepartmentPopover = ({ business, parentDepartment, containerRef }) => {
	const [name, setName] = useState('');
	const [error, setError] = useState('');
	const [isSaveLoading, setIsSaveLoading] = useState(false);
	const dispatch = useDispatch();
	const { isOpen, onToggle, onClose } = useDisclosure();

	const handleSave = async () => {
		if (error) {
			return;
		}

		if (!name) {
			setError('Required');
			return;
		}

		setIsSaveLoading(true);

		if (parentDepartment) {
			await dispatch(
				createSubDepartment({
					name,
					businessName: business.name,
					departmentName: parentDepartment.name,
				})
			);
		} else {
			await dispatch(createDepartment({ name, businessName: business.name }));
		}

		setIsSaveLoading(false);

		onClose();
	};

	const handleNameInputChange = (e) => {
		const { value } = e.target;
		setName(value);

		const lowerCaseValue = value.toLowerCase();

		let existingDepartment = null;
		if (parentDepartment) {
			existingDepartment = parentDepartment.subDepartments.find(
				(dep) => dep.toLowerCase() === lowerCaseValue
			);
		} else {
			existingDepartment = business.departments.find(
				(department) =>
					department.name.toLowerCase() === lowerCaseValue ||
					department.label.toLowerCase() === lowerCaseValue
			);
		}

		if (existingDepartment) {
			setError('A department with this name already exists');
		} else {
			setError('');
		}
	};

	useEffect(() => {
		if (isOpen) {
			setName('');
			setError('');
		}
	}, [isOpen]);

	return (
		<Popover
			returnFocusOnClose={false}
			isOpen={isOpen}
			onClose={onClose}
			placement='right'
			closeOnBlur={false}>
			<PopoverTrigger>
				{parentDepartment ? (
					<Button onClick={onToggle} variant='ghost' size='xs'>
						+ Add Sub-Department
					</Button>
				) : (
					<IconButton variant='ghost' size='sm' onClick={onToggle}>
						<Icon as={GrAdd} />
					</IconButton>
				)}
			</PopoverTrigger>
			<Portal containerRef={containerRef}>
				<PopoverContent>
					<PopoverArrow />
					<PopoverHeader>Add {parentDepartment ? 'Sub-' : ''}Department</PopoverHeader>
					<PopoverCloseButton />
					<PopoverBody py={4}>
						<FormControl isInvalid={Boolean(error)}>
							<FormLabel fontSize={14}>{parentDepartment ? 'Sub-' : ''}Department Name</FormLabel>
							<Input placeholder='Enter name...' value={name} onChange={handleNameInputChange} />
						</FormControl>
					</PopoverBody>
					<PopoverFooter>
						<Box w='fit-content' ml='auto'>
							<Button
								isDisabled={Boolean(error)}
								onClick={handleSave}
								isLoading={isSaveLoading}
								colorScheme='blue'>
								Save
							</Button>
						</Box>
					</PopoverFooter>
				</PopoverContent>
			</Portal>
		</Popover>
	);
};

export default AddDepartmentPopover;
