import {
	defineStyleConfig,
	extendTheme,
	withDefaultColorScheme,
	createMultiStyleConfigHelpers,
} from '@chakra-ui/react';
import { selectAnatomy } from '@chakra-ui/anatomy';

const { definePartsStyle } = createMultiStyleConfigHelpers(selectAnatomy.keys);

const FormLabel = defineStyleConfig({
	baseStyle: {
		color: 'primary',
	},
});

const Button = defineStyleConfig({
	variants: {
		solid: {
			bgColor: 'primary',
			_hover: {
				bgColor: 'primaryHover',
			},
		},
		ghost: {
			_hover: {
				bgColor: 'gray.100',
			},
		},
	},
});

const Input = defineStyleConfig({
	baseStyle: {},
	defaultProps: {
		variant: 'default',
	},
	sizes: {},
	variants: {
		'full-screen-form': definePartsStyle({
			field: {
				borderColor: 'grey.100',
				borderWidth: 1,
				borderRadius: 20,
				bg: '#fff',
				height: '60px',
				// maxWidth: 300,
			},
		}),

		default: definePartsStyle({
			field: {
				borderColor: 'grey.100',
				borderWidth: 1,
				borderRadius: 20,
				bg: '#fff',
			},
		}),
	},
});

const Textarea = defineStyleConfig({
	baseStyle: {
		borderColor: 'grey.100',
		borderWidth: 1,
		borderRadius: 20,
		backgroundColor: 'white',
	},
	defaultProps: {
		variant: 'default',
	},
	variants: {
		default: definePartsStyle({
			field: {
				borderColor: 'grey.100',
				borderWidth: 1,
				borderRadius: 20,
				bg: '#fff',
				height: '60px',
			},
		}),
	},
});

const Heading = defineStyleConfig({
	baseStyle: {
		py: 6,
		color: 'primary',
	},
});

export const theme = extendTheme(
	{
		components: {
			FormLabel,
			Input,
			Textarea,
			Button,
			Heading,
		},
		initialColorMode: 'light',
		useSystemColorMode: false,
		fonts: {
			heading: `'Inter', sans-serif`,
			body: `'Inter', sans-serif`,
		},
		fontSizes: {
			'4xl': '2rem',
		},

		colors: {
			primary: '#2F61A8',
			primaryHover: '#4176bf',
		},
	},
	withDefaultColorScheme({
		colorScheme: 'primary',
	})
);
